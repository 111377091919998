import React from 'react';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.minimal.css';
import './Toast.scss';
import _ from 'lodash';

const ToastTransition = cssTransition({
  enter: 'ToastTransition--in',
  exit: 'ToastTransition--out',
  duration: 250,
});

function DumpsterToastContainer(props) {
  return (
    <ToastContainer 
      className="ToastContainer"
      toastClassName="Toast"
      bodyClassName="ToastBody"
      position={toast.POSITION.BOTTOM_CENTER}
      transition={ToastTransition}
      autoClose={5000}
      pauseOnHover={true}
      pauseOnFocusLoss={false}
      draggable={false} 
      hideProgressBar={true}
      closeButton={false} />
  );
}

function dumpsterToast(content, options = {}) {
  if (_.isString(content)) {
    options.className = 'Toast Toast--alert';
  }
  else {
    options.className = 'Toast Toast--dialog';
    options.closeOnClick = false;
  }
  return toast(content, options);
}

dumpsterToast.dismiss = toast.dismiss;
dumpsterToast.update = toast.update;

function ToastDialog(props) {
  return (<div className="ToastDialog">{props.children}</div>);
}

function ToastDialogBody(props) {
  return (<div className="ToastDialogBody">{props.children}</div>);
}

function ToastDialogButtons(props) {
  return (<div className="ToastDialogButtons">{props.children}</div>);
}

export { 
  DumpsterToastContainer as ToastContainer,
  dumpsterToast as toast, 
  ToastDialog, 
  ToastDialogBody, 
  ToastDialogButtons 
};