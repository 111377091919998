import React, { PureComponent } from 'react';
import './Checkbox.scss';
import _ from 'lodash';
import cx from 'classnames';

class Checkbox extends PureComponent {
  constructor() {
    super();
    this.htmlId = _.uniqueId();
  }

  _handleOnChange = (e) => {
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange(e.target.checked);
    }
  }

  render() {
    const className = cx(
      'Checkbox', 
      { 'Checkbox--checked': this.props.checked },
      { 'Checkbox--right': this.props.right },
      { 'Checkbox--warning': this.props.warning }
    );

    return (
      <div className={className}>
        { this.props.right ? (
          <>
            <label htmlFor={this.htmlId}>{this.props.label}</label>
            <input type="checkbox" id={this.htmlId} checked={this.props.checked} onChange={this._handleOnChange} />
          </>
        ) : (
          <>
            <input type="checkbox" id={this.htmlId} checked={this.props.checked} onChange={this._handleOnChange} />
            <label htmlFor={this.htmlId}>{this.props.label}</label>
          </>
        )}
        
      </div>
    );
  }
}

export default Checkbox;