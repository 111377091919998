import React, { Component } from 'react';
import './Tags.scss';
import { DataStore } from '../data/DataStore';
import _ from 'lodash';
import NavTabs from '../components/NavTabs';
import ViewToggle from '../components/ViewToggle';
import TagSortSelect from '../components/TagSortSelect';
import Loading from '../components/Loading';
import TagGrid from '../components/TagGrid';
import TagList from '../components/TagList';
import EmptyState from '../components/EmptyState';
import { trackEvent } from '../data/Analytics';

class Tags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: null,
      sortValue: null,
      activeView: null
    };

    this.dataStore = new DataStore();
  }

  componentDidMount = () => {
    trackEvent('Tags Viewed');
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  _syncWithDataStore = () => {
    const { value, field, order } = this.dataStore.getDefaultTagSortOrder();
    const fieldResolver = (field === 'label') ? item => item[field].toLowerCase() : field;
    this.setState({
      tags: _.orderBy(this.dataStore.tags, fieldResolver, order),
      sortValue: value,
      activeView: this.dataStore.getDefaultTagView()
    });
  }

  render() {
    // If we are still loading
    if (_.isNil(this.state.tags)) {
      return <Loading />;
    }

    const hasTags = this.state.tags.length > 0;

    return (
      <div className="Tags">
        <div className="Tags__header">
          <NavTabs />
          <div className="Tags__spacer"></div>
          {hasTags &&
            <>
              <ViewToggle activeView={this.state.activeView} onViewToggle={this.dataStore.setDefaultTagView} />
              <TagSortSelect value={this.state.sortValue} onChange={this.dataStore.setDefaultTagSortOrder} />
            </>
          }
        </div>
        {!hasTags && <EmptyState message="You haven't created any tags yet!" />}
        {hasTags && this.state.activeView === 'grid' && <TagGrid tags={this.state.tags} />}
        {hasTags && this.state.activeView === 'list' && <TagList tags={this.state.tags} />}
      </div>
    );
  }
}

export default Tags;