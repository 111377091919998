import * as React from 'react';

function SvgIconProfile(props) {
  return (
    <svg width={21} height={21} fill="none" {...props}>
      <path
        d="M10.433 11.398a4.66 4.66 0 004.66-4.661V4.759a4.66 4.66 0 10-9.321 0v1.978c0 2.547 2.086 4.661 4.66 4.661zM7.94 4.732a2.487 2.487 0 012.493-2.494 2.487 2.487 0 012.493 2.494V6.71a2.487 2.487 0 01-2.493 2.493A2.487 2.487 0 017.94 6.71V4.732zM14.498 12.646h-8.13A6.36 6.36 0 000 19.014c0 .596.488 1.084 1.084 1.084.596 0 1.084-.488 1.084-1.084 0-2.303 1.897-4.2 4.2-4.2h8.13c2.304 0 4.2 1.897 4.2 4.2 0 .596.488 1.084 1.084 1.084.596 0 1.084-.488 1.084-1.084a6.36 6.36 0 00-6.368-6.368z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconProfile;
