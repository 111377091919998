import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import env from '../data/env';
import log from '../data/logging';
import amplitude from 'amplitude-js';
import _ from 'lodash';

let _project = null;

const initAnalytics = () => {
  if (!_.isNull(_project)) return;

  log('Analytics: Initializing', {
    commit: env.commit
  });

  _project = amplitude.getInstance();
  _project.init(env.amplitude.apiKey);

  _trackPageview();

  if (window.location.pathname === '/extension/popup') {
    trackEvent('Extension Popup Loaded');
  }
  else if (window.location.pathname === '/extension/background') {
    trackEvent('Extension Background Loaded');
  }
  else {
    trackEvent('Application Opened');
  }
};

let _identity = null;

const resetAnalytics = () => {
  _identity = null;
  log('Analytics: Reset');
  _project.setUserId(null);
  _project.regenerateDeviceId();
};

const identifyAnalytics = (user) => {
  if (_identity === user.uid) return;
  _identity = user.uid;
  log('Analytics: Identify', {
    user: user.uid
  });
  _project.setUserId(user.uid);
};

const trackEvent = (eventName, eventData) => {
  log(`Analytics: Event: ${eventName}`, eventData);
  _project.logEvent(eventName, eventData);
};

const _trackPageview = () => {
  log(`Analytics: Page Viewed: ${window.location.href}`);

  const eventData = {};
  eventData.path = window.location.pathname;
  eventData.url = window.location.href;
  eventData.search = window.location.search;
  if (!_.isEmpty(document.referrer)) eventData.referrer = document.referrer;

  _project.logEvent('Page Loaded', eventData);
};

class _TrackPageview extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname
    || this.props.location.search !== prevProps.location.search) {
      _trackPageview();
    }
  }

  render() {
    return null;
  }
}
const TrackPageview = withRouter(_TrackPageview);

export {
  initAnalytics,
  resetAnalytics,
  identifyAnalytics,
  trackEvent,
  TrackPageview
};
