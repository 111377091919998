import React, { Component } from 'react';
import './ItemList.scss';
import TimeAgo from 'react-timeago';
import _ from 'lodash';
import { PulseLoader } from 'react-spinners';
import LinesEllipsis from 'react-lines-ellipsis/lib/loose';
import { trackEvent } from '../data/Analytics';
import { ItemControls } from './ItemControls';

class ItemListRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      editing: false
    };
  }

  _handleItemOpen = (e) => {
    trackEvent('Item Opened');
  }

  _handleDeleteOpen = (e) => {
    e.preventDefault();
    this.setState({ deleting: true });
  }

  _handleDeleteClose = () => {
    this.setState({ deleting: false });
  }

  _handleEditOpen = (e) => {
    e.preventDefault();
    this.setState({ editing: true });
  }

  _handleEditClose = () => {
    this.setState({ editing: false });
  }

  render() {
    const item = this.props.item;

    const tagTokens = item.tags.map((t) => {
      return (<span key={t.id}> • {t.label}</span>);
    });

    const sourceToken = (<span key="source"> • {item.displaySource}</span>);

    const typeTokens = item.types.map((t) => {
      return (<span key={t.id}> • {t.label}</span>);
    });

    const tokens = _.concat(tagTokens, sourceToken, typeTokens);

    return (
      <div className="ItemListRow">
        <a className="ItemListRow__image-container" href={item.url} target="_blank" rel="noopener noreferrer" onClick={this._handleItemOpen}>
          {!_.isEmpty(item.imageUrls) &&
            <div className="ItemListRow__image">
              <img src={item.imageUrls[0]} alt="" />
            </div>
          }
        </a>
        <a className="ItemListRow__text-container" href={item.url} target="_blank" rel="noopener noreferrer" onClick={this._handleItemOpen}>
          <div className="ItemListRow__title">{item.displayTitle}</div>
          <div className="ItemListRow__description">{item.description}</div>
          <div className="ItemListRow__footer">
            {item.loading ? (
              <PulseLoader
                size={6}
                color="var(--text-light-light-gray)"/>
            ) : (
              <>
                <TimeAgo date={item.savedDate.toDate()} />
                {tokens}
              </>
            )}
          </div>
          { item.note &&
            <div className="ItemListRow__note-container">
              <LinesEllipsis
                text={item.note}
                className="ItemListRow__note"
                maxLine={2}
                ellipsis="..."
              />
            </div>
          }
        </a>
        <ItemControls
          className="ItemListRow__ItemControls"
          item={item}
          editable={this.props.editable} />
      </div>
    );
  }
}

class ItemList extends Component {
  constructor(props) {
    super(props);
    this._perPage = 50;
    this._pagesLoaded = 0;
    this.state = {
      loadedItems: []
    };
    this._itemListRef = React.createRef();
  }

  _loadMoreItems = () => {
    this._pagesLoaded += 1;
    this._updateLoadedItems();
  }

  _updateLoadedItems = () => {
    this.setState({ loadedItems: this.props.items.slice(0, this._pagesLoaded * this._perPage) });
  }

  _handleScroll = (e) => {
    var h = document.documentElement, 
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';
    const distanceFromBottom = ((h[sh]||b[sh]) - h.clientHeight) -(h[st]||b[st]);

    if (distanceFromBottom < 1000) {
      this._loadMoreItems();
    } 
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this._handleScroll);
    this._loadMoreItems();
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this._handleScroll);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.items !== prevProps.items) {
      this._updateLoadedItems();
    }
  }

  render() {
    // If we don't have any data, we render nothing
    // Containing views are responsible for showing sensible state for these cases
    if (_.isEmpty(this.state.loadedItems)) {
      return null;
    }

    const rows = this.state.loadedItems.map(item => {
      return (
        <ItemListRow
          key={item.id}
          item={item}
          editable={this.props.editable}
        />
      );
    });

    return (
      <div className="ItemList" ref={this._itemListRef}>
        {rows}
      </div>
    );
  }
}

export default ItemList;