import React, { Component } from 'react';
import './GetStartedButtons.scss';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import cx from 'classnames';
import env from '../data/env';
import SvgIconDownloadAppStoreLarge from '../images/IconDownloadAppStoreLarge';
import SvgIconDownloadAppStoreSmall from '../images/IconDownloadAppStoreSmall';

class DownloadAppButton extends Component {
  render() {
    const className = cx(
      'DownloadAppButton',
      {'DownloadAppButton--dark': this.props.dark},
      this.props.className
    );

    return (
      <a href={env.iOSAppDownloadURL} className={className}>
        <SvgIconDownloadAppStoreLarge className="DownloadAppButton__svg--large" />
        <SvgIconDownloadAppStoreSmall className="DownloadAppButton__svg--small" />
      </a>
    );
  }
}

class SignInButton extends Component {
  render() {
    return (
      <Link to="/auth/sign-in" className={cx('SignInButton btn secondary large', this.props.className)}>
        Sign In
      </Link>
    );
  }
}

class GetStartedButton extends Component {
  render() {
    if (isMobile) {
      return <DownloadAppButton className="GetStartedButton" />;
    }
    else {
      return <SignInButton className="GetStartedButton" />;
    }
  }
}

export {
  DownloadAppButton,
  SignInButton,
  GetStartedButton
};