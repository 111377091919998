import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './Select.scss';
import cx from 'classnames';
import _ from 'lodash';
import SvgIconDropdownIndicator from '../images/IconDropdownIndicator';

class Select extends Component {
  render() {
    const props = _.clone(this.props);
    props.className = cx('Select', props.className);
    return <Dropdown 
      arrowClosed={<SvgIconDropdownIndicator className="Select__dropdown-indicator" />}
      arrowOpen={<SvgIconDropdownIndicator className="Select__dropdown-indicator" />}
      {...props} />;
  }
}

export default Select;