import React, { Component } from 'react';
import './Settings.scss';
import { DataStore } from '../data/DataStore';
import { NavbarTitleHeader } from '../components/Navbar';
import { currentUserRecord } from '../data/Auth';
import Moment from 'react-moment';
import Checkbox from '../components/Checkbox';
import Loading from '../components/Loading';
import AccountDeleteModal from '../components/AccountDeleteModal';
import { trackEvent } from '../data/Analytics';
import { PlanSettingsTitle, PlanDescription } from '../components/Plan';
import { Link } from 'react-router-dom';
import SvgIconProfile from '../images/IconProfile';
import _ from 'lodash';

class NavbarSettingsHeader extends Component {
  render() {
    return <NavbarTitleHeader chevron to="/" title="Settings" />;
  }
}

class Settings extends Component {
  constructor() {
    super();
    this.dataStore = new DataStore();

    this.state = {
      sendEmailUpdates: false,
      deletingAccount: false
    };
  }

  _syncWithDataStore = () => {
    this.setState({
      fullName: this.dataStore.user.fullName,
      photoURL: this.dataStore.user.photoURL,
      sendEmailUpdates: this.dataStore.user.settings.sendEmailUpdates
    });
  }

  _handleEmailUpdatesChange = (newValue) => {
    this.dataStore.setEmailUpdatesPreference(newValue);
  }

  _showAccountDeleteModal = () => {
    this.setState({ deletingAccount: true });
  }

  _hideAccountDeleteModal = () => {
    this.setState({ deletingAccount: false });
  }

  componentDidMount = () => {
    trackEvent('Settings Viewed');
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  render() {
    if (!this.dataStore.initialized) {
      return <Loading />;
    }

    const user = currentUserRecord();
    const hasProfileImage = !_.isNull(this.state.photoURL);

    return (
      <div className="Settings">
        <div className="Settings__row Settings__row--reduced-padding">
          <div className="Settings__row__left">Photo</div>
          <div className="Settings__row__spacer"></div>
          {hasProfileImage ? (
            <img className="Settings__row__right Settings__photo" src={this.state.photoURL} alt="" />
          ) : (
            <div className="Settings__row__right Settings__photo Settings__photo--default"><SvgIconProfile /></div>
          )}
        </div>
        <div className="Settings__row">
          <div className="Settings__row__left">Name</div>
          <div className="Settings__row__spacer"></div>
          <div className="Settings__row__right">{this.state.fullName || 'User'}</div>
        </div>
        <div className="Settings__row">
          <div className="Settings__row__left">Email</div>
          <div className="Settings__row__spacer"></div>
          <div className="Settings__row__right">{user.email}</div>
        </div>
        <div className="Settings__row">
          <div className="Settings__row__left">Member since</div>
          <div className="Settings__row__spacer"></div>
          <div className="Settings__row__right">
            <Moment format="MMMM D, YYYY">{user.metadata.creationTime}</Moment>
          </div>
        </div>
        <div className="Settings__row">
          <div className="Settings__row__left">Plan</div>
          <div className="Settings__row__spacer"></div>
          <div className="Settings__row__right">
            <div><b><PlanSettingsTitle /></b></div>
            <div><PlanDescription /></div>
            <Link to="/settings/plans" className="Settings__link">Edit</Link>
          </div>
        </div>
        <div className="Settings__row">
          <div className="Settings__row__left">Email notifications</div>
          <div className="Settings__row__spacer"></div>
          <div className="Settings__row__right">
            <Checkbox 
              label="Receive occasional updates via email" 
              checked={this.state.sendEmailUpdates}
              onChange={this._handleEmailUpdatesChange} right />
          </div>
        </div>
        <div className="Settings__row">
          <div className="Settings__row__left">Delete account</div>
          <div className="Settings__row__spacer"></div>
          <div className="Settings__row__right">
            <div>This will delete your account and cannot be undone.</div>
            <div className="Settings__link Settings__link--warning" onClick={this._showAccountDeleteModal}>
              Delete account
            </div>
          </div>
        </div>

        { this.state.deletingAccount && 
          <AccountDeleteModal
            isOpen
            onRequestClose={this._hideAccountDeleteModal}
          />
        }
      </div>
    );
  }
}

export { NavbarSettingsHeader, Settings };