import React, { Component } from 'react';
import { withAuth, currentUser, AUTH_STATE } from '../data/Auth';
import { toast, ToastDialog, ToastDialogBody, ToastDialogButtons } from '../components/Toast';
import { DataStore } from '../data/DataStore';
import _ from 'lodash';

// Format of country codes below is ISO-3166-2
const COUNTRIES_REQUIRING_PRIVACY_CONSENT = [
  'AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES',
  'FI', 'FR', 'GB', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT',
  'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI',
  'SK'
];

class PrivacyConsentHandler extends Component {
  constructor() {
    super();
    this.dataStore = new DataStore();
  }

  componentDidMount = () => {
    const localStorageConsent = localStorage.getItem('dumpster_privacy_consent') === 'true';
    const user = currentUser();
    const userModelConsent = _.get(user, 'settings.privacyConsent');

    if (this.props.authState > AUTH_STATE.UNAUTHENTICATED) {
      if (localStorageConsent) {
        if (!userModelConsent) {
          this.dataStore.setPrivacyConsent(true);
        }
      }
      else if (userModelConsent) {
        localStorage.setItem('dumpster_privacy_consent', 'true');
      }
      else {
        this._checkGeographyRequirements();
      }
    }
    else {
      if (!localStorageConsent) {
        this._checkGeographyRequirements();
      }
    }
  }

  _checkGeographyRequirements = () => {
    this.dataStore.getGeography({}).then(({ data }) => {
      if (COUNTRIES_REQUIRING_PRIVACY_CONSENT.includes(data.country)) {
        this._showPrivacyToast();
      }
    });
  }

  _showPrivacyToast = () => {
    toast(({ closeToast }) =>
      <ToastDialog>
        <ToastDialogBody>
          Hi there, we use cookies to offer you a better browsing experience and to analyze
          site traffic. By continuing to use our website, you consent to the use of these
          cookies. <a href="https://help.dumpster.com">Learn more.</a>
        </ToastDialogBody>
        <ToastDialogButtons>
          <div className="btn" onClick={() => { this._handleConsent(closeToast); }}>Got it!</div>
        </ToastDialogButtons>
      </ToastDialog>
    , { autoClose: false });
  }

  _handleConsent = (closeToast) => {
    localStorage.setItem('dumpster_privacy_consent', 'true');
    if (this.props.authState > AUTH_STATE.UNAUTHENTICATED) {
      this.dataStore.setPrivacyConsent(true);
    }
    closeToast();
  }
  
  render() {
    return null;
  }
}

export default withAuth(PrivacyConsentHandler);