import React, { Component } from 'react';
import './SearchBar.scss';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash';
import cx from 'classnames';
import { DataStore } from '../data/DataStore';
import TokenInput from './TokenInput';
import { Tokens, TagToken, TypeToken } from './Tokens';
import { trackEvent } from '../data/Analytics';
import SvgIconSearch from '../images/IconSearch';
import SvgIconSearchCancel from '../images/IconSearchCancel';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagFilter: null,
      typeFilter: null,
      searchTerm: '',
      focused: false,
      recentSearches: [],
      recentTags: [],
      recentTypes: []
    };

    this.dataStore = new DataStore();
    this.inputRef = React.createRef();
    this.waitBeforeSearch = 1000; // ms to wait before triggering search
    this.searchTimeout = null;
    this.ignoreNextSearchQueryChange = false;
  }

  componentDidMount = () => {
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(this.props.location, prevProps.location)) {
      if (this.ignoreNextSearchQueryChange) {
        this.ignoreNextSearchQueryChange = false;
      }
      else {
        this._updateSearchFromQuery();
      }
    }
  }

  _syncWithDataStore = (dataTypeUpdated) => {
    const recentSearches = _
      .chain(this.dataStore.user.searchQueries)
      .orderBy('date', 'desc')
      .take(3)
      .value();

    const recentTags = _
      .chain(this.dataStore.tags)
      .filter((t) => { return t.items.length > 0; })
      .orderBy('mostRecentItem.savedDate', 'desc')
      .take(6)
      .value();

    const recentTypes = _
      .chain(this.dataStore.types)
      .filter((t) => { return t.items.length > 0; })
      .orderBy('mostRecentItem.savedDate', 'desc')
      .take(6)
      .value();
    
    this.setState({
      recentSearches: recentSearches,
      recentTags: recentTags,
      recentTypes: recentTypes
    });

    if (dataTypeUpdated !== 'user') {
      this._updateSearchFromQuery();
    }
  }

  _reset = () => {
    this.setState({
      tagFilter: null,
      typeFilter: null,
      searchTerm: ''
    }, () => this._updateQueryFromSearch());
  }
  
  _updateSearchFromQuery = () => {
    const query = {
      tagFilter: null,
      typeFilter: null,
      searchTerm: ''
    };

    const params = qs.parse(
      this.props.location.search,
      { ignoreQueryPrefix: true }
    );

    if (this.props.location.pathname === '/search') {
      query.tagFilter = params['tag'] || null;
      query.typeFilter = params['type'] || null;
      query.searchTerm = params['search'] || '';
    }
    else if (_.startsWith(this.props.location.pathname, '/list/')) {
      const tagId = this.props.location.pathname.slice(6);
      const typeId = params['type'];
      query.tagFilter =  this.dataStore.tagsById[tagId] ? tagId : null;
      query.typeFilter = this.dataStore.typesById[typeId] ? typeId : null;
    }

    this.setState(query);
  }

  _updateQueryFromSearch = () => {
    const params = qs.parse(
      this.props.location.search,
      { ignoreQueryPrefix: true }
    );
    params['search'] = this.state.searchTerm !== '' ? this.state.searchTerm : undefined;
    params['tag'] = this.state.tagFilter || undefined;
    params['type'] = this.state.typeFilter || undefined;

    this.ignoreNextSearchQueryChange = true;

    const queryString = qs.stringify(params);
    if (queryString.length > 0) {
      this.props.history.push('/search?' + queryString);
    }
    else {
      this.props.history.push('/');
    }
  }

  _submitSearch = () => {
    trackEvent('Search Query Executed', {
      tagFilter: !_.isNull(this.state.tagFilter),
      typeFilter: !_.isNull(this.state.typeFilter)
    });
    this._updateQueryFromSearch();
  }

  _handleInputKeyDown = (e) => {
    // Blur on esc key press
    if (e.keyCode === 27) {
      this.inputRef.current.blur();
    }
    // Search on enter press
    else if (e.keyCode === 13) {
      clearTimeout(this.searchTimeout);
      this._submitSearch();
    }
  }

  _handleInputValueChange = (e) => {
    const inputValue = e.target.value;
    this.setState({ searchTerm: inputValue }, () => {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this._submitSearch();
      }, this.state.searchTerm === '' ? 0 : this.waitBeforeSearch);
    });
  }

  _handleFocus = () => {
    trackEvent('Search Focused');
    this.setState({ focused: true });
  }

  _handleBlur = () => {
    if (this.state.searchTerm !== '') this.dataStore.saveSearchQuery(this.state.searchTerm);
    this.setState({ focused: false });
  }

  _handleRemoveToken = () => {
    this.setState({
      tagFilter: null,
      typeFilter: null
    }, () => {
      this._updateQueryFromSearch();
      this.inputRef.current.focus();
    });
  }

  _handleRecentSearchClick = (query) => {
    return (e) => {
      trackEvent('Recent Search Query Opened');
      this.setState({
        searchTerm: query
      }, () => {
        this._submitSearch();
      });
    };
  }

  _handleTokenClick = (e) => {
    trackEvent('Search Token Opened');
  }

  _preventMouseDown = (e) => {
    e.preventDefault();
  }

  render() {
    const tag = !_.isNil(this.state.tagFilter) ? this.dataStore.tagsById[this.state.tagFilter] : undefined;
    const type = !_.isNil(this.state.typeFilter) ? this.dataStore.typesById[this.state.typeFilter] : undefined;
    const isSearching = this.state.searchTerm !== '' || !_.isNil(tag) || !_.isNil(type);
    const isExpanded = this.state.focused || isSearching;

    let token = null;
    if (this.state.tagFilter) {
      if (tag !== undefined) {
        tag.kind = 'tag';
        token = <TagToken key={0} tag={tag} onRemove={this._handleRemoveToken} onMouseDown={this._preventMouseDown} />;
      }
    }
    else if (this.state.typeFilter) {
      const type = this.dataStore.typesById[this.state.typeFilter];
      if (type !== undefined) {
        type.kind = 'type';
        token = <TypeToken key={0} type={type} onRemove={this._handleRemoveToken} onMouseDown={this._preventMouseDown} />;
      }
    }

    const rootClassName = cx({
      'SearchBar': true,
      'SearchBar--hover-border-small': this.state.focused
    });

    const inputClassName = cx({
      'SearchBar__input': true,
      'SearchBar__input--expanded': isExpanded
    });

    const dropdownClassName = cx({
      'SearchBar__dropdown': true,
      'SearchBar__dropdown--visible': this.state.focused && !isSearching
    });

    const cancelClassName = cx({
      'SearchBar__cancel': true,
      'SearchBar__cancel--show': isSearching
    });

    const recentSearches = this.state.recentSearches.map((s, index) => {
      return (
        <div 
          className="SearchBar__dropdown__recentSearch"
          onClick={this._handleRecentSearchClick(s.query)}
          key={index}>
          {s.query}
        </div>
      );
    });

    const tagTokens = this.state.recentTags.map(t => {
      return (<TagToken hover link onClick={this._handleTokenClick} key={t.id} tag={t} />);
    });

    const typeTokens = this.state.recentTypes.map(t => {
      return (<TypeToken hover link onClick={this._handleTokenClick} key={t.id} type={t} />);
    });

    return (
      <div className={rootClassName}>
        <SvgIconSearch className="SearchBar__icon" />
        <TokenInput 
          className={inputClassName}
          placeholder={isSearching ? '' : 'Search'}
          ref={this.inputRef}
          tokens={[token]}
          value={this.state.searchTerm}
          onChange={this._handleInputValueChange}
          onKeyDown={this._handleInputKeyDown}
          onFocus={this._handleFocus}
          onBlur={this._handleBlur}
          onRemoveToken={this._handleRemoveToken}
        />
        <SvgIconSearchCancel className={cancelClassName} onClick={this._reset} />
        {(this.state.recentSearches.length > 0 
          || this.state.recentTags.length > 0 
          || this.state.recentTypes.length > 0) &&
          <div className={dropdownClassName} onMouseDown={this._preventMouseDown}>
            {this.state.recentSearches.length > 0 &&
              <>
                <div className="SearchBar__dropdown__header">Recent searches</div>
                <div className="SearchBar__dropdown__recentSearches">
                  {recentSearches}
                </div>
              </>
            }
            {this.state.recentTags.length > 0 &&
              <>
                <div className="SearchBar__dropdown__header">Lists</div>
                <Tokens>{tagTokens}</Tokens>
              </>
            }
            {this.state.recentTypes.length > 0 &&
              <>
                <div className="SearchBar__dropdown__header">Types</div>
                <Tokens>{typeTokens}</Tokens>
              </>
            }
          </div>
        }
      </div>
    );
  }
}

export default withRouter(SearchBar);