import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import { DataStore } from '../data/DataStore';
import Loading from './Loading';
import { trackEvent } from '../data/Analytics';
import moment from 'moment';
import _ from 'lodash';
import { PLANS } from './Plan';

class StripeSwitchPlanModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this._dataStore = new DataStore();
  }

  _handleAfterOpen = () => {
    trackEvent('Stripe Switch Plan Opened');
  }

  _handleChangePlan = (token) => {
    this.setState({ loading: true });
    this._dataStore.createStripeSubscription({
      plan: this.props.planType
    }).then(() => {
      trackEvent('Stripe Plan Switched');
      this.props.onRequestClose();
    }).catch((err) => {
      this.setState({ loading: false });
      throw err;
    });
  }

  _handleKeepPlan = (e) => {
    e.preventDefault();
    trackEvent('Stripe Switch Plan Aborted');
    this.props.onRequestClose();
  }

  render() {
    const plan = this.props.plan;
    const currentPeriodEndMoment = _.isNull(plan.currentPeriodEndDate) ? moment() : moment(plan.currentPeriodEndDate.toDate());
    const datePeriodEnds = currentPeriodEndMoment.format('l');

    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={!this.state.loading}
        shouldCloseOnOverlayClick={!this.state.loading}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header">Change plan?</div>
        <div className="Modal__row">
          <div className="Modal__text">
            Are you sure you want to change to the {this.props.planType} Dumpster Premium plan?<br/>
            You will be charged {PLANS[this.props.planType].PRICE_STRING} per {PLANS[this.props.planType].PERIOD_STRING} starting on {datePeriodEnds}.
          </div>
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this._handleKeepPlan}>Don't change plan</button>
          <button className="Modal__button btn" onClick={this._handleChangePlan}>Change plan</button>
        </div>
        
        {this.state.loading && <Loading opaque />}
      </ReactModal>
    );
  }
}

export default StripeSwitchPlanModal;