import React, { Component } from 'react';
import './Tokens.scss';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import _ from 'lodash';
import SvgIconTokenRemove from '../images/IconTokenRemove';
import SvgIconTokenNew from '../images/IconTokenNew';

class Token extends Component {
  _onRemoveClick = (e) => {
    e.preventDefault();
    this.props.onRemove();
  }

  render() {
    const className = cx({
      'Token': true,
      'Token--clickable': this.props.link || _.isFunction(this.props.onClick) || this.props.selectable,
      'Token--hover': this.props.hover,
      'Token--selectable': this.props.selectable,
      'Token--selectable--hover': this.props.selectable && this.props.hover
    }, this.props.className);

    const strippedProps = _.omit(this.props, [
      'className',
      'hover',
      'selectable',
      'linkTo',
      'link',
      'onRemove'
    ]);

    if (this.props.linkTo) {
      return (
        <Link className={className} to={this.props.linkTo} {...strippedProps}>
          <div className="Token__label">{this.props.children}</div>
          {this.props.onRemove && 
            <div className="Token__remove" onClick={this._onRemoveClick}>
              <SvgIconTokenRemove />
            </div>
          }
        </Link>
      );
    }
    else {
      return (
        <div className={className} {...strippedProps}>
          <div className="Token__label">{this.props.children}</div>
          {this.props.onRemove &&
            <div className="Token__remove" onClick={this._onRemoveClick}>
              <SvgIconTokenRemove />
            </div>
          }
        </div>
      );
    }
  }
}

class SourceToken extends Component {  
  render() {
    const className = cx({
      'Token--source': true,
      'Token--source--hover': this.props.hover,
      'Token--source--selectable': this.props.selectable,
      'Token--source--selectable--hover': this.props.selectable && this.props.hover
    });
    return (
      <Token className={className} {...this.props}>{this.props.label}</Token>
    );
  }
}

class TypeToken extends Component {
  render() {
    const className = cx({
      'Token--type': true,
      [`Token--type-${this.props.type.value}`]: true,
      'Token--type--hover': this.props.hover,
      'Token--type--selectable': this.props.selectable,
      'Token--type--selectable--hover': this.props.selectable && this.props.hover
    });
    const linkTo = this.props.link ? `/search?type=${this.props.type.id}` : undefined;
    return (
      <Token className={className} linkTo={linkTo} {...this.props}>
        {this.props.type.label}
      </Token>
    );
  }
}

class TagToken extends Component {
  render() {
    const className = cx({
      'Token--tag': true,
      'Token--tag--hover': this.props.hover,
      'Token--tag--selectable': this.props.selectable,
      'Token--tag--selectable--hover': this.props.selectable && this.props.hover,
      'Token--tag--new': this.props.tag._new
    });
    const linkTo = this.props.link ? `/list/${this.props.tag.id}` : undefined;
    return (
      <Token className={className} linkTo={linkTo} {...this.props}>
        {this.props.tag._new && <SvgIconTokenNew />}
        {this.props.tag.label}
      </Token>
    );
  }
}

class Tokens extends Component {
  render() {
    const className = `Tokens ${this.props.className}`;
    return (
      <div className={className}>
        {this.props.children}
      </div>
    );
  }
}

export {
  Tokens,
  TagToken,
  TypeToken,
  SourceToken
};