import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import './Navbar.scss';
import cx from 'classnames';
import SvgIconLogo from '../images/IconLogo';
import SvgIconBack from '../images/IconBack';
import SvgIconMenu from '../images/IconMenu';

function NavbarBrandHeader (props) {
  const className = cx('NavbarBrandHeader', props.className);
  const logoClassName = cx(
    'NavbarBrandHeader__logo', 
    { 'NavbarBrandHeader__logo--large': props.large }
  );
  const titleClassName = cx(
    'NavbarBrandHeader__title', 
    { 'NavbarBrandHeader__title--large': props.large },
    { 'NavbarBrandHeader__title--hide-on-mobile': props.hideTitleOnMobile }
  );
  return (
    <div className={className}>
      <Link className={logoClassName} to="/">
        <SvgIconLogo />
      </Link>
      <Link className={titleClassName} to="/">Dumpster</Link>
    </div>
  );
}

class _NavbarTitleHeader extends Component {
  _handleBack = () => {
    if (this.props.to) {
      this.props.history.replace(this.props.to);
    }
    else if (window.history.length > 1) {
      window.history.back();
    }
    else {
      this.props.history.replace('/');
    }
  }

  render() {
    return (
      <div className="NavbarTitleHeader">
        {this.props.chevron &&
          <div className="NavbarTitleHeader__chevron" onClick={this._handleBack}>
            <SvgIconBack />
          </div>
        }
        {this.props.avatar &&
          <img className="NavbarTitleHeader__avatar" src={this.props.avatar} alt="" />
        }
        <div>
          <div className="NavbarTitleHeader__title">
            <span className="NavbarTitleHeader__title__prepend">{this.props.prepend}</span>
            {this.props.title}
          </div>
          {this.props.subtitle && <div className="NavbarTitleHeader__subtitle">{this.props.subtitle}</div>}
        </div>
        {this.props.extras &&
          <div className="NavbarTitleHeader__extras">
            {this.props.extras}
          </div>
        }
      </div>
    );
  }
}
const NavbarTitleHeader = withRouter(_NavbarTitleHeader);

function NavbarSpacer () {
  return <div className="NavbarSpacer"></div>;
}

function NavbarDivider () {
  return <div className="NavbarDivider"><div></div></div>;
}

function NavbarButton (props) {
  return <div className="NavbarButton btn secondary">{props.children}</div>;
}

function NavbarMenuTrigger (props) {
  return <SvgIconMenu className="NavbarMenuTrigger" onClick={props.onClick} />;
}

function NavbarLink (props) {
  const className = cx('NavbarLink', props.className);
  return (
    <NavLink 
      className={className}
      activeClassName="NavbarLink--active" 
      to={props.to}
      target={props.openInNewTab ? '_blank' : undefined}>
      {props.children}
    </NavLink>
  );
}

class Navbar extends Component {
  render() {
    const className = cx({
      'Navbar': true,
      'Navbar--app': this.props.app,
      'Navbar--brand': this.props.brand,
      'Navbar--banner': this.props.banner,
      'Navbar--footer': this.props.footer,
      'Navbar--menu': this.props.showAsMenu
    }, this.props.className);
    
    return (
      <nav className={className}>
        {this.props.children}
      </nav>
    );
  }
}

export { 
  Navbar,
  NavbarBrandHeader,
  NavbarTitleHeader,
  NavbarSpacer,
  NavbarDivider,
  NavbarMenuTrigger,
  NavbarLink,
  NavbarButton
};
export default Navbar;