import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import _ from 'lodash';
import { DataStore } from '../data/DataStore';
import { trackEvent } from '../data/Analytics';

class TagEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: this.props.tag.label,
      changed: false
    };
    this.labelInput = React.createRef();
    this.dataStore = new DataStore();
  }

  componentDidMount = () => {
    setTimeout(() => { this.labelInput.current.select(); }, 0);
  }

  _handleAfterOpen = () => {
    trackEvent('Edit Tag Opened');
  }

  _updateChanged = () =>{
    this.setState({
      changed: !_.isEqual(this.state.label, this.props.tag.label)
    });
  }

  _handleLabelChange = (e) => {
    this.setState({ label: e.target.value }, () => {
      this._updateChanged();
    });
  }

  _handleLabelKeyUp = (e) => {
    if (e.keyCode === 13) {
      this._handleSave();
    }
  }

  _handleSave = (e) => {
    if (e) e.preventDefault();
    if (!this.state.changed) return;

    this.dataStore.editTag({
      id: this.props.tag.id,
      label: this.state.label
    });

    this.props.onRequestClose();
  }

  _handleCancel = (e) => {
    e.preventDefault();
    this.props.onRequestClose();
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header">Edit list title</div>
        <div className="Modal__row">
          <input
            className="Modal__input"
            ref={this.labelInput}
            value={this.state.label}
            onChange={this._handleLabelChange}
            onKeyUp={this._handleLabelKeyUp}
            type="text"
            placeholder="List title"/>
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this._handleCancel}>Cancel</button>
          <button className="Modal__button btn" disabled={!this.state.changed} onClick={this._handleSave}>Save</button>
        </div>
      </ReactModal>
    );
  }
}

export default TagEditModal;