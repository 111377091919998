import * as React from 'react';

function SvgIconSearch(props) {
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        d="M15.726 14.374l-3.298-3.351c-.04-.04-.051-.1-.02-.145 1.852-2.702 1.599-6.44-.771-8.85a6.74 6.74 0 00-9.64.001c-2.663 2.705-2.663 7.092 0 9.797a6.74 6.74 0 004.82 2.03c1.495 0 2.988-.5 4.223-1.494.02-.015.045-.01.062.007L14.4 15.72a.925.925 0 001.326.001.964.964 0 000-1.347zm-8.91-2.423a4.872 4.872 0 01-3.494-1.471c-1.927-1.959-1.927-5.146 0-7.104a4.871 4.871 0 013.495-1.471c1.32 0 2.56.522 3.495 1.471 1.927 1.958 1.927 5.145 0 7.104a4.872 4.872 0 01-3.495 1.47z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconSearch;
