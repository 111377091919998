import React, { Component } from 'react';
import './EmptyState.scss';
import { Link } from 'react-router-dom';

class EmptyState extends Component {
  render() {
    const message = this.props.message || 'Nothing to see here';
    return (
      <div className="EmptyState">
        <div>
          <div className="EmptyState__header">{message}</div>
          <div className="EmptyState__body">
            Stuff you save appears here. Make sure to use our
            <a className="EmptyState__link" href="https://chrome.google.com/webstore/detail/dumpster/lcmgcfibdfmboahplofobcneiaggopcp" target="_blank" rel="noopener noreferrer"> Chrome extension </a>
            to quickly save new items. 
          </div>
          <Link className="EmptyState__button btn" to="/get-started">Learn more</Link>
        </div>
      </div>
    );
  }
}

export default EmptyState;