import React, { Component } from 'react';
import './TagList.scss';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { DataStore } from '../data/DataStore';
import { TagControls } from './TagControls';

class TagListRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      editing: false
    };
    this.dataStore = new DataStore();
  }
  
  _handleEditOpen = (e) => {
    e.preventDefault();
    this.setState({ editing: true });
  }

  _handleEditClose = () => {
    this.setState({ editing: false });
  }

  _handleDeleteOpen = (e) => {
    e.preventDefault();
    this.setState({ deleting: true });
  }

  _handleDeleteClose = (e) => {
    this.setState({ deleting: false });
  }

  render() {
    const tag = this.props.tag;
    return (
      <div className="TagListRow">
        <div className="TagListRow__content">
          <Link className="TagListRow__header" to={`/list/${this.props.tag.id}`}>
            <div className="TagListRow__label">{tag.label}</div>
            <div className="TagListRow__items-count">{tag.items.length}</div>
          </Link>
          <Link className="TagListRow__text-container" to={`/list/${this.props.tag.id}`}>
            <div className="TagListRow__recent-item-title">{tag.mostRecentItem.displayTitle}</div>
            <div className="TagListRow__recent-item-footer">
              {tag.mostRecentItem.displaySource} • <TimeAgo date={tag.mostRecentItem.savedDate.toDate()} />
            </div>
          </Link>
        </div>
        
        <TagControls
          className="TagListRow__TagControls"
          tag={tag}
          editable />
      </div>
    );
  }
}

class TagList extends Component {
  render() {
    // If we don't have any data, we render nothing
    // Containing views are responsible for showing sensible state for these cases
    if (_.isNil(this.props.tags) || this.props.tags.length === 0) {
      return null;
    }

    var rows = [];
    this.props.tags.forEach(tag => {
      // Ignore tags with no items
      if (tag.items.length === 0) return;
      rows.push((
        <TagListRow
          key={tag.id}
          tag={tag}
        />
      ));
    });

    return (
      <div className="TagList">
        {rows}
      </div>
    );
  }
}

export default TagList;