import React, { Component } from 'react';
import './AccountDropdown.scss';
import Dropdown, { DropdownTrigger, DropdownContent } from './Dropdown';
import { DataStore } from '../data/DataStore';
import { trackEvent } from '../data/Analytics';
import { Link } from 'react-router-dom';
import { currentUserRecord } from '../data/Auth';
import cx from 'classnames';
import _ from 'lodash';
import SvgIconProfile from '../images/IconProfile';

class AccountDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      active: false
    };

    this.dataStore = new DataStore();
  }

  componentDidMount = () => {
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  _syncWithDataStore = () => {
    this.setState({
      initialized: true,
      itemCount: this.dataStore.items.length,
      tagCount: this.dataStore.tags.length,
      planType: this.dataStore.user.plan.type,
      fullName: this.dataStore.user.fullName,
      photoURL: this.dataStore.user.photoURL
    });
  }

  _handleShow = () => {
    trackEvent('Profile Viewed');
    this.setState({ active: true });
  }

  _handleHide = () => {
    this.setState({ active: false });
  }

  render() {
    const user = currentUserRecord();

    if (user === null) {
      return null;
    }

    let itemCountString = '';
    if (this.state.itemCount === 0) {
      itemCountString = 'No items';
    }
    else if (this.state.itemCount === 1) {
      itemCountString = '1 item';
    }
    else {
      itemCountString = this.state.itemCount + ' items';
    }

    let tagCountString = '';
    if (this.state.tagCount === 0) {
      tagCountString = 'No lists';
    }
    else if (this.state.tagCount === 1) {
      tagCountString = '1 list';
    }
    else {
      tagCountString = this.state.tagCount + ' lists';
    }

    const hasProfileImage = this.state.initialized && !_.isNull(this.state.photoURL);
    const avatarClassName = cx({
      'AccountDropdown__trigger-avatar': hasProfileImage,
      'AccountDropdown__trigger-avatar--hover-border-small': hasProfileImage && this.state.active,
      'AccountDropdown__trigger-avatar-default': !hasProfileImage,
      'AccountDropdown__trigger-avatar-default--active': !hasProfileImage && this.state.active
    });

    return (
      <Dropdown className="AccountDropdown" onShow={this._handleShow} onHide={this._handleHide} hideOnNavigate>
        <DropdownTrigger className="AccountDropdown__trigger">
          {hasProfileImage ? (
            <img className={avatarClassName} src={this.state.photoURL} alt="" />
          ) : (
            <div className={avatarClassName}><SvgIconProfile /></div>
          )}
        </DropdownTrigger>
        <DropdownContent className="AccountDropdown__content">
          {hasProfileImage ? (
            <img className="AccountDropdown__avatar" src={this.state.photoURL} alt="" />
          ) : (
            <div className="AccountDropdown__avatar AccountDropdown__avatar--default"><SvgIconProfile /></div>
          )}
          <div className="AccountDropdown__counts">
            <span>{tagCountString}</span>
            <span>{itemCountString}</span>
          </div>
          <div className="AccountDropdown__display-name">{this.state.fullName || 'User'}</div>
          <div className="AccountDropdown__email">{user.email}</div>
          {this.state.planType === null ? (
            <Link to="/settings/plans" className="AccountDropdown__upgrade-button btn medium secondary">Upgrade to Premium</Link>
          ) : (
            <Link to="/settings/plans" className="AccountDropdown__premium-indicator">Premium Account</Link>
          )}
          <div className="AccountDropdown__divider"><div></div></div>
          <Link className="AccountDropdown__link" to="/settings">Settings</Link>
          <Link className="AccountDropdown__link" to="/install">Install</Link>
          <Link className="AccountDropdown__link" to="/blog" target="_blank">Blog</Link>
          <Link className="AccountDropdown__link" to="/help">Help &amp; FAQ</Link>
          <Link className="AccountDropdown__link AccountDropdown__link--warning" to="/auth/sign-out">Sign out</Link>
        </DropdownContent>
      </Dropdown>
    );
  }
}

export default AccountDropdown;
