import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import { DataStore } from '../data/DataStore';
import Loading from '../components/Loading';
import { trackEvent } from '../data/Analytics';
import Checkbox from '../components/Checkbox';
import { toast } from '../components/Toast';
import _ from 'lodash';

class TagDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alsoDeleteItems: false
    };
    this.dataStore = new DataStore();
  }

  _handleAfterOpen = () => {
    trackEvent('Delete Tag Opened');
  }

  _switchDeleteItems = () => {
    this.setState({ alsoDeleteItems: !this.state.alsoDeleteItems });
  }

  _handleDelete = (e) => {
    if (e) e.preventDefault();
    const toastId = toast('Deleting list...', { autoClose: false, closeOnClick: false });
    this.props.onRequestClose();
    this.dataStore.deleteTag({
      id: this.props.tag.id,
      alsoDeleteItems: this.state.alsoDeleteItems
    }).then(() => {
      toast.update(toastId, {
        render: 'List deleted!',
        autoClose: 3000,
        closeOnClick: true
      });
      if (_.isFunction(this.props.onDeleted)) {
        this.props.onDeleted();
      }
    }, (err) => {
      toast.dismiss(toastId);
    });
  }

  _handleCancel = (e) => {
    e.preventDefault();
    this.props.onRequestClose();
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={!this.state.deleting}
        shouldCloseOnOverlayClick={!this.state.deleting}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header Modal__header--warning">Delete list?</div>
        <div className="Modal__row">
          <div className="Modal__text">
            Are you sure you want to delete the list <em>{this.props.tag.label}</em> from your Dumpster? This cannot be undone.
          </div>
        </div>
        <div className="Modal__row">
          <div className="Modal__subtext">
            <Checkbox 
              label="Also delete items" 
              checked={this.state.alsoDeleteItems}
              onChange={this._switchDeleteItems}
              warning />
          </div>
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this._handleCancel}>Cancel</button>
          <button className="Modal__button btn warning" onClick={this._handleDelete}>Delete</button>
        </div>
        {this.state.deleting && <Loading opaque />}
      </ReactModal>
    );
  }
}

export default TagDeleteModal;