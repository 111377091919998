import React, { Component } from 'react';
import './Tag.scss';
import { DataStore } from '../data/DataStore';
import { 
  NavbarBrandHeader,
  NavbarTitleHeader,
  NavbarDivider
} from '../components/Navbar';
import ViewToggle from '../components/ViewToggle';
import ItemSortSelect from '../components/ItemSortSelect';
import Loading from '../components/Loading';
import NoResults from '../components/NoResults';
import ItemGrid from '../components/ItemGrid';
import ItemList from '../components/ItemList';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { trackEvent } from '../data/Analytics';
import NotFound from './NotFound';
import { AUTH_STATE, AuthComponent } from '../data/Auth';
import { TagControls } from '../components/TagControls';

class _NavbarTagAuthenticatedHeader extends Component {
  constructor(props) {
    super(props);
    this.dataStore = new DataStore();
    this.state = {
      tag: undefined,
      savedBy: undefined,
      editing: false,
      deleting: false
    };
  }

  componentDidMount = () => {
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this._syncWithDataStore();
    }
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  _syncWithDataStore = async () => {
    const { tag, savedBy } = await this.dataStore.getTag(this.props.match.params.id);
    this.setState({ tag, savedBy });
  }

  _handleDeleteOpen = (e) => {
    e.preventDefault();
    this.setState({ deleting: true });
  }

  _handleDeleteClose = () => {
    this.setState({ deleting: false });
  }

  _handleDeleted = () => {
    this.props.history.push('/');
  }
  
  _handleEditOpen = (e) => {
    e.preventDefault();
    this.setState({ editing: true });
  }

  _handleEditClose = () => {
    this.setState({ editing: false });
  }

  render() {
    const { tag } = this.state;

    if (_.isUndefined(tag)) {
      return null;
    }

    // If tag isn't found we render the brand header
    if (_.isNull(tag)) {
      return <NavbarBrandHeader />;
    }

    if (tag.shared) {
      const extras = <TagControls tag={tag} reverse />;

      return (
        <NavbarTitleHeader
          chevron
          avatar={this.state.savedBy.photoURL}
          prepend={`${this.state.savedBy.firstName || 'User'}'s `}
          title={tag.label}
          extras={extras}
        />
      );
    }
    else {
      const subtitle = tag !== null ? `${tag.items.length} ${tag.items.length === 1 ? 'item': 'items'}` : '';

      const extras = <TagControls tag={tag} onDeleted={this._handleDeleted} editable reverse />;

      return (
        <NavbarTitleHeader
          chevron
          title={tag !== null ? tag.label : ''}
          subtitle={subtitle}
          extras={extras}
        />
      );
    }
  }
}
const NavbarTagAuthenticatedHeader = withRouter(_NavbarTagAuthenticatedHeader);

class TagAuthenticated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: undefined,
      items: null,
      activeView: null
    };
    this.dataStore = new DataStore();
    this.firstTagRender = false;
  }

  componentDidMount = () => {
    this.firstTagRender = true;
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.firstTagRender = true;
      this._syncWithDataStore();
    }
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  _syncWithDataStore = async () => {
    const { tag } = await this.dataStore.getTag(this.props.match.params.id);

    if (tag === null) {
      this.setState({ tag: null });
      return;
    }

    if (this.firstTagRender) {
      if (tag.shared) {
        trackEvent('Shared Tag Viewed');
      }
      else {
        trackEvent('Tag Viewed');
      }
      this.firstTagRender = false;
    }
    const { value, field, order } = this.dataStore.getDefaultItemSortOrder();
    const fieldResolver = (['title', 'siteDomain'].includes(field)) ? item => item[field].toLowerCase() : field;
    this.setState({
      tag: tag,
      items: _.orderBy(tag.items, fieldResolver, order),
      sortValue: value,
      activeView: this.dataStore.getDefaultItemView()
    });
  }

  render() {
    const { tag } = this.state;
    
    // If we are still loading
    if (_.isUndefined(tag)) {
      return <Loading />;
    }

    // If null, tag couldn't be found or loaded
    if (_.isNull(tag)) {
      return <NotFound />;
    }

    // If we have no results
    if (this.state.items.length === 0) {
      return <NoResults message="This tag has no saved items!" />;
    }

    return (
      <div className="Tag">
        <div className="Tag__header">
          <div className="Tag__spacer"></div>
          <ViewToggle activeView={this.state.activeView} onViewToggle={this.dataStore.setDefaultItemView} />
          <ItemSortSelect value={this.state.sortValue} onChange={this.dataStore.setDefaultItemSortOrder} />
        </div>
        {this.state.activeView === 'grid' && <ItemGrid editable={!tag.shared} clickableTokens={!tag.shared} items={this.state.items} />}
        {this.state.activeView === 'list' && <ItemList editable={!tag.shared} items={this.state.items} />}
      </div>
    );
  }
}

class _NavbarTagUnauthenticatedHeader extends Component {
  constructor() {
    super();

    this.state = {
      tag: undefined,
      items: undefined,
      savedBy: undefined,
      types: undefined
    };

    this.dataStore = new DataStore();
  }

  _getTag = () => {
    this.dataStore.getTag(this.props.match.params.id).then((data) => {
      this.setState(data);
    }).catch((err) => {
      alert('There was an error fetching the tag data.');
      throw err;
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this._getTag();
    }
  }

  componentDidMount = () => {
    this._getTag();
  }

  render() {
    const { tag } = this.state;

    if (_.isUndefined(tag)) {
      return null;
    }

    // If tag isn't found we render the brand header
    if (_.isNull(tag)) {
      return <NavbarBrandHeader />;
    }
    
    const extras = <TagControls tag={tag} reverse />;
    return (
      <>
        <NavbarBrandHeader hideTitleOnMobile />
        <NavbarDivider />
        <NavbarTitleHeader
          avatar={this.state.savedBy.photoURL}
          prepend={`${this.state.savedBy.firstName || 'User'}'s `}
          title={tag.label}
          extras={extras}
        />
      </>
    );
  }
}
const NavbarTagUnauthenticatedHeader = withRouter(_NavbarTagUnauthenticatedHeader);

class TagUnauthenticated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortValue: 'savedDate',
      activeView: 'grid',
      tag: undefined,
      items: null,
      savedBy: null,
      types: null
    };

    this.dataStore = new DataStore();
  }

  _setSortOrder = (newSortOrder) => {
    const { field, order } = this.dataStore.getDefaultItemSortOrder(newSortOrder);
    this.setState({ 
      sortValue: newSortOrder,
      items: _.orderBy(this.state.items, field, order)
    });
  }

  _setView = (newView) => {
    this.setState({ activeView: newView });
  }

  _getTag = () => {
    this.dataStore.getTag(this.props.match.params.id).then((data) => {
      const { field, order } = this.dataStore.getDefaultItemSortOrder(this.state.sortValue);
      data.items = _.orderBy(data.items, field, order);
      this.setState(data);
    }).catch((err) => {
      alert('There was an error fetching the tag data.');
      throw err;
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      trackEvent('Shared Tag Viewed');
      this._getTag();
    }
  }

  componentDidMount = () => {
    trackEvent('Shared Tag Viewed');
    this._getTag();
  }

  render() {
    const { tag } = this.state;

    // If we are still loading
    if (_.isUndefined(tag)) {
      return <Loading />;
    }

    // If null, tag couldn't be found or loaded
    if (_.isNull(tag)) {
      return <NotFound />;
    }

    // If we have no results
    if (this.state.items.length === 0) {
      return <NoResults message="This tag has no saved items!" />;
    }

    return (
      <div className="Tag">
        <div className="Tag__header">
          <div className="Tag__spacer"></div>
          <ViewToggle activeView={this.state.activeView} onViewToggle={this._setView} />
          <ItemSortSelect value={this.state.sortValue} onChange={this._setSortOrder} />
        </div>
        {this.state.activeView === 'grid' && <ItemGrid items={this.state.items} clickableTokens={false} />}
        {this.state.activeView === 'list' && <ItemList items={this.state.items} />}
      </div>
    );
  }
}

class NavbarTagHeader extends AuthComponent {
  render() {
    if (this.state.authState < AUTH_STATE.TRIALING) {
      return <NavbarTagUnauthenticatedHeader {...this.props} />;
    }
    else {
      return <NavbarTagAuthenticatedHeader {...this.props} />;
    }
  }
}

class Tag extends AuthComponent {
  render() {
    if (this.state.authState >= AUTH_STATE.TRIALING) {
      return <TagAuthenticated {...this.props} />;
    }
    else {
      return <TagUnauthenticated {...this.props} />;
    }
  }
}

export {
  NavbarTagHeader,
  Tag
};