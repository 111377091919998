import React, { Component } from 'react';
import './Waitlist.scss';
import { withAuth, AUTH_STATE } from '../data/Auth';
import { Link, Redirect } from 'react-router-dom';
import SvgIconLogo from '../images/IconLogo';

class Waitlist extends Component {
  render = () => {
    if (this.props.authState < AUTH_STATE.UNAUTHENTICATED) {
      return <Redirect to="/auth/sign-in" />;
    }
    else if (this.props.authState >= AUTH_STATE.INACTIVE) {
      return <Redirect to="/" />;
    }

    return (
      <div className="Waitlist">
        <div className="Waitlist__logo">
          <SvgIconLogo />
        </div>
        <div>We aren't available to everyone yet.</div>
        <div>But, we've added you to the waitlist!</div>
        <Link className="btn secondary" to="/auth/sign-out">Sign out</Link>
      </div> 
    );
  }
}

export default withAuth(Waitlist);