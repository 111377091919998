import React, { Component } from 'react';
import './Install.scss';
import { NavbarTitleHeader } from '../components/Navbar';
import ReadyToTryDumpster from '../components/ReadyToTryDumpster';
import { DownloadAppButton } from '../components/GetStartedButtons';
import { withAuth, AUTH_STATE } from '../data/Auth';
import cx from 'classnames';
import env from '../data/env';
import { trackEvent } from '../data/Analytics';
import { detect } from 'detect-browser';
import BrowserExtensionNotSupportedModal from '../components/BrowserExtensionNotSupportedModal';

class NavbarInstallHeader extends Component {
  render() {
    return (
      <NavbarTitleHeader
        chevron
        to="/"
        title="Install"
      />
    );
  }
}

class _Install extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

  componentDidMount = () => {
    trackEvent('Install Viewed');
  }

  _openInstallPageForBrowser = () => {
    const browser = detect();

    if (browser && browser.os === 'iOS') {
      window.open(env.iOSAppDownloadURL);
    }
    else {
      switch (browser && browser.name) {
      case 'chrome':
      case 'edge':
        window.open(env.chromeExtensionInstallURL);
        break;
      case 'safari':
        window.open(env.macAppDownloadURL);
        break;
      case 'firefox':
        window.open(env.firefoxExtensionInstallURL);
        break;
      default:
        this.setState({
          modalOpen: true
        });
      }
    }
  }

  _handleModalClose = () => {
    this.setState({
      modalOpen: false
    });
  }

  render() {
    return (
      <div className={cx('Install', {'Install--onboarding': this.props.onboarding})}>
        <div className="Install__section">
          {this.props.onboarding ? (
            <>
              <h2>Welcome to Dumpster</h2>
              <p>
              We did it! Well, you did it. And now you can do more by starting to save any and
              all links you want. Use our browser extensions or the iOS app. It’s all you from
              here. Have fun!
              </p>
            </>
          ) : (
            <>
              <h2>How to save to Dumpster</h2>
              <p>
                Save the whole internet in your pocket. At least the stuff that matters to you.
                Use our browser extensions or the iOS app to save and share links whenever,
                wherever, and with whoever. 
              </p>
            </>
          )}
          <div className="Install__buttons">
            <div className="btn large secondary" onClick={this._openInstallPageForBrowser}>
              Install Browser Extension
            </div>
            <DownloadAppButton dark />
          </div>
          <div className="Install__image"><div /></div>
        </div>
        {this.props.authState <= AUTH_STATE.UNAUTHENTICATED && <ReadyToTryDumpster />}
        {this.state.modalOpen &&
          <BrowserExtensionNotSupportedModal 
            isOpen
            onRequestClose={this._handleModalClose}
          />
        }
      </div>
    );
  }
}
const Install = withAuth(_Install);

export { Install, NavbarInstallHeader };