import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import { DataStore } from '../data/DataStore';
import Loading from './Loading';
import { trackEvent } from '../data/Analytics';
import moment from 'moment';
import _ from 'lodash';

class StripeCancelSubscriptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this._dataStore = new DataStore();
  }

  _handleAfterOpen = () => {
    trackEvent('Stripe Cancel Plan Opened');
  }

  _handleCancelPlan = (token) => {
    this.setState({ loading: true });
    this._dataStore.cancelStripeSubscription({}).then(() => {
      trackEvent('Stripe Plan Canceled');
      this.props.onRequestClose();
    }).catch((err) => {
      this.setState({ loading: false });
      throw err;
    });
  }

  _handleDoNotCancel = (e) => {
    e.preventDefault();
    trackEvent('Stripe Cancel Plan Aborted');
    this.props.onRequestClose();
  }

  render() {
    const plan = this.props.plan;
    const currentPeriodEndMoment = _.isNull(plan.currentPeriodEndDate) ? moment() : moment(plan.currentPeriodEndDate.toDate());
    const datePeriodEnds = currentPeriodEndMoment.format('l');

    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={!this.state.loading}
        shouldCloseOnOverlayClick={!this.state.loading}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header Modal__header--warning">Cancel plan?</div>
        <div className="Modal__row">
          <div className="Modal__text">
            Are you sure you want to cancel your Dumpster Premium plan?<br/>
            Your plan will remain active until {datePeriodEnds}.
          </div>
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this._handleDoNotCancel}>Don't cancel plan</button>
          <button className="Modal__button btn warning" onClick={this._handleCancelPlan}>Cancel plan</button>
        </div>
        
        {this.state.loading && <Loading opaque />}
      </ReactModal>
    );
  }
}

export default StripeCancelSubscriptionModal;