import React, { Component } from 'react';
import Select from './Select';
import './TagSortSelect.scss';
import _ from 'lodash';

class TagSortSelect extends Component {
  constructor(props) {
    super(props);
    this.options = [
      { value: 'mostRecentItemSavedDate', label: 'Most recent' },
      { value: 'label', label: 'List title' },
      { value: 'itemCount', label: 'Number of items' }
    ];
  }

  _handleOnChange = (value) => {
    this.props.onChange(value.value);
  }

  render() {
    if (!this.props.value) {
      return null;
    }
    
    const option = _.find(this.options, { value: this.props.value });
    return (
      <Select
        value={option}
        options={this.options}
        onChange={this._handleOnChange}
        placeholder="Sort by"
        className="TagSortSelect"
      />
    );
  }
}

export default TagSortSelect;