import * as React from 'react';

function SvgIconListView(props) {
  return (
    <svg width={9} height={10} fill="none" {...props}>
      <path
        d="M1.145 2.29h6.616a1.14 1.14 0 001.145-1.145A1.14 1.14 0 007.76 0H1.145A1.14 1.14 0 000 1.145 1.14 1.14 0 001.145 2.29zM7.76 3.855H1.146A1.14 1.14 0 000 5a1.14 1.14 0 001.145 1.145h6.616A1.14 1.14 0 008.906 5 1.14 1.14 0 007.76 3.855zM7.76 7.71H1.146A1.14 1.14 0 000 8.855 1.14 1.14 0 001.145 10h6.616a1.14 1.14 0 001.145-1.145c0-.623-.51-1.145-1.145-1.145z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconListView;
