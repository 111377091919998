import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './NavTabs.scss';

function HomeNavItem(props) {
  return (
    <li className="NavTabs__item">
      <NavLink className ="NavTabs__link" activeClassName="NavTabs__link--active" {...props} />
    </li>
  );
}

class NavTabs extends Component {
  render() {
    return (
      <ul className="NavTabs">
        <HomeNavItem exact to="/">All</HomeNavItem>
        <HomeNavItem to="/lists">Lists</HomeNavItem>
        <HomeNavItem to="/items">Items</HomeNavItem>
      </ul>
    );
  }
}

export default NavTabs;