import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import { trackEvent } from '../data/Analytics';
import env from '../data/env';

class BrowserExtensionNotSupportedModal extends Component {
  _handleAfterOpen = () => {
    trackEvent('Browser Extension Not Supported Opened');
  }

  _handleClose = (e) => {
    this.props.onRequestClose();
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header">Browser not supported</div>
        <div className="Modal__row">
          <div className="Modal__text">
            Unfortunately we haven't built an extension for your browser just yet. Currently we only have extensions
            for <a href={env.macAppDownloadURL} target="_blank" rel="noopener noreferrer">Safari</a>,&nbsp;
            <a href={env.chromeExtensionInstallURL}  target="_blank" rel="noopener noreferrer">Chrome</a>,&nbsp;
            <a href={env.firefoxExtensionInstallURL}  target="_blank" rel="noopener noreferrer">Firefox</a>
            &nbsp;and <a href={env.iOSAppDownloadURL} target="_blank" rel="noopener noreferrer">iOS</a>.
          </div>
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn" onClick={this._handleClose}>Close</button>
        </div>
      </ReactModal>
    );
  }
}

export default BrowserExtensionNotSupportedModal;