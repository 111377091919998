import * as React from 'react';

function SvgIconShare(props) {
  return (
    <svg width={19} height={19} fill="none" {...props}>
      <path
        d="M16.967 11.202v3.294c0 .906-.742 1.647-1.647 1.647H3.79a1.652 1.652 0 01-1.647-1.647v-3.294M13.672 6.26L9.555 2.144 5.437 6.261M9.555 2.143v9.882"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIconShare;
