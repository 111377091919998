import React, { Component } from 'react';
import env from './data/env';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { 
  withAuth, 
  AUTH_STATE, 
  SignIn, 
  SignOut,
  PremiumRedirect
} from './data/Auth';
import ScrollToTop from './components/ScrollToTop';
import { TrackPageview } from './data/Analytics';
import Loading from './components/Loading';
import BrowserExtensionAuth from './containers/BrowserExtensionAuth';
import BrowserExtensionPopup from './containers/BrowserExtensionPopup';
import BrowserExtensionBackground from './containers/BrowserExtensionBackground';
import App from './containers/App';
import LandingPage from './containers/LandingPage';
import { Settings, NavbarSettingsHeader } from './containers/Settings';
import Home from './containers/Home';
import Tags from './containers/Tags';
import Items from './containers/Items';
import { Tag, NavbarTagHeader } from './containers/Tag';
import SearchResults, { NavbarSearchResultsHeader } from './containers/SearchResults';
import NotFound from './containers/NotFound';
import { Premium, NavbarPremiumHeader } from './containers/Premium';
import Waitlist from './containers/Waitlist';
import _ from 'lodash';
import Brand from './containers/Brand';
import { Install, NavbarInstallHeader } from './containers/Install';
import { Blog, Terms, PrivacyPolicy, CookiePolicy } from './components/Links';
import { Help, NavbarHelpHeader } from './containers/Help';
import BrowserExtensionToken from './containers/BrowserExtensionToken';
import BrowserExtensionReset from './containers/BrowserExtensionReset';
import ArchivedPaged from './containers/ArchivedPage';

const publicBrandRoutes = [
  { path: '/',            content: LandingPage    },
  { path: '/pricing',     content: Premium        },
  { path: '/install',     content: Install        },
  { path: '/help',        content: Help           },
  { path: '*',            content: NotFound       }
];
const publicBrandPaths = publicBrandRoutes.map(r => r.path);

const publicAppRoutes = [
  { path: '/list/:id',    content: Tag,           header: NavbarTagHeader },
];
const publicAppPaths = publicAppRoutes.map(r => r.path);

const privateAppRoutes = [
  { path: '/',            content: Home           },
  { path: '/install',     content: Install,       header: NavbarInstallHeader},
  { path: '/settings',    content: Settings,      header: NavbarSettingsHeader },
  { path: '/settings/plans', content: Premium,    header: NavbarPremiumHeader },
  { path: '/help',        content: Help,          header: NavbarHelpHeader },
  { path: '/lists',       content: Tags           },
  { path: '/items',       content: Items          },
  { path: '/list/:id',    content: Tag,           header: NavbarTagHeader },
  { path: '/search',      content: SearchResults, header: NavbarSearchResultsHeader,       },
  { path: '*',            content: NotFound       }
];
const privateAppPaths = privateAppRoutes.map(r => r.path);

const publicPaths = _.union(publicBrandPaths, publicAppPaths);
const privateOnlyPaths = _.pullAll(privateAppPaths, publicPaths);

class AppRouter extends Component {
  render() {
    const { authState } = this.props;

    if (authState < AUTH_STATE.UNAUTHENTICATED) {
      return (<Loading/>);
    }

    return (
      <Router>
        <ScrollToTop />
        <TrackPageview />
        <Switch>
          <Route exact path="/auth/sign-in" component={SignIn} />
          <Route exact path="/auth/sign-out" component={SignOut} />
          <Route exact path="/extension/popup" component={BrowserExtensionPopup} />
          <Route exact path="/extension/background" component={BrowserExtensionBackground} />
          <Route exact path="/extension/auth" component={BrowserExtensionAuth} />
          <Route exact path="/extension/token" component={BrowserExtensionToken} />
          <Route exact path="/extension/reset" component={BrowserExtensionReset} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/cookies" component={CookiePolicy} />
          <Route exact path="/help/app" component={Help} />
          <Route path="/archive/:id" component={ArchivedPaged} />

          {(authState === AUTH_STATE.UNAUTHENTICATED && env.protectPublicPages) && [
            <Route exact path={publicAppPaths} render={props => <App routes={publicAppRoutes} />} key={0} />,
            <PremiumRedirect all key={1} />
          ]}
          {(authState === AUTH_STATE.UNAUTHENTICATED && !env.protectPublicPages) && [
            <PremiumRedirect path={privateOnlyPaths} key={0} />,
            <Route exact path={publicAppPaths} render={props => <App routes={publicAppRoutes} />} key={1} />,
            <Route exact path={publicBrandPaths} render={props => <Brand routes={publicBrandRoutes} />} key={2} />
          ]}
          {authState === AUTH_STATE.INACTIVE && [
            <PremiumRedirect path={privateOnlyPaths} key={0} />,
            <Redirect exact from="/" to="/pricing" key={1} />,
            <Route exact path={publicAppPaths} render={props => <App routes={publicAppRoutes} />} key={2} />,
            <Route exact path={publicBrandPaths} render={props => <Brand routes={publicBrandRoutes} />} key={3} />
          ]}
          {authState === AUTH_STATE.WAITLISTED &&
              <Route path="*" component={Waitlist} />
          }
          {authState >= AUTH_STATE.TRIALING && [
            <Redirect from="/pricing" to="/settings/plans" key={0} />,
            <Route path="/" render={props => <App routes={privateAppRoutes} />} key={1} />
          ]}
        </Switch>
      </Router>
    );
  }
}

export default withAuth(AppRouter);