import * as React from 'react';

function SvgIconCancel(props) {
  return (
    <svg width={11} height={11} fill="none" {...props}>
      <rect
        y={9.238}
        width={13.064}
        height={1.804}
        rx={0.902}
        transform="rotate(-45 0 9.238)"
        fill="currentColor"
      />
      <rect
        x={9.239}
        y={10.513}
        width={13.064}
        height={1.804}
        rx={0.902}
        transform="rotate(-135 9.239 10.513)"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconCancel;
