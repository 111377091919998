import React, { Component } from 'react';
import './SearchResults.scss';
import qs from 'qs';
import _ from 'lodash';
import { DataStore, DataStoreQuery } from '../data/DataStore';
import { NavbarTitleHeader } from '../components/Navbar';
import Loading from '../components/Loading';
import NoResults from '../components/NoResults';
import ItemGrid from '../components/ItemGrid';
import ItemList from '../components/ItemList';
import ViewToggle from '../components/ViewToggle';

let dataStoreQuerySingleton = null;
function getDataStoreQuery () {
  if (dataStoreQuerySingleton === null) {
    dataStoreQuerySingleton = new DataStoreQuery();
  }
  return dataStoreQuerySingleton;
}

class NavbarSearchResultsHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemCount: null
    };

    this.dataStoreQuery = getDataStoreQuery();
  }

  componentDidMount = () => {
    this.dataStoreQuery.listen(this._syncWithDataStoreQuery);
  }

  componentWillUnmount = () => {
    this.dataStoreQuery.stopListening(this._syncWithDataStoreQuery);
  }

  _syncWithDataStoreQuery = () => {
    this.setState({
      itemCount: this.dataStoreQuery.items.length
    });
  }

  render() {
    const itemCount = this.state.itemCount;
    return (
      <NavbarTitleHeader
        chevron
        title="Search results"
        subtitle={itemCount !== null ? `${itemCount} ${itemCount === 1 ? 'item': 'items'}` : ''}
      />
    );
  }
}

class SearchResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
      activeView: null
    };

    this.dataStore = new DataStore();
    this.dataStoreQuery = getDataStoreQuery();
  }

  componentDidMount = () => {
    this.dataStoreQuery.listen(this._syncWithDataStoreQuery);
    this._updateDataStoreFromQuery();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this._updateDataStoreFromQuery();
    }
  }

  componentWillUnmount = () => {
    this.dataStoreQuery.stopListening(this._syncWithDataStoreQuery);
  }

  _syncWithDataStoreQuery = () => {
    this.setState({
      items: this.dataStoreQuery.items,
      activeView: this.dataStore.getDefaultItemView()
    });
  }

  _updateDataStoreFromQuery = () => {
    const params = qs.parse(
      this.props.location.search,
      { ignoreQueryPrefix: true }
    );

    this.dataStoreQuery.queryItems({
      tagFilter: params['tag'] || null,
      typeFilter: params['type'] || null,
      searchTerm: params['search'] || null
    });
  }

  render() {
    // If we are still loading
    if (_.isNil(this.state.items)) {
      return <Loading />;
    }

    // If we have no results
    if (this.state.items.length === 0) {
      return <NoResults message="Sorry, there are no matching items." />;
    }

    return (
      <div className="SearchResults">
        <div className="SearchResults__header">
          <div className="SearchResults__spacer"></div>
          <ViewToggle activeView={this.state.activeView} onViewToggle={this.dataStore.setDefaultItemView} />
        </div>
        {this.state.activeView === 'grid' && <ItemGrid editable items={this.state.items} />}
        {this.state.activeView === 'list' && <ItemList editable items={this.state.items} />}
      </div>
    );
  }
}

export { NavbarSearchResultsHeader };
export default SearchResults;