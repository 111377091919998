import React, { Component } from 'react';
import './BrowserExtension.scss';
import { Redirect, Link } from 'react-router-dom';
import { withAuth, AUTH_STATE, signInWithToken } from '../data/Auth';
import Loading from '../components/Loading';
import { DataStore } from '../data/DataStore';
import _ from 'lodash';
import { updateDarkModeStatus } from '../data/darkmode';

class BrowserExtensionToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      done: false
    };
  }
  
  _sendMessageToExtension = (name, data) => {
    document.dispatchEvent(new CustomEvent('dumpster-message-from-app', { detail: { name, data } }));
  }

  componentDidMount = () => {
    updateDarkModeStatus(true);
    
    const { hash } = this.props.location;
    if (_.isEmpty(hash)) {
      if (this.props.authState <= AUTH_STATE.UNAUTHENTICATED) {
        this.setState({ redirect: true });
      }
      else {
        const dataStore = new DataStore();
        dataStore.getAuthToken().then(({ data }) => {
          const { token } = data;
          this._sendMessageToExtension('token', { token: token });
          this.setState({ done: true });
        });
      }
    }
    else {
      const token = hash.slice(1);
      signInWithToken(token, () => {
        window.location.hash = '';
        window.location.pathname = '/extension/popup';
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: '/auth/sign-in', state: { from: this.props.location } }} />;
    }

    if (!this.state.done) {
      return <Loading />;
    }

    return (
      <div className="BrowserExtension__message">
        <div className="BrowserExtension__message__header">
          You are signed into Dumpster!
        </div>
        <div className="BrowserExtension__message__body">
          You can start using the Dumpster Extension now.
        </div>
        <Link to="/" className="btn medium BrowserExtension__btn">View my Dumpster</Link>
      </div>
    );
  }
}

export default withAuth(BrowserExtensionToken);