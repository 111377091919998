import * as React from 'react';

function SvgGoogle(props) {
  return (
    <svg width={19} height={19} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.62 9.716c0-.674-.06-1.322-.173-1.943H9.5v3.674h5.113a4.37 4.37 0 01-1.896 2.868v2.383h3.07c1.797-1.654 2.833-4.089 2.833-6.982z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 19c2.564 0 4.715-.85 6.287-2.302l-3.07-2.383c-.851.57-1.94.907-3.218.907-2.474 0-4.568-1.672-5.315-3.917H1.01v2.461A9.496 9.496 0 009.5 19z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.184 11.305A5.71 5.71 0 013.886 9.5c0-.626.108-1.235.298-1.805V5.234H1.01A9.496 9.496 0 000 9.5c0 1.533.367 2.984 1.01 4.266l3.174-2.46z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 3.778c1.394 0 2.646.48 3.63 1.421l2.726-2.725C14.21.941 12.06 0 9.499 0a9.496 9.496 0 00-8.49 5.234l3.175 2.461C4.93 5.45 7.025 3.778 9.499 3.778z"
        fill="#EA4335"
      />
    </svg>
  );
}

export default SvgGoogle;
