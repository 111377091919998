import React, { Component } from 'react';
import ReactMasonryLayout from 'react-masonry-layout';
import './MasonryLayout.scss';

class MasonryLayout extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const instance = this.ref.getBricksInstance();
    instance.pack();
  }

  render() {
    if (this.props.objects === null) {
      return null;
    }

    return (
      <div className="MasonryLayout">
        <ReactMasonryLayout
          id="masonry-layout"
          ref={ref => this.ref = ref}
          infiniteScrollEnd={this.props.infiniteScrollEnd}
          infiniteScroll={this.props.infiniteScroll}
          infiniteScrollEndIndicator={null}
          infiniteScrollDistance={600}
          sizes={[
            {columns: 1, gutter: 16},
            {mq: '768px', columns: 1, gutter: 28},
            {mq: '834px', columns: 2, gutter: 28},
            {mq: '1194px', columns: 3, gutter: 28},
            {mq: '1564px', columns: 4, gutter: 28}
          ]}>
          {this.props.objects}
        </ReactMasonryLayout>
      </div>
    );
  }
}

export default MasonryLayout;