import React, { Component } from 'react';
import './ReadyToTryDumpster.scss';
import { Link } from 'react-router-dom';
import { GetStartedButton } from './GetStartedButtons';
import cx from 'classnames';

class ReadyToTryDumpster extends Component {
  render() {
    const classes = cx('ReadyToTryDumpster', { 'ReadyToTryDumpster--no-border' : this.props.noBorder });

    return (
      <div className={classes}>
        <h2>Ready to try out Dumpster?</h2>
        <p>
          Dumpster is free to try out for 14 days. If you like what you see, unlock Dumpster
          for a small price so we can keep the lights on and continue building new features
          for us and for you. Learn more about Dumpster pricing <Link to="/pricing">here</Link>.
        </p>
        <GetStartedButton />
      </div>
    );
  }
}

export default ReadyToTryDumpster;