import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import copy from 'copy-to-clipboard';
import { trackEvent } from '../data/Analytics';
import { isMobile } from 'react-device-detect';
import { toast } from '../components/Toast';

class TagShareModal extends Component {
  constructor(props) {
    super(props);
    this.urlInputRef = React.createRef();
  }

  _url = () => {
    return `${window.location.origin}/list/${this.props.tag.id}`;
  }

  _handleAfterOpen = () => {
    trackEvent('Share Tag Opened');
    if (!isMobile) {
      setTimeout(() => { this.urlInputRef.current.select(); }, 0);
    }
  }

  _handleClose = () => {
    this.props.onRequestClose();
  }

  _handleCopyAndClose = () => {
    trackEvent('Share Tag Link Copied', { method: 'dialog' });
    copy(this._url());
    toast('Link copied');
    this.props.onRequestClose();
  }

  _handleInputClick = (e) => {
    e.preventDefault();
    if (!isMobile) {
      this.urlInputRef.current.select();
    }
  }

  render() {
    return (
      <ReactModal 
        isOpen={true}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onAfterOpen={this._handleAfterOpen}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header">Share list</div>
        <div className="Modal__row" onClick={this._handleInputClick}>
          <input
            readOnly
            className="Modal__input"
            ref={this.urlInputRef}
            value={this._url()}
            type="text" />
        </div>
        <div className="Modal__subtext">Anyone with the link can view the list</div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this._handleClose}>Close</button>
          <button className="Modal__button btn" onClick={this._handleCopyAndClose}>Copy link</button>
        </div>
      </ReactModal>
    );
  }
}

export default TagShareModal;
