// Some helper functions for dark mode

const _setDarkModeEnabled = (enabled) => {
  if (enabled) {
    document.getElementsByTagName('html')[0].className = 'dark-mode-enabled';
  }
  else {
    document.getElementsByTagName('html')[0].className = '';
  }
};

export const updateDarkModeStatus = (newStatus) => {
  if (newStatus !== undefined) {
    _setDarkModeEnabled(newStatus);
    localStorage.setItem('dumpster_darkmode_preference', newStatus);
  }
  else {
    // No new dark mode preference, so we load from local storage
    const darkModePreference = localStorage.getItem('dumpster_darkmode_preference') === 'true' || false;
    _setDarkModeEnabled(darkModePreference);
  }
};
