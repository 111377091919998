import * as React from 'react';

function SvgIconGridView(props) {
  return (
    <svg width={10} height={10} fill="none" {...props}>
      <path
        d="M1.033 6.033a1.033 1.033 0 100-2.065 1.033 1.033 0 000 2.065zM5.005 6.019a1.033 1.033 0 10-.33-2.04 1.033 1.033 0 00.33 2.04zM8.646 6.033a1.033 1.033 0 100-2.065 1.033 1.033 0 000 2.065zM1.033 9.848a1.033 1.033 0 100-2.065 1.033 1.033 0 000 2.065zM5.005 9.834a1.033 1.033 0 10-.33-2.038 1.033 1.033 0 00.33 2.038zM8.646 9.848a1.033 1.033 0 100-2.065 1.033 1.033 0 000 2.065zM1.033 2.217a1.033 1.033 0 100-2.065 1.033 1.033 0 000 2.065zM5.004 2.204a1.033 1.033 0 10-.33-2.039 1.033 1.033 0 00.33 2.039zM8.646 2.217a1.033 1.033 0 100-2.065 1.033 1.033 0 000 2.065z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconGridView;
