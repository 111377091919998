import * as React from 'react';

function SvgIconDropdownIndicator(props) {
  return (
    <svg width={8} height={6} fill="none" {...props}>
      <path
        d="M4.42 5.354a.5.5 0 01-.839 0L1.04 1.44a.5.5 0 01.419-.773h5.085a.5.5 0 01.42.773L4.42 5.354z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconDropdownIndicator;
