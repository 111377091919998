import * as React from 'react';

function SvgIconBack(props) {
  return (
    <svg width={13} height={21} fill="none" {...props}>
      <rect
        x={2.121}
        y={8}
        width={14}
        height={3}
        rx={1.5}
        transform="rotate(45 2.121 8)"
        fill="currentColor"
      />
      <rect
        y={9.899}
        width={14}
        height={3}
        rx={1.5}
        transform="rotate(-45 0 9.9)"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconBack;
