import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import { DataStore } from '../data/DataStore';
import { trackEvent } from '../data/Analytics';

class ItemDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.dataStore = new DataStore();
  }

  _handleAfterOpen = () => {
    trackEvent('Delete Item Opened');
  }

  _handleDelete = (e) => {
    if (e) e.preventDefault();
    this.dataStore.deleteItem(this.props.item.id);
    this.props.onRequestClose();
  }

  _handleCancel = (e) => {
    e.preventDefault();
    this.props.onRequestClose();
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header Modal__header--warning">Delete item?</div>
        <div className="Modal__row">
          <div className="Modal__text">
            Are you sure you want to delete <em>{this.props.item.displayTitle}</em> from your Dumpster?
          </div>
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this._handleCancel}>Cancel</button>
          <button className="Modal__button btn warning" onClick={this._handleDelete}>Delete</button>
        </div>
      </ReactModal>
    );
  }
}

export default ItemDeleteModal;