import * as React from 'react';

function SvgIconCancelLarge(props) {
  return (
    <svg width={20} height={20} fill="none" {...props}>
      <rect
        y={17.573}
        width={24.853}
        height={3.431}
        rx={1.716}
        transform="rotate(-45 0 17.573)"
        fill="currentColor"
      />
      <rect
        x={17.574}
        y={20}
        width={24.853}
        height={3.431}
        rx={1.716}
        transform="rotate(-135 17.574 20)"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconCancelLarge;
