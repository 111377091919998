import React, { Component } from 'react';
import './ItemGridCard.scss';
import TimeAgo from 'react-timeago';
import { PulseLoader } from 'react-spinners';
import LinesEllipsis from 'react-lines-ellipsis/lib/loose';
import { DataStore } from '../data/DataStore';
import { Tokens, SourceToken, TagToken, TypeToken } from './Tokens';
import _ from 'lodash';
import { trackEvent } from '../data/Analytics';
import { ItemControls } from './ItemControls';

class ItemGridCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      editing: false
    };
    this.dataStore = new DataStore();
    this.resizeTimeout = null;
  }

  _handleItemOpen = (e) => {
    trackEvent('Item Opened');
  }

  _handleDeleteOpen = (e) => {
    e.preventDefault();
    this.setState({ deleting: true });
  }

  _handleDeleteClose = () => {
    this.setState({ deleting: false });
  }

  _handleEditOpen = (e) => {
    e.preventDefault();
    this.setState({ editing: true });
  }

  _handleEditClose = () => {
    this.setState({ editing: false });
  }

  render() {
    const item = this.props.item;
    const ct = _.isBoolean(this.props.clickableTokens) ? this.props.clickableTokens : true;

    const tagTokens = item.tags.map((t) => {
      return (<TagToken hover link={ct} key={t.id} tag={t} />);
    });

    const sourceToken = (<SourceToken hover label={item.displaySource} />);

    const typeTokens = item.types.map((t) => {
      return (<TypeToken hover link={ct} key={t.id} type={t} />);
    });

    return (
      <div className="ItemGridCard">
        <a href={item.url} className="ItemGridCard__link" target="_blank" rel="noopener noreferrer" onClick={this._handleItemOpen}> </a>
        <LinesEllipsis
          text={item.displayTitle}
          className="ItemGridCard__title"
          maxLine={2}
          ellipsis="..."
        />
        { item.description &&
          <LinesEllipsis
            text={item.description}
            className="ItemGridCard__description"
            maxLine={3}
            ellipsis="..."
          />
        }
        {!_.isEmpty(item.imageUrls) &&
          <div className="ItemGridCard__image-container">
            <div className="ItemGridCard__image">
              <img src={item.imageUrls[0]} alt="" />
            </div>
          </div>
        }
        <Tokens className="ItemGridCard__Tokens">
          {tagTokens}
          {sourceToken}
          {typeTokens}
        </Tokens>
        { item.note &&
          <div className="ItemGridCard__note-container">
            <LinesEllipsis
              text={item.note}
              className="ItemGridCard__note"
              maxLine={2}
              ellipsis="..."
            />
          </div>
        }
        <div className="ItemGridCard__timestamp">
          {item.loading ? (
            <PulseLoader
              size={6}
              color="var(--text-light-light-gray)"
              css="margin-top: 2px;"/>
          ) : (
            <>Saved <TimeAgo date={item.savedDate.toDate()} /></>
          )}
        </div>
        <ItemControls 
          className="ItemGridCard__ItemControls" 
          item={item}
          editable={this.props.editable} />
      </div>
    );
  }
}

export default ItemGridCard;
