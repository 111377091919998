import React, { Component } from 'react';
import './LandingPage.scss';
import { Link } from 'react-router-dom';
import { GetStartedButton } from '../components/GetStartedButtons';
import ReadyToTryDumpster from '../components/ReadyToTryDumpster';
import { trackEvent } from '../data/Analytics';

class LandingPage extends Component {
  componentDidMount = () => {
    trackEvent('Landing Page Viewed');
  }

  render() {
    return (
      <div className="LandingPage">
        <div className="LandingPage__hero">
          <div className="LandingPage__hero__column">
            <div className="LandingPage__hero__text">
              <h1>Easy to save.<br />Easier to find.</h1>
              <p>
                Save the good. Save the meh. Save the whole internet in your pocket.
                Introducing a lightweight way to save and share links.
              </p>
              <GetStartedButton />
            </div>
            <div className="LandingPage__hero__image">
              <div><div /></div>
            </div>
          </div>
        </div>
        <div className="LandingPage__section LandingPage__section--alternate">
          <div className="LandingPage__section__column">
            <div className="LandingPage__section__text">
              <h2>Save all the things</h2>
              <p>
                Don’t fret, just save. We’ve created the easiest way for you to keep
                anything and everything for later. 
              </p>
              <p>
                To make it even easier on ya, Dumpster is available for iOS and as
                Chrome and Safari extensions. Learn more about our apps <Link to="/install">here</Link>.
              </p>
            </div>
            <div className="LandingPage__section__image LandingPage__section__image--extensions">
              <div><div /></div>
            </div>
          </div>
        </div>
        <div className="LandingPage__section">
          <div className="LandingPage__section__column">
            <div className="LandingPage__section__text">
              <h2>Lists for you and your friends</h2>
              <p>
                Recipes, memes, apartment listings, you name it. Create lists for
                anything you can think of and share ‘em with just one click.
              </p>
            </div>
            <div className="LandingPage__section__image LandingPage__section__image--lists">
              <div><div /></div>
            </div>
          </div>
        </div>
        <div className="LandingPage__section LandingPage__section--alternate">
          <div className="LandingPage__section__column">
            <div className="LandingPage__section__text">
              <h2>Archive the Internet</h2>
              <p>
                Like a shark, the Internet never sleeps. Unlike a shark, Dumpster was
                made for mammals who forget things sometimes. Dumpster saves a copy of
                every link in case it disappears. Or gets eaten by a shark.
                But that seems unlikely. 
              </p>
            </div>
            <div className="LandingPage__section__image LandingPage__section__image--archive">
              <div><div /></div>
            </div>
          </div>
        </div>
        <div className="LandingPage__section">
          <div className="LandingPage__section__column">
            <div className="LandingPage__section__text">
              <h2>Turn out the lights</h2>
              <p>
                Dark mode is pretty nice on the eyes. So we said, “hey, how about we
                add Dark Mode to all of our apps.” And to that we said, “great idea
                let’s do it.”
              </p>
            </div>
            <div className="LandingPage__section__image LandingPage__section__image--dark-mode">
              <div><div /></div>
            </div>
          </div>
        </div>
        <div className="LandingPage__section LandingPage__section--alternate">
          <div className="LandingPage__section__column">
            <div className="LandingPage__section__text">
              <h2>Find your findings</h2>
              <p>
                We all forget things. But your Dumpster library won’t. We have one
                of those magnifying glasses. Or as they more formally say, contextual
                search.
              </p>
            </div>
            <div className="LandingPage__section__image LandingPage__section__image--search">
              <div><div /></div>
            </div>
          </div>
        </div>
        <ReadyToTryDumpster noBorder />
      </div>
    );
  }
}

export default LandingPage;