import React, { Component } from 'react';
import './AddItemDropdown.scss';
import * as Sentry from '@sentry/browser';
import Dropdown, { DropdownTrigger, DropdownContent } from './Dropdown';
import { DataStore } from '../data/DataStore';
import urlRegex from 'url-regex';
import TagSelect from './TagSelect';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { trackEvent } from '../data/Analytics';
import SvgIconAdd from '../images/IconAdd';
import cx from 'classnames';
import { toast } from '../components/Toast';

class SaveItemDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlValue: '',
      isURL: false,
      noteValue: '',
      tags: [],
      creatingItem: false,
      active: false
    };

    this.dataStore = new DataStore();
    this.wasPasted = false;
    this.urlInput = React.createRef();
    this.noteInput = React.createRef();
    this.tagSelect = React.createRef();
    this.dropdown = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener('paste', this._handlePaste);
    window.addEventListener('keydown', this._handleKeyDown);
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(this.props.location, prevProps.location)) {
      this._reset();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('paste', this._handlePaste);
    window.removeEventListener('keydown', this._handleKeyDown);
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  _syncWithDataStore = () => {
    this.setState({
      tags: this._getPrepopulatedTag()
    });
  }

  _getPrepopulatedTag = () => {
    if (_.startsWith(this.props.location.pathname, '/list/')) {
      const tagId = this.props.location.pathname.slice(5);
      const tag = this.dataStore.tagsById[tagId];
      if (_.isUndefined(tag)) {
        return [];
      }
      else {
        return [tag];
      }
    }
    else {
      return [];
    }
  }

  _reset = () => {
    this.setState({
      urlValue: '',
      isURL: false,
      noteValue: '',
      tags: this._getPrepopulatedTag()
    });
    this.tagSelect.current.resetInput();
    this.tagSelect.current.stopListeningToDocumentKeyDown();
  }

  _focusUrlInput = () => {
    setTimeout(() => { this.urlInput.current.focus(); }, 0);
  }

  _handleKeyDown = (e) => {
    // Hide dropdown if visible and esc key is pressed
    if (this.dropdown.current.isActive() && e.keyCode === 27) {
      this.dropdown.current.hide();
    }
  }

  _handlePaste = (e) => {
    const clipboardData = e.clipboardData.getData('text');
    if (urlRegex({exact: true}).test(clipboardData)) {
      this.setState({
        urlValue: clipboardData,
        isURL: true
      }, () => {
        this.dropdown.current.show();
      });
      e.preventDefault();
    }
  }

  _handleShow = () => {
    trackEvent('Create Item Opened');

    if (this.state.isURL) {
      // We have a URL already set for the dropdown, so we focus tags
      setTimeout(() => { this.noteInput.current.focus(); }, 10);
    }
    this._focusUrlInput();
    
    this.setState({ active: true });
  }

  _handleHide = () => {
    // We include a timeout so we reset after the hide animation
    setTimeout(() => {
      this._reset();
    }, 350);

    this.setState({ active: false });
  }

  _handleUrlChange = (e) => {
    const isURL = urlRegex({exact: true}).test(e.target.value);
    this.setState({
      urlValue: e.target.value,
      isURL: isURL
    });
    if (this.wasPasted && isURL) {
      this.noteInput.current.focus();
    }
    this.wasPasted = false;
  }

  _handleUrlPaste = (e) => {
    this.wasPasted = true;
  }

  _handleEnterKeyDown = (e) => {
    if (e.keyCode === 13) {
      this._handleSubmit();
    }
  }

  _handleNoteChange = (e) => {
    this.setState({ noteValue: e.target.value });
  }

  _handleSubmit = (e) => {
    if (e) e.preventDefault();
    if (!this.state.isURL) return;

    const toastId = toast('Saving item...', { autoClose: false, closeOnClick: false });

    this.dataStore.createItem({
      url: this.state.urlValue,
      note: this.state.noteValue,
      tags: this.state.tags.map(tag => { return { label: tag.label }; })
    }, false).then(_ => {
      toast.update(toastId, {
        render: 'Item saved!',
        autoClose: 3000,
        closeOnClick: true
      });
    }, err => {
      toast.dismiss(toastId);
      Sentry.captureException(err);
      alert(`There was an error saving ${this.state.urlValue}. Check your internet connection and try again.`);
    });

    this.dropdown.current.hide();
  }

  _handleTagsChange = (updatedTags) => {
    // Note: preventDropdownClose is a hack that allows us to make TagSelect
    // not close the dropdown when selecting something from its menu.
    this.dropdown.current.preventDropdownClose = true;
    setTimeout(() => {
      this.dropdown.current.preventDropdownClose = false;
    }, 0);

    // Update tags properly
    this.setState({
      tags: updatedTags
    });
  }

  render() {
    const triggerClassName = cx('AddItemDropdown__trigger', { 
      'AddItemDropdown__trigger--active': this.state.active 
    });

    return (
      <Dropdown className="AddItemDropdown" 
        ref={this.dropdown}
        onShow={this._handleShow}
        onHide={this._handleHide}>

        <DropdownTrigger className={triggerClassName}>
          <SvgIconAdd />
        </DropdownTrigger>
        <DropdownContent className="AddItemDropdown__content">
          <div className="Dropdown__header">Save a new item</div>
          <div className="Dropdown__row">
            <input className="Dropdown__input"
              ref={this.urlInput}
              value={this.state.urlValue}
              onChange={this._handleUrlChange}
              onPaste={this._handleUrlPaste}
              onKeyDown={this._handleEnterKeyDown}
              type="text"
              placeholder="http://..."/>
          </div>
          <div className="Modal__header">Note</div>
          <div className="Modal__row">
            <input
              ref={this.noteInput}
              className="Modal__input"
              value={this.state.noteValue}
              onChange={this._handleNoteChange}
              onKeyDown={this._handleEnterKeyDown}
              type="text"
              placeholder="Add a note"/>
          </div>
          <div className="Dropdown__header">Lists</div>
          <div className="Dropdown__row">
            <TagSelect 
              ref={this.tagSelect}
              placeholder="Search your lists"
              tags={this.state.tags}
              onSubmit={this._handleSubmit}
              onTagsChange={this._handleTagsChange} />
          </div>
          <div className="Dropdown__row Dropdown__buttons">
            <div className="Dropdown__spacer"></div>
            <button 
              className="btn" 
              disabled={!this.state.isURL}
              onClick={this._handleSubmit}>
                  Save
            </button>
          </div>
        </DropdownContent>
      </Dropdown>
    );
  }
}

export default withRouter(SaveItemDropdown);
