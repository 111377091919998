import React, { Component } from 'react';
import './SignInOptions.scss';
import SvgGoogle from '../images/Google';
import SvgApple from '../images/Apple';
import Navbar, { NavbarBrandHeader } from '../components/Navbar';
import { Link } from 'react-router-dom';

class SignInOptions extends Component {
  _createClickHandler = (service) => {
    return (e) => {
      e.preventDefault();
      this.props.onSignInRequested(service);
    };
  }

  render() {
    return (
      <div className="SignInOptions">
        <Navbar brand>
          <NavbarBrandHeader large />
        </Navbar>
        <div className="SignInOptions__content">
          <h2>Sign in</h2>
          <div to="/auth/sign-in" className="btn secondary large icon" onClick={this._createClickHandler('google')}>
            <SvgGoogle />
            <div>Sign in with Google</div>
          </div>
          <div className="btn secondary large icon" onClick={this._createClickHandler('apple')}>
            <SvgApple className="apple" />
            <div>Sign in with Apple</div>
          </div>
          <p>
            By signing in to Dumpster, you agree to our <Link to="/terms">Terms of Service</Link>
            &nbsp;and <Link to="/privacy">Privacy Policy</Link>. 
          </p>
        </div>
      </div>
    );
  }
}

export default SignInOptions;