import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import TagSelect from './TagSelect';
import { DataStore } from '../data/DataStore';
import _ from 'lodash';
import { trackEvent } from '../data/Analytics';

class ItemEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.item.tags,
      title: this.props.item.displayTitle,
      note: this.props.item.note || '',
      titleChanged: false,
      noteChanged: false,
      tagsChanged: false,
      changed: false
    };
    this.titleInput = React.createRef();
    this.urlInput = React.createRef();
    this.dataStore = new DataStore();
  }

  componentDidMount = () => {
    setTimeout(() => { this.titleInput.current.select(); }, 0);
  }

  _handleAfterOpen = () => {
    trackEvent('Edit Item Opened');
  }

  _updateChanged = () =>{
    const titleChanged = !_.isEqual(this.state.title, this.props.item.displayTitle);
    const noteChanged = _.isUndefined(this.props.item.note) ? !_.isEmpty(this.state.note) : !_.isEqual(this.state.note, this.props.item.note);
    const tagsChanged = !_.isEqual(this.state.tags, this.props.item.tags);
    
    this.setState({
      titleChanged: titleChanged,
      noteChanged: noteChanged,
      tagsChanged: tagsChanged,
      changed: titleChanged || noteChanged || tagsChanged
    });
  }

  _handleTitleChange = (e) => {
    this.setState({ title: e.target.value }, () => {
      this._updateChanged();
    });
  }

  _handleNoteChange = (e) => {
    this.setState({ note: e.target.value }, () => {
      this._updateChanged();
    });
  }

  _handleEnterKeyUp = (e) => {
    if (e.keyCode === 13) {
      this._handleEdit();
    }
  }

  _handleUrlClick = (e) => {
    e.preventDefault();
    this.urlInput.current.select();
  }

  _handleTagsChange = (tags) => {
    this.setState({
      tags: tags
    }, () => {
      this._updateChanged();
    });
  }

  _handleEdit = (e) => {
    if (e) e.preventDefault();
    if (!this.state.changed) return;

    const data = { id: this.props.item.id };
    if (this.state.titleChanged) data.userTitle = this.state.title;
    if (this.state.noteChanged) data.note = this.state.note;
    if (this.state.tagsChanged) data.tags = this.state.tags;

    this.dataStore.editItem(data);
    this.props.onRequestClose();
  }

  _handleCancel = (e) => {
    e.preventDefault();
    this.props.onRequestClose();
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header">Edit saved item</div>
        <div className="Modal__row">
          <input
            className="Modal__input"
            ref={this.titleInput}
            value={this.state.title}
            onChange={this._handleTitleChange}
            onKeyUp={this._handleEnterKeyUp}
            type="text"
            placeholder="Title"/>
        </div>
        <div className="Modal__row" onClick={this._handleUrlClick}>
          <input
            className="Modal__input"
            disabled
            ref={this.urlInput}
            value={this.props.item.url}
            type="text"/>
        </div>
        <div className="Modal__header">Note</div>
        <div className="Modal__row">
          <input
            className="Modal__input"
            value={this.state.note}
            onChange={this._handleNoteChange}
            onKeyUp={this._handleEnterKeyUp}
            type="text"
            placeholder="Add a note"/>
        </div>
        <div className="Modal__header">Lists</div>
        <div className="Modal__row">
          <TagSelect 
            placeholder="Search your lists"
            closeMenuOnSelect={true}
            menuShouldScrollIntoView={false}
            tags={this.state.tags}
            onTagsChange={this._handleTagsChange} 
            onSubmit={this._handleEdit} />
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this._handleCancel}>Cancel</button>
          <button className="Modal__button btn" disabled={!this.state.changed} onClick={this._handleEdit}>Save</button>
        </div>
      </ReactModal>
    );
  }
}

export default ItemEditModal;