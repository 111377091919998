import * as React from 'react';

function SvgIconDelete(props) {
  return (
    <svg width={19} height={19} fill="none" {...props}>
      <path
        d="M14.667 5.81l-.665 9.669c-.073.811-.664 1.402-1.476 1.402H6.474c-.812 0-1.402-.59-1.476-1.402l-.665-9.67"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M8.024 8.762v5.166M10.977 8.762v5.166"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M2.857 5.81h13.287M6.548 5.81V3.594a.74.74 0 01.738-.738h4.428a.74.74 0 01.739.738V5.81"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgIconDelete;
