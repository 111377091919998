import * as React from 'react';

function SvgIconMenu(props) {
  return (
    <svg width={17} height={16} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={2}
        d="M0 1.625h17M0 8h17M0 14.375h17"
      />
    </svg>
  );
}

export default SvgIconMenu;
