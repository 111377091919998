import EventEmitter from 'events';
import firebase from '../data/firebase';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { trackEvent } from '../data/Analytics';

class DataStore extends EventEmitter {
  constructor() {
    if (DataStore.singleton) {
      return DataStore.singleton;
    }

    super();

    this.setMaxListeners(30);

    this.listeners = [];

    this.initializing = false;
    this.userInitialized = false;
    this.itemsInitialized = false;
    this.tagsInitialized = false;
    this.typesInitialized = false;
    this.initialized = false;
    
    this.user = null;
    this.editingUser = false;
    
    this.tags = null;
    this.tagsById = null;
    
    this.types = null;
    this.typesById = null;

    this.items = null;
    this.itemsById = null;

    this.fetchingTag = {};

    DataStore.singleton = this;

    return this;
  }

  static _specialTypeToDisplayNameMapping = {
    'accommodation': 'Accommodation',
    'music': 'Music',
    'tvshow': 'TV Shows',
    'null': 'Uncategorized'
  }

  static _typeToDisplayName = (type) => {
    const typeString = String(type);
    if (DataStore._specialTypeToDisplayNameMapping[typeString] !== undefined) {
      return DataStore._specialTypeToDisplayNameMapping[typeString];
    }
    else {
      return type.charAt(0).toUpperCase() + type.slice(1) + 's';
    }
  }

  static _handleCloudFunctionError = (err) => {
    if (err.message) {
      alert(err.message);
    }
    else {
      alert('Ooops. We hit an error. Please try that again.');
    }
  }

  initialize = () => {
    if (this.initialized || this.initializing) {
      return;
    }

    this.initializing = true;
    
    const onceInitialized = (dataTypeUpdated) => {
      if (this.userInitialized
        && this.itemsInitialized 
        && this.tagsInitialized 
        && this.typesInitialized) {

        if (!this.initialized) {
          this.initialized = true;
          this.initializing = false;
          this._update();
        }
        else {
          this._update(dataTypeUpdated);
        }
      }
    };

    if (firebase.auth().currentUser === null) {
      throw new Error('must be authenticated to initialize datastore');
    }

    this.listeners.push(firebase.db.collection('users').doc(firebase.auth().currentUser.uid)
      .onSnapshot((doc) => {
        // On account creation doc may not exists for second, so we need to wait for it
        if (!doc.exists) return;
        if (!this.editingUser) {
          this.user = doc.data();
        }
        this.user.id = doc.id;
        this.userInitialized = true;
        onceInitialized('user');
      }));

    this.listeners.push(firebase.db.collection('items')
      .where('savedBy', '==', firebase.auth().currentUser.uid)
      .orderBy('savedDate', 'desc')
      .onSnapshot((querySnapshot) => {
        var newItems = [];
        querySnapshot.forEach((doc) => {
          var item = doc.data();
          item.id = doc.id;
          item.displayTitle = item.userTitle || item.title || item.url;
          item.displaySource = item.siteName || item.siteDomain;
          newItems.push(item);
        });
        this.items = newItems;
        this.itemsInitialized = true;
        onceInitialized('items');
      }));

    this.listeners.push(firebase.db.collection('tags')
      .where('savedBy', '==', firebase.auth().currentUser.uid)
      .onSnapshot((querySnapshot) => {
        var newTags = [];
        querySnapshot.forEach((doc) => {
          const tag = doc.data();
          tag.id = doc.id;
          newTags.push(tag);
        });
        this.tags = newTags;
        this.tagsInitialized = true;
        onceInitialized('tags');
      }));
    
    this.listeners.push(firebase.db.collection('types')
      .where('savedBy', '==', firebase.auth().currentUser.uid)
      .onSnapshot((querySnapshot) => {
        var newTypes = [];
        querySnapshot.forEach((doc) => {
          const type = doc.data();
          // Ignore types with no items
          if (type.itemRefs.length === 0) return;
          type.id = doc.id;
          type.label = DataStore._typeToDisplayName(type.value);
          newTypes.push(type);
        });
        this.types = newTypes;
        this.typesInitialized = true;
        onceInitialized('types');
      }));
  }

  listen = (listener) => {
    this.on('updated', listener);
    if (this.initialized) {
      // We fire once to make sure data is properly updated at least once
      listener();
    }
    else {
      this.initialize();
    }
  }

  stopListening = (listener) => {
    this.off('updated', listener);
  }

  _update = (dataTypeUpdated) => {
    switch (dataTypeUpdated) {
    case 'user':
      // Don't have to do anything for just user updates
      break;
    case 'items':
      this._updateItemsById();
      
      this._hydrateTagsOnItems();
      this._hydrateTypesOnItems();
      this._hydrateItemsOnTags();
      this._hydrateItemsOnTypes();
      break;
    case 'tags':
      this._updateTagsById();

      this._hydrateTagsOnItems();
      this._hydrateItemsOnTags();
      break;
    case 'types':
      this._updateTypesById();

      this._hydrateTypesOnItems();
      this._hydrateItemsOnTypes();
      break;
    default:
      this._updateItemsById();
      this._updateTagsById();
      this._updateTypesById();
    
      this._hydrateTagsOnItems();
      this._hydrateTypesOnItems();
      this._hydrateItemsOnTags();
      this._hydrateItemsOnTypes();
    }
    
    this.emit('updated', dataTypeUpdated);
  }

  _updateItemsById = () => {
    const newItemsById = {};
    this.items.forEach(item => {
      newItemsById[item.id] = item;
    });
    this.itemsById = newItemsById;
  }

  _hydrateItemsOnTags = () => {
    this.tags.forEach(tag => {
      // We compact to protect against race condition where item has deleted before items
      // array has been updated on the tag
      tag.items = _
        .chain(tag.itemRefs)
        .map(itemRef => { return this.itemsById[itemRef.id]; })
        .orderBy('savedDate', 'desc')
        .compact()
        .value();

      tag.mostRecentItem = tag.items[0];
    });
  }

  _updateTagsById = () => {
    const newTagsById = {};
    this.tags.forEach(tag => {
      newTagsById[tag.id] = tag;
    });
    this.tagsById = newTagsById;
  }

  _hydrateItemsOnTypes = () => {
    this.types.forEach(type => {
      // We compact to protect against race condition where item has deleted before items
      // array has been updated on the tag
      type.items = _
        .chain(type.itemRefs)
        .map(itemRef => { return this.itemsById[itemRef.id]; })
        .orderBy('savedDate', 'desc')
        .compact()
        .value();
      
      type.mostRecentItem = _.maxBy(type.items, 'savedDate');
    });
  }

  _updateTypesById = () => {
    const newTypesById = {};
    this.types.forEach(type => {
      newTypesById[type.id] = type;
    });
    this.typesById = newTypesById;
  }

  _hydrateTagsOnItems = () => {
    this.items.forEach(item => {
      // We compact to protect against race condition where tag has deleted before tags
      // array has been updated on the item
      item.tags = _.compact(item.tagRefs.map(tagRef => {
        return this.tagsById[tagRef.id];
      }));
    });
  }

  _hydrateTypesOnItems = () => {
    this.items.forEach(item => {
      // We compact to protect against race condition where tag has deleted before tags
      // array has been updated on the item
      item.types = _.compact(item.typeRefs.map(typeRef => {
        return this.typesById[typeRef.id];
      }));
    });
  }

  setDefaultItemView = (newItemView) => {
    trackEvent('Item View Changed', { view: newItemView });
    this.editUser({
      settings: { defaultItemView: newItemView }
    });
  }

  getDefaultItemView = () => {
    return this.user.settings.defaultItemView || 'grid';
  }

  setDefaultTagView = (newTagView) => {
    trackEvent('Tag View Changed', { view: newTagView });
    this.editUser({
      settings: { defaultTagView: newTagView }
    });
  }

  getDefaultTagView = () => {
    return this.user.settings.defaultTagView || 'grid';
  }

  setDefaultItemSortOrder = (newSortOrder) => {
    trackEvent('Item Sort Order Changed', { sort_order: newSortOrder });
    this.editUser({
      settings: { defaultItemSortOrder: newSortOrder }
    });
  }

  getDefaultItemSortOrder = (override) => {
    const sortOrder = override || _.get(this, 'user.settings.defaultItemSortOrder');
    if (sortOrder === 'savedDate') {
      return { value: 'savedDate', field: 'savedDate', order: 'desc'};
    }
    else if (sortOrder === 'title') {
      return { value: 'title', field: 'displayTitle', order: 'asc'};
    }
    else if (sortOrder === 'siteDomain') {
      return { value: 'siteDomain', field: 'displaySource', order: 'asc'};
    }
    else {
      // Default sort by item recency
      return { value: 'savedDate', field: 'savedDate', order: 'desc'};
    }
  }

  setDefaultTagSortOrder = (newSortOrder) => {
    trackEvent('Tag Sort Order Changed', { sort_order: newSortOrder });
    this.editUser({
      settings: { defaultTagSortOrder: newSortOrder }
    });
  }

  getDefaultTagSortOrder = () => {
    const sortOrder = _.get(this, 'user.settings.defaultTagSortOrder');
    if (sortOrder === 'mostRecentItemSavedDate') {
      return { value: 'mostRecentItemSavedDate', field: 'mostRecentItem.savedDate', order: 'desc'};
    }
    else if (sortOrder === 'itemCount') {
      return { value: 'itemCount', field: 'items.length', order: 'desc'};
    }
    else if (sortOrder === 'label') {
      return { value: 'label', field: 'label', order: 'asc'};
    }
    else {
      // Default sort by item recency
      return { value: 'mostRecentItemSavedDate', field: 'mostRecentItem.savedDate', order: 'desc'};
    }
  }

  setEmailUpdatesPreference = (newPreference) => {
    trackEvent('Email Updates Preference Changed', { send_email_updates: newPreference });
    this.editUser({
      settings: { sendEmailUpdates: newPreference }
    });
  }

  editUser = (data) => {
    const editUser = firebase.functions().httpsCallable('editUser');

    // Save old user in case we need to roll back
    const oldUserData = _.cloneDeep(this.user);

    // Merge in new user data so we can be optimistic
    _.merge(this.user, data);
    this.emit('updated');

    this.editingUser = true;

    // Attempt to save and roll back if we fail
    const promise = editUser(data).then(({ data }) => {
      this.editingUser = false;
    }, (err) => {
      DataStore._handleCloudFunctionError(err);
      this.user = oldUserData;
      this.emit('updated');
      this.editingUser = false;
    });

    return promise;
  }

  getAuthToken = () => {
    const getAuthToken = firebase.functions().httpsCallable('getAuthToken');
    return getAuthToken({});
  }

  setPrivacyConsent = (value) => {
    const editUser = firebase.functions().httpsCallable('editUser');
    editUser({
      settings: { privacyConsent: value }
    });
  }

  dismissedExpiryWarning = () => {
    const editUser = firebase.functions().httpsCallable('editUser');
    editUser({
      settings: { dismissedExpiryWarningForPeriodEndMillis: this.user.plan.currentPeriodEndDate.toMillis() }
    });
  }

  createStripeSubscription = (params) => {
    const createStripeSubscription = firebase.functions().httpsCallable('createStripeSubscription');
    return createStripeSubscription(params);
  }

  cancelStripeSubscription = (params) => {
    const cancelStripeSubscription = firebase.functions().httpsCallable('cancelStripeSubscription');
    return cancelStripeSubscription();
  }

  deleteUser = () => {
    const deleteUser = firebase.functions().httpsCallable('deleteUser');

    return deleteUser().then(() => {
      // // We wait a second to make really sure the refresh shows a deleted account
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    }).catch(DataStore._handleCloudFunctionError);
  }

  createItem = (data, usingExtension) => {
    trackEvent('Item Created', { 
      extension: usingExtension,
      with_fields: _.keys(_.pickBy(data, v => !_.isEmpty(v)))
    });
    const createItem = firebase.functions().httpsCallable('createItem');
    return createItem(data);
  }

  editItem = (data) => {
    trackEvent('Item Edited', {
      edited_fields: _.without(_.keys(data), 'id')
    });

    // NOTE: This data only changes the userTitle optimistically
    // references on tag objects are updated via the server

    const editItem = firebase.functions().httpsCallable('editItem');
    const item = this.itemsById[data.id];
    const edits = { id: data.id };

    // Save data before edits, so we can reverse optimistic changes on failure
    const dataBeforeEdits = {};

    if (_.has(data, 'tags')) {
      edits.tags = data.tags.map((t) => { return { label: t.label }; });
    }
    
    if (_.has(data, 'userTitle')) {
      dataBeforeEdits.displayTitle = item.displayTitle;
      dataBeforeEdits.userTitle = item.userTitle;
      item.displayTitle = item.userTitle = data.userTitle;
      edits.userTitle = data.userTitle;
    }

    if (_.has(data, 'note')) {
      dataBeforeEdits.note = item.note;
      item.note = data.note;
      edits.note = data.note;
    }

    this._update('items');

    return editItem(edits).catch((err) => {
      // Edit did not succeed, so we walk back changes
      _.assign(item, dataBeforeEdits);
      this._update('items');
      DataStore._handleCloudFunctionError(err);
    });
  }

  deleteItem = (itemId) => {
    trackEvent('Item Deleted');
    const itemToDelete = this.itemsById[itemId];
    const deleteItem = firebase.functions().httpsCallable('deleteItem');
    _.remove(this.items, (i) => { return i.id === itemId; });
    this._update('items');
    return deleteItem({ id: itemId }).catch((err) => {
      this.items.push(itemToDelete);
      this._update('items');
      DataStore._handleCloudFunctionError(err);
    });
  }

  getTag = async (tagId) => {
    if (this.tagsById && this.tagsById[tagId]) {
      return { tag: this.tagsById[tagId] };
    }
    else if (this.fetchingTag[tagId]) {
      return await this.fetchingTag[tagId];
    }
    
    this.fetchingTag[tagId] = (async () => {
      const getTag = firebase.functions().httpsCallable('getTag');
      let data = null;
      try {
        ({ data } = await getTag({ id: tagId }));
      }
      catch (err) {
        // Not found or db error, so we return null
        return { tag: null, items: null, savedBy: null };
      }
      // eslint-disable-next-line no-unused-vars
      for (const item of data.items) {
        item.fetchedDate = firebase.firestore.Timestamp.fromMillis(item.fetchedDate);
        item.savedDate = firebase.firestore.Timestamp.fromMillis(item.savedDate);
        item.displayTitle = item.userTitle || item.title || item.url;
        item.displaySource = item.siteName || item.siteDomain;
        item.tags = [];
        item.types = item.typeRefs.map(typeId => { 
          const type = data.types[typeId];
          type.id = typeId;
          type.label = DataStore._typeToDisplayName(type.value);
          return type;
        });
      }
      data.tag.items = data.items;
      data.tag.shared = true;
      return {
        tag: data.tag,
        items: data.items,
        savedBy: data.savedBy
      };
    })();
    
    return await this.fetchingTag[tagId];
  }

  editTag = (data) => {
    trackEvent('Tag Edited', {
      edited_fields: _.without(_.keys(data), 'id')
    });
    const editTag = firebase.functions().httpsCallable('editTag');
    const tag = this.tagsById[data.id];
    const tagBeforeEdit = _.assign({}, tag);
    _.assign(tag, data);
    this._update('tags');
    return editTag(data).catch((err) => {
      _.assign(tag, tagBeforeEdit);
      this._update('tags');
      DataStore._handleCloudFunctionError(err);
    });
  }

  deleteTag = (params) => {
    trackEvent('Tag Deleted', { also_delete_items: params.alsoDeleteItems || false });
    const deleteTag = firebase.functions().httpsCallable('deleteTag');
    return deleteTag(params).catch((err) => {
      DataStore._handleCloudFunctionError(err);
    });
  }

  getItem = async (params) => {
    const getItem = firebase.functions().httpsCallable('getItem');
    let item;
    try {
      const { data } = await getItem(params);
      data.item.archiveDate = firebase.firestore.Timestamp.fromMillis(data.item.archiveDate);
      item = data.item;
    }
    catch (err) {
      item = null;
    }
    return { item };
  }

  saveSearchQuery = (query) => {
    const saveSearchQuery = firebase.functions().httpsCallable('saveSearchQuery');
    return saveSearchQuery({ query: query });
  }

  getGeography = () => {
    const getGeography = firebase.functions().httpsCallable('getGeography');
    return getGeography({});
  }

  reset = () => {
    this.removeAllListeners();
    this.listeners.forEach((unsubscribe) => { unsubscribe(); });
    delete DataStore.singleton;
  }
}

class DataStoreQuery extends EventEmitter {
  constructor() {
    super();

    this.dataStore = new DataStore();
    this.fuse = null;
    this.searchTerm = null;
    this.tagFilter = null;
    this.typeFilter = null;
    this.filteredItems = null;
    this.items = null;
  }

  listen = (listener) => {
    this.on('updated', listener);
    this.dataStore.listen(this._update);
  }

  stopListening = (listener) => {
    this.off('updated', listener);
    this.dataStore.stopListening(this._update);
  }

  _update = () => {
    if (this.dataStore.initialized) {
      this._updateFilteredItems();
      this._setupFuse();
      this._updateSearchedItems();
    }
  }

  _updateFilteredItems = () => {
    if (this.tagFilter !== null) {
      const tag = this.dataStore.tagsById[this.tagFilter];
      if (tag !== undefined) {
        this.filteredItems = tag.items;
      }
      else {
        this.filteredItems = [];
      }
    }
    else if (this.typeFilter !== null) {
      const type = this.dataStore.typesById[this.typeFilter];
      if (type !== undefined) {
        this.filteredItems = type.items;
      }
      else {
        this.filteredItems = [];
      }
    }
    else {
      this.filteredItems = this.dataStore.items;
    }
  }

  _setupFuse = () => {
    this.fuse = new Fuse(this.filteredItems, {
      shouldSort: true,
      tokenize: true,
      threshold: 0.1,
      location: 0,
      distance: 1000,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        { name: 'tags.label', weight: 0.2 },
        { name: 'types.label', weight: 0.2 },
        { name: 'siteDomain', weight: 0.2 },
        { name: 'siteName', weight: 0.2 },
        { name: 'displayTitle', weight: 0.1 },
        { name: 'description', weight: 0.05 },
        { name: 'note', weight: 0.05 }
      ]
    });
  }

  _updateSearchedItems = () => {
    if (this.searchTerm === null) {
      this.items = this.filteredItems;
    }
    else {
      this.items = this.fuse.search(this.searchTerm);
    }
    this.emit('updated');
  }

  queryItems = (query) => {
    this.tagFilter = _.get(query, 'tagFilter', null);
    this.typeFilter = _.get(query, 'typeFilter', null);
    this.searchTerm = _.get(query, 'searchTerm', null);

    this._update();
  }
}

export { DataStore, DataStoreQuery };
