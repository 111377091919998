import React, { Component } from 'react';
import env from '../data/env';
import './Brand.scss';
import { Route, Switch, withRouter } from 'react-router-dom';
import { withAuth, AUTH_STATE } from '..//data/Auth';
import Navbar, { NavbarSpacer, NavbarLink, NavbarBrandHeader, NavbarMenuTrigger } from '../components/Navbar';
import PrivacyConsentHandler from '../components/PrivacyConsentHandler';
import cx from 'classnames';
import { trackEvent } from '../data/Analytics';
import SafariExtensionHandler from '../components/SafariExtensionHandler';
import { updateDarkModeStatus } from '../data/darkmode';

class Brand extends Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
  }

  componentDidMount = () => {
    updateDarkModeStatus(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname
    || this.props.location.search !== prevProps.location.search) {
      this.setState({ showMenu: false });
    }
  }

  _onClickMenuTrigger = (e) => {
    trackEvent('Footer Menu Opened');
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    const { authState, routes } = this.props;

    return (
      <div className={cx('Brand', { 'Brand--menu-visible': this.state.showMenu })}>
        <div className="Brand__navbar">
          <Navbar brand>
            <NavbarBrandHeader large />
            <NavbarSpacer />
            <NavbarMenuTrigger onClick={this._onClickMenuTrigger} />
            {!env.protectPublicPages && <NavbarLink to="/pricing">Pricing</NavbarLink>}
            {authState > AUTH_STATE.UNAUTHENTICATED && <NavbarLink to="/auth/sign-out">Sign Out</NavbarLink>}
            {authState <= AUTH_STATE.UNAUTHENTICATED && <NavbarLink to="/auth/sign-in">Sign In</NavbarLink>}
          </Navbar>
        </div>
        <div className="Brand__content">
          <Switch>
            {routes.map((route, index) => (
              <Route
                exact
                key={index}
                path={route.path}
                component={route.content}
              />
            ))}
          </Switch>
        </div>
        <div className="Brand__footer">
          <Navbar footer showAsMenu={this.state.showMenu}>
            <NavbarBrandHeader large />
            <NavbarSpacer />
            {!env.protectPublicPages && <NavbarLink to="/install">Install</NavbarLink>}
            {!env.protectPublicPages && <NavbarLink to="/pricing">Pricing</NavbarLink>}
            {!env.protectPublicPages && <NavbarLink openInNewTab to="/blog">Blog</NavbarLink>}
            {!env.protectPublicPages && <NavbarLink openInNewTab to="/privacy">Privacy Policy</NavbarLink>}
            {!env.protectPublicPages && <NavbarLink openInNewTab to="/terms">Terms of Service</NavbarLink>}
            {!env.protectPublicPages && <NavbarLink to="/help">Help &amp; FAQ</NavbarLink>}
          </Navbar>
        </div>
        <PrivacyConsentHandler />
        <SafariExtensionHandler />
      </div>
    );
  }
}

export default withRouter(withAuth(Brand));