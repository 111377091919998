import * as React from 'react';

function SvgIconTokenNew(props) {
  return (
    <svg width={14} height={14} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14A7 7 0 117 0a7 7 0 010 14zm-.89-7.89H2.8v1.78h3.31v3.31h1.78V7.89h3.31V6.11H7.89V2.8H6.11v3.31z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconTokenNew;
