import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './base.scss';
import AppRouter from './AppRouter';
import * as serviceWorker from './data/serviceWorker';
import env from './data/env';
import * as Sentry from '@sentry/browser';
import { initAnalytics } from './data/Analytics';
import { updateDarkModeStatus } from './data/darkmode';

initAnalytics();

if (env.environment === 'production' || env.environment === 'test') {
  Sentry.init({
    dsn: 'https://c06a8bcad92c49369d0bfe41ce771192@sentry.io/1531932',
    environment: env.environment,
    release: env.commit
  });
}

updateDarkModeStatus();
ReactDOM.render(<AppRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
