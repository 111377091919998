import React, { Component } from 'react';
import ItemGridCard from './ItemGridCard';
import _ from 'lodash';
import MasonryLayout from './MasonryLayout';

class ItemGrid extends Component {
  constructor(props) {
    super(props);
    this._perPage = 50;
    this._pagesLoaded = 0;
    this.state = {
      loadedItems: [],
      infiniteScrollEnd: false
    };
  }

  _loadMoreItems = () => {
    if (this.state.loadedItems.length < this.props.items.length) {
      this._pagesLoaded += 1;
      this._updateLoadedItems();
    }
    else {
      this.setState({ infiniteScrollEnd: true });
    }
  }

  _updateLoadedItems = () => {
    this.setState({ loadedItems: this.props.items.slice(0, Math.min(this._pagesLoaded * this._perPage, this.props.items.length)) });
  }

  componentDidMount = () => {
    this._loadMoreItems();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.items !== prevProps.items) {
      this._updateLoadedItems();
    }
  }

  render() {
    // If we don't have any data, we render nothing
    // Containing views are responsible for showing sensible state for these cases
    if (_.isEmpty(this.state.loadedItems)) {
      return null;
    }

    const cards = this.state.loadedItems.map((item) => {
      return (
        <ItemGridCard
          key={item.id}
          item={item}
          onDelete={this._handleDelete}
          editable={this.props.editable}
          clickableTokens={this.props.clickableTokens}
        />
      );
    });

    return (
      <MasonryLayout 
        objects={cards}
        infiniteScrollEnd={this.state.infiniteScrollEnd}
        infiniteScroll={this._loadMoreItems}/>
    );
  }
}

export default ItemGrid;
