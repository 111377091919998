import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { updateDarkModeStatus } from '../data/darkmode';
import './BrowserExtension.scss';

class BrowserExtensionReset extends Component {
  _sendMessageToExtension = (name, data) => {
    document.dispatchEvent(new CustomEvent('dumpster-message-from-app', { detail: { name, data } }));
  }
  
  componentDidMount = () => {
    updateDarkModeStatus(true);
    this._sendMessageToExtension('reset');
  }

  render() {
    return (
      <div className="BrowserExtension__message">
        <div className="BrowserExtension__message__header">
          Extension reset
        </div>
        <div className="BrowserExtension__message__body">
          You should now be able to do other things with your time.
        </div>
        <Link to="/" className="btn medium BrowserExtension__btn">View my Dumpster</Link>
      </div>
    );
  }
}

export default BrowserExtensionReset;