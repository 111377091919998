import React, { Component } from 'react';
import { DataStore } from '../data/DataStore';
import * as Sentry from '@sentry/browser';
import { withAuth, ONLINE_STATE } from '../data/Auth';

class BrowserExtensionBackground extends Component {
  componentDidMount = () => {
    window.addEventListener('message', this._receiveMessageFromExtension, false);
  }

  componentWillUnmount = () => {
    window.removeEventListener('message', this._receiveMessageFromExtension);
  }

  _receiveMessageFromExtension = async (event) => {
    // Ignore untrusted messages / only process those generated by user action
    if (!event.isTrusted) {
      return;
    }

    // Only process messages from known extension origins
    // if (event.origin !== 'chrome-extension://njifgmbkpfiebjbfnbggcdibfelknblk'
    //  && event.origin !== 'chrome-extension://lcmgcfibdfmboahplofobcneiaggopcp'
    //  && event.origin !== 'moz-extension://5c861aba-e113-aa4f-b589-bb116d54cd19') {
    //   return;
    // }
    
    const { data } = event;
    
    new DataStore().createItem(data, true).catch(err => {
      Sentry.captureException(err);
      alert(`There was an error saving ${data.url}. Check your internet connection and try again.`);
    });
  }

  _sendMessageToBackgroundExtension = (data) => {
    window.parent.postMessage(data, '*');
  }

  render() {
    if (this.props.onlineState === ONLINE_STATE.OFFLINE) {
      return <div>You are offline.</div>;
    }

    return (<div>Dumpster background extension is running!</div>);
  }
}

export default withAuth(BrowserExtensionBackground);