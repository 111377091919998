import React, { Component } from 'react';
import './WhatIsDumpsterPopover.scss';
import { NavbarBrandHeader } from '../components/Navbar';
import { Link } from 'react-router-dom';
import SvgIconCancel from '../images/IconCancel';

class WhatIsDumpsterPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: false
    };
  }

  componentDidMount = () => {
    this.setState({
      hide: localStorage.getItem('dumpster_hide_what_is_dumpster_popover') === 'true'
    });
  }

  _handleHide = () => {
    localStorage.setItem('dumpster_hide_what_is_dumpster_popover', 'true');
    this.setState({
      hide: true
    });
  }

  render() {
    if (this.state.hide) {
      return null;
    }

    return (
      <div className="WhatIsDumpsterPopover">
        <SvgIconCancel className="WhatIsDumpsterPopover__hide" onClick={this._handleHide} />
        <NavbarBrandHeader />
        <div className="WhatIsDumpsterPopover__text">
          Matt created this page with Dumpster.<br/>
          Tap below to learn more.
        </div>
        <Link to="/" className="btn secondary">What is Dumpster?</Link>
      </div>
    );
  }
}

export default WhatIsDumpsterPopover;