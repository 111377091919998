import * as React from 'react';

function SvgApple(props) {
  return (
    <svg width={19} height={23} fill="none" {...props}>
      <path
        d="M18.602 17.58a12.261 12.261 0 01-1.213 2.18c-.638.909-1.16 1.538-1.562 1.888-.624.573-1.292.867-2.008.884-.514 0-1.133-.146-1.855-.443-.723-.295-1.388-.441-1.996-.441-.638 0-1.322.146-2.053.441-.733.297-1.323.451-1.774.466-.687.03-1.37-.273-2.054-.907-.435-.38-.98-1.032-1.634-1.955-.7-.986-1.276-2.13-1.727-3.432C.242 14.853 0 13.49 0 12.17c0-1.513.327-2.816.98-3.909a5.754 5.754 0 012.055-2.078A5.527 5.527 0 015.813 5.4c.545 0 1.26.169 2.148.5.886.333 1.455.501 1.704.501.186 0 .818-.197 1.89-.59 1.012-.364 1.867-.515 2.568-.456 1.897.153 3.323.901 4.271 2.25-1.697 1.028-2.537 2.468-2.52 4.316.015 1.439.538 2.637 1.564 3.588A5.14 5.14 0 0019 16.533c-.125.364-.258.712-.398 1.046zM14.249.451c0 1.128-.412 2.181-1.233 3.156-.992 1.16-2.19 1.829-3.491 1.723a3.512 3.512 0 01-.026-.427c0-1.083.471-2.242 1.308-3.19.418-.48.95-.879 1.595-1.197.643-.313 1.251-.487 1.824-.516a4.1 4.1 0 01.023.45z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgApple;
