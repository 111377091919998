import React, { Component } from 'react';
import './NotFound.scss';
import { withAuth } from '..//data/Auth';
import { Navbar, NavbarBrandHeader, NavbarSpacer, NavbarLink } from '../components/Navbar';

class NotFound extends Component {
  render() {
    return (<div className="NotFound">
      <div>
        <div className="NotFound__header">Nothing to see here!</div>
        <div className="NotFound__body">Check your link. It probably isn't right.</div>
      </div>
    </div>);
  }
}

class _NotFoundPage extends Component {
  render() {
    return (
      <>
        <Navbar noDivider>
          <NavbarBrandHeader />
          <NavbarSpacer />
          <NavbarLink to="/get-started">Get Started</NavbarLink>
          {!this.props.authenticated ? (
            <NavbarLink to="/auth/sign-in">Sign in with Google</NavbarLink>
          ) : (
            <NavbarLink to="/auth/sign-out">Sign out</NavbarLink>
          )}
        </Navbar>
        <NotFound />
      </>
    );
  }
}
const NotFoundPage = withAuth(_NotFoundPage);

export { NotFoundPage };
export default NotFound;