import * as React from 'react';

function SvgIconExpand(props) {
  return (
    <svg width={14} height={14} fill="none" {...props}>
      <path stroke="currentColor" strokeWidth={2} d="M7 0v14M0 7h14" />
    </svg>
  );
}

export default SvgIconExpand;
