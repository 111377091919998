import { Component } from 'react';
import { toast } from '../components/Toast';
import { withAuth, ONLINE_STATE } from '../data/Auth';
import _ from 'lodash';

class OnlineToastHandler extends Component {
  constructor(props) {
    super(props);
    this.toastId = null;
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.onlineState === ONLINE_STATE.ONLINE) {
      if (!_.isNull(this.toastId)) {
        toast.dismiss(this.toastId);
        this.toastId = null;
      }
    }
    else if (this.props.onlineState === ONLINE_STATE.OFFLINE) {
      if (_.isNull(this.toastId)) {
        this.toastId = toast('You are offline', {
          closeOnClick: false,
          autoClose: false
        });
      }
    }
  }

  render() {
    return null;
  }
}

export default withAuth(OnlineToastHandler);