import React, { Component } from 'react';
import './TokenInput.scss';
import _ from 'lodash';
import cx from 'classnames';

class TokenInput extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  blur = () => {
    this.inputRef.current.blur();
  }

  focus = () => {
    this.inputRef.current.focus();
  }

  _handleInputKeyDown = (e) => {
    // Remove token on backspace
    if (e.keyCode === 8) {
      if (e.target.selectionStart === 0) {
        if (this.props.tokens.length > 0 && _.isFunction(this.props.onRemoveToken)) {
          const index = this.props.tokens.length - 1;
          this.props.onRemoveToken(this.props.tokens[index], index);
        }
      }
    }

    // Pass function on, if handler provided
    if (_.isFunction(this.props.onKeyDown)) {
      this.props.onKeyDown(e);
    }
  }

  _handleInputValueChange = (e) => {
    // Pass function on, if handler provided
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange(e);
    }
  }

  _handleFocus = () => {
    // Pass function on, if handler provided
    if (_.isFunction(this.props.onFocus)) {
      this.props.onFocus();
    }
  }

  _handleBlur = () => {
    // Pass function on, if handler provided
    if (_.isFunction(this.props.onBlur)) {
      this.props.onBlur();
    }
  }

  render() {
    const className = cx(
      'TokenInput',
      {'TokenInput--wrap': this.props.wrap},
      {'TokenInput--has-tokens': !_.isEmpty(this.props.tokens)},
      this.props.className
    );
    return (
      <div className={className}>
        {this.props.tokens}
        <input 
          type="text"
          size={1}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="off"
          autoFocus={this.props.autoFocus}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          ref={this.inputRef}
          value={this.props.value}
          onChange={this._handleInputValueChange}
          onKeyDown={this._handleInputKeyDown}
          onFocus={this._handleFocus}
          onBlur={this._handleBlur}
        />
      </div>
    );
  }
}

export default TokenInput;