import EventEmitter from 'events';
import React, { Component } from 'react';
import firebase from '../data/firebase';
import { DataStore } from '../data/DataStore';
import { identifyAnalytics, resetAnalytics, trackEvent } from '../data/Analytics';
import { Route, Redirect } from 'react-router-dom';
import Loading from '../components/Loading';
import SignInOptions from '../containers/SignInOptions';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import env from './env';

const EVENT_NAME = 'stateChanged';

const AUTH_STATE = {
  UNINITIALIZED: 0,
  INITIALIZING: 1,
  UNAUTHENTICATED: 2,
  WAITLISTED: 3,
  INACTIVE: 4,
  TRIALING: 5,
  ACTIVE: 6
};

const ONLINE_STATE = {
  UNINITIALIZED: 0,
  INITIALIZING: 1,
  OFFLINE: 2,
  ONLINE: 3
};

class AuthHandler extends EventEmitter {
  constructor() {
    if (AuthHandler.singleton) {
      return AuthHandler.singleton;
    }

    super();

    this.setMaxListeners(30);

    this.user = null;
    this.currentClaimsDate = null;
    this.authState = AUTH_STATE.UNINITIALIZED;
    this.onlineState = ONLINE_STATE.UNINITIALIZED;

    AuthHandler.singleton = this;

    return this;
  }

  initialize = () => {
    if (this.authState !== AUTH_STATE.UNINITIALIZED) {
      return;
    }

    this.authState = AUTH_STATE.INITIALIZING;
    this.onlineState = ONLINE_STATE.INITIALIZING;
    
    const _updateOnlineStatus = (e) => {
      const newOnlineState = window.navigator.onLine ? ONLINE_STATE.ONLINE : ONLINE_STATE.OFFLINE;
      if (newOnlineState !== this.onlineState) {
        this.onlineState = newOnlineState;
        this.emit(EVENT_NAME);
      }
    };
    _updateOnlineStatus();
    window.addEventListener('online', _updateOnlineStatus);
    window.addEventListener('offline', _updateOnlineStatus);

    let unsubscribeFromUser = () => {};
    firebase.auth().onIdTokenChanged((userRecord) => {
      unsubscribeFromUser();
      if (userRecord) {
        identifyAnalytics(userRecord);
        let userExisted = false;
        unsubscribeFromUser = firebase.db.collection('users')
          .doc(userRecord.uid)
          .onSnapshot((userSnapshot) => {
            if (!userSnapshot.exists) {
              // Only happens when user is first created and pending initialization, or deleted
              if (userExisted) {
                // User previously existed and was deleted, so we should sign out
                trackEvent('User Deleted');
                firebase.auth().signOut();
              }
              return;
            }
            userExisted = true;
            this.user = userSnapshot.data();
            if (_.isNull(this.user.claimsUpdatedDate)) {
              // Only happens when user is first created and pending initialization
              return;
            }
            if (this.currentClaimsDate < this.user.claimsUpdatedDate.toDate()) {
              userRecord.getIdTokenResult(true).then((idTokenResult) => {
                if (env.whitelist && !idTokenResult.claims.whitelist) {
                  this.authState = AUTH_STATE.WAITLISTED;
                }
                else if (_.has(idTokenResult.claims, 'status')) {
                  switch (idTokenResult.claims.status) {
                  case 'trialing':
                    this.authState = AUTH_STATE.TRIALING;
                    break;
                  case 'active':
                    this.authState = AUTH_STATE.ACTIVE;
                    break;
                  case 'inactive':
                  default:
                    this.authState = AUTH_STATE.INACTIVE;
                  }
                }
                else {
                  this.authState = AUTH_STATE.INACTIVE;
                }
                this.emit(EVENT_NAME);
              });
              this.currentClaimsDate = this.user.claimsUpdatedDate.toDate();
            }
          });
      } else {
        resetAnalytics();
        (new DataStore()).reset();
        // The line below tells the Safari extension to reset its auth state
        document.dispatchEvent(new CustomEvent('dumpster-message-from-app', { detail: { name: 'reset' } }));
        this.authState = AUTH_STATE.UNAUTHENTICATED;
        this.emit(EVENT_NAME);
      }
    });
  }

  listen = (listener) => {
    this.on(EVENT_NAME, listener);

    if (this.authState >= AUTH_STATE.UNAUTHENTICATED) {
      // We fire once to make sure auth state is updated at least once
      listener();
    }
    else {
      this.initialize();
    }
  }

  stopListening = (listener) => {
    this.off(EVENT_NAME, listener);
  }
}

class AuthComponent extends Component {
  constructor() {
    super();
    
    this.authHandler = new AuthHandler();
    this.state = {
      authState: this.authHandler.authState,
      onlineState: this.authHandler.onlineState
    };
  }

  componentDidMount = () => {
    this.authHandler.listen(this._syncWithAuthHandler);
  }

  componentWillUnmount = () => {
    this.authHandler.stopListening(this._syncWithAuthHandler);
  }

  _syncWithAuthHandler = () => {
    this.setState({
      authState: this.authHandler.authState,
      onlineState: this.authHandler.onlineState
    });
  }

  render() {
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, this.state);
    });
  }
}

const withAuth = (ComponentToWrap) => {
  class AuthWrapped extends Component {
    render() {
      return <AuthComponent><ComponentToWrap {...this.props} /></AuthComponent>;
    }
  }

  return AuthWrapped;
};

class _SignIn extends Component {
  _signInWithGoogle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    this._signInWithProvider(provider);
  }

  _signInWithApple = () => {
    var provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    this._signInWithProvider(provider);
  }

  _signInWithProvider = (provider) => {
    trackEvent('User Signed In');
    if (_.has(this.props, 'location.state.from')) {
      const { from } = this.props.location.state;
      window.sessionStorage.setItem('redirectAfterAuth', `${from.pathname}${from.search || ''}`);
    }
    else {
      window.sessionStorage.setItem('redirectAfterAuth', '/');
    }

    if (isMobile || _.get(this.props, 'location.state.useRedirect')) {
      firebase.auth().signInWithRedirect(provider);
    }
    else {
      firebase.auth().signInWithPopup(provider).then((result) => {
        window.location.reload();
      }).catch((err) => {
        // Save to ignore this, since it can be triggered by simply closing the popup
      });
    }
  }

  _handleSignInRequested = (service) => {
    if (service === 'google') {
      this._signInWithGoogle();
    }
    else if (service === 'apple') {
      this._signInWithApple();
    }
    else {
      throw new Error('unsupported sign in service requested');
    }
  }

  render() {
    const { authState } = this.props;

    if (authState < AUTH_STATE.UNAUTHENTICATED) {
      return <Loading />;
    }
    else if (authState === AUTH_STATE.UNAUTHENTICATED) {
      return <SignInOptions onSignInRequested={this._handleSignInRequested} />;
    }
    else if (this.props.authState > AUTH_STATE.UNAUTHENTICATED) {
      const redirectAfterAuth = window.sessionStorage.getItem('redirectAfterAuth');
      if (this.props.authState === AUTH_STATE.INACTIVE) {
        return <Redirect to="/pricing" />;
      }
      else if (_.isNull(redirectAfterAuth)) {
        return <Redirect to="/" />;
      }
      else {
        window.sessionStorage.removeItem('redirectAfterAuth');
        return <Redirect to={redirectAfterAuth} />;
      }
    }
  }
}
const SignIn = withAuth(_SignIn);

const signInWithToken = (token, done) => {
  firebase.auth().signInWithCustomToken(token).then((result) => {
    done();
  });
};

class _SignOut extends Component {
  componentDidMount = () => {
    if (this.props.authState > AUTH_STATE.UNAUTHENTICATED) {
      this._signOut();
    }
  }

  _signOut = () => {
    trackEvent('User Signed Out');
    firebase.auth().signOut();
  }

  render() {
    if (this.props.authState <= AUTH_STATE.UNAUTHENTICATED) {
      return <Redirect to="/" />;
    }
    else {
      return <Loading />;
    }
  }
}
const SignOut = withAuth(_SignOut);

const signOut = () => {
  firebase.auth().signOut();
};

class PremiumRedirect extends AuthComponent {
  render() {
    switch (this.state.authState) {
    case AUTH_STATE.UNINITIALIZED:
    case AUTH_STATE.INITIALIZING:
      return null;
    case AUTH_STATE.UNAUTHENTICATED:
      if (this.props.all) {
        return (
          <Route path="*">
            <Redirect to={{pathname: '/auth/sign-in', state: { from: this.props.location }}} />
          </Route>
        );
      }
      else if (this.props.path) {
        return (
          <Route path={this.props.path}>
            <Redirect to={{pathname: '/auth/sign-in', state: { from: this.props.location }}} />
          </Route>
        );
      }
      else {
        throw new Error('PremiumRedirect requires all or path prop');
      }
    case AUTH_STATE.INACTIVE:
      return (
        <Redirect to="/pricing" />
      );
    case AUTH_STATE.TRIALING:
    case AUTH_STATE.ACTIVE:
    default:
      return null;
    }
  }
}

const currentUser = () => {
  return (new AuthHandler()).user;
};

const currentUserRecord = () => {
  return firebase.auth().currentUser;
};

export {
  AuthComponent,
  AuthHandler,
  withAuth,
  SignIn,
  signInWithToken,
  SignOut,
  signOut,
  PremiumRedirect,
  currentUser,
  currentUserRecord,
  AUTH_STATE,
  ONLINE_STATE
};