import React, { Component } from 'react';
import Select from './Select';
import './ItemSortSelect.scss';
import _ from 'lodash';

class ItemSortSelect extends Component {
  constructor(props) {
    super(props);
    this.options = [
      { value: 'savedDate', label: 'Most recent' },
      { value: 'title', label: 'Title' },
      { value: 'siteDomain', label: 'Source' }
    ];
  }

  _handleOnChange = (value) => {
    this.props.onChange(value.value);
  }

  render() {
    if (!this.props.value) {
      return null;
    }

    const option = _.find(this.options, { value: this.props.value });
    return (
      <Select
        value={option}
        options={this.options}
        onChange={this._handleOnChange}
        placeholder="Sort by"
        className="ItemSortSelect"
      />
    );
  }
}

export default ItemSortSelect;