import React, { Component } from 'react';
import './BrowserExtension.scss';
import * as Sentry from '@sentry/browser';
import TagSelect from '../components/TagSelect';
import $ from 'jquery';
import { DataStore } from '../data/DataStore';
import { withAuth, AUTH_STATE, ONLINE_STATE, currentUserRecord } from '..//data/Auth';
import { trackEvent } from '../data/Analytics';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import { PlanDescription } from '../components/Plan';
import { updateDarkModeStatus } from '../data/darkmode';

const isSafari = window.webkit !== undefined && window.webkit.messageHandlers !== undefined;

class BrowserExtensionPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      title: null,
      url: null,
      note: null,
      saved: false
    };
    this.height = null;
    this._dataStore = new DataStore();
  }

  componentDidMount = () => {
    trackEvent('Extension Opened');

    updateDarkModeStatus(true);

    window.addEventListener('message', this._receiveMessageFromExtension, false);
    this._updateHeight();

    // Fetch page data from background extension script
    this._sendMessageToExtension({
      getTitle: true,
      getUrl: true
    });

    if (this.props.authState > AUTH_STATE.UNAUTHENTICATED) {
      this._dataStore.listen(this._syncWithDataStore);
      this._sendMessageToExtension({ currentUser: currentUserRecord().uid });
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('message', this._receiveMessageFromExtension);
    this._dataStore.stopListening(this._syncWithDataStore);
  }

  componentDidUpdate = () => {
    this._updateHeight();
  }

  _syncWithDataStore = () => {
    this.setState({
      dataStoreInitialized: true
    });
  }

  _reset = () => {
    this.setState({
      tags: [],
      title: null,
      url: null,
      note: null,
      saved: false
    });
  }

  _setHeight = (newHeight) => {
    this._sendMessageToExtension({
      pageHeight: newHeight
    });
    this.height = newHeight;
  }

  _updateHeight = () => {
    // Send height to popup to update size, if changed
    const newHeight = $('.BrowserExtension').outerHeight();
    if (this.height !== newHeight) {
      this._setHeight(newHeight);
    }
  }

  _receiveMessageFromExtension = (event) => {
    const data = event.data;
    if (data.title) {
      this.setState({title: data.title});
    }
    if (data.url) {
      this.setState({url: data.url});
    }
    if (data.popoverReopened) {
      trackEvent('Extension Opened');

      this._reset();

      // Fetch page data from background extension script
      this._sendMessageToExtension({
        getTitle: true,
        getUrl: true
      });
    }
  }

  _sendMessageToExtension = (data) => {
    if (isSafari) {
      window.webkit.messageHandlers.extension.postMessage(data);
    }
    else {
      window.parent.postMessage(data, '*');
    }
  }

  _handleTagsChange = (tags) => {
    this.setState({
      tags: tags
    }, () => {
      const cleanTags = this.state.tags.map(tag => { return { label: tag.label }; });
      this._sendMessageToExtension({tags: cleanTags});
    });
  }

  _handleNoteChange = (e) => {
    this.setState({
      note: e.target.value
    }, () => {
      this._sendMessageToExtension({note: this.state.note});
    });
  }

  _handleEnterKeyDown = (e) => {
    if (e.keyCode === 13) {
      this._handleSubmit();
    }
  }

  _handleSignIn = (e) => {
    e.preventDefault();
    this._sendMessageToExtension({
      signInInNewTab: true,
      closePopup: true
    });
  }

  _handleUpgrade = (e) => {
    e.preventDefault();
    this._sendMessageToExtension({
      upgradeToPremium: true,
      closePopup: true
    });
  }

  _noOptionsMessage = (inputValue) => {
    return null;
  }

  _handleSubmit = (event) => {
    this.setState({ saved: true });
    this._sendMessageToExtension({
      saveItem: true
    });

    if (isSafari) {
      this._dataStore.createItem({
        url: this.state.url,
        note: this.state.note,
        tags: this.state.tags.map(tag => { return { label: tag.label }; })
      }, true).catch(err => {
        Sentry.captureException(err);
      });
    }
    setTimeout(() => {
      this._sendMessageToExtension({
        closePopup: true
      });
    }, 2000);
  }

  render() {
    if (this.props.onlineState < ONLINE_STATE.OFFLINE) {
      return <Loading />;
    }
    else if (this.props.onlineState === ONLINE_STATE.OFFLINE) {
      return (
        <div className="BrowserExtension">
          <div className="BrowserExtension__message">
            <div>Check your internet connection and try again.</div>
          </div>
        </div>
      );
    }

    if (this.props.authState < AUTH_STATE.UNAUTHENTICATED) {
      return <Loading />;
    }
    else if (this.props.authState === AUTH_STATE.UNAUTHENTICATED) {
      return (
        <div className="BrowserExtension">
          <div className="BrowserExtension__message">
            <Link 
              className="btn"
              onClick={this._handleSignIn}
              to={{ pathname: '/auth/sign-in', state: { from: this.props.location, useRedirect: true }}}>
              Sign in to Dumpster
            </Link>
          </div>
        </div>
      );
    }
    else if (this.props.authState < AUTH_STATE.TRIALING) {
      if (!this._dataStore.initialized) {
        return <Loading />;
      }

      return (
        <div className="BrowserExtension">
          <div className="BrowserExtension__message">
            <div className="BrowserExtension__message__header">
              Dumpster Premium is needed to save new items
            </div>
            <div className="BrowserExtension__message__body">
              <PlanDescription />
            </div>
            <Link 
              className="btn"
              onClick={this._handleUpgrade}
              to={{ pathname: '/pricing', state: { from: this.props.location }}}>
              Upgrade to Premium
            </Link>
          </div>
        </div>
      );
    }

    if (this.state.title === null) {
      return <Loading />;
    }

    return (
      <div className="BrowserExtension">
        {this.state.saved ? (
          <div className="BrowserExtension__header BrowserExtension__header--saved">Saved!</div>
        ) : (
          <>
            <div className="BrowserExtension__header">Save a new item</div>
            <div className="BrowserExtension__text">{this.state.title}</div>
            <div className="BrowserExtension__header">Note</div>
            <input
              className="BrowserExtension__input"
              autoFocus
              value={this.state.noteValue}
              onChange={this._handleNoteChange}
              onKeyDown={this._handleEnterKeyDown}
              type="text"
              placeholder="Add a note"/>
            <div className="BrowserExtension__header">Lists</div>
            <TagSelect
              placeholder="Search your lists"
              onTagsChange={this._handleTagsChange}
              onSubmit={this._handleSubmit}
              onUpdate={this._updateHeight}
              tags={this.state.tags} />
            <div className="Dropdown__row Dropdown__buttons">
              <div className="Dropdown__spacer"></div>
              <button className="btn" onClick={this._handleSubmit}>Save</button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withAuth(BrowserExtensionPopup);