function createRedirectComponent(url) {
  return function(props) {
    window.location.replace(url);
    return null;
  };
}

const Blog = createRedirectComponent('https://medium.com/@dumpsterdotapp');
const Terms = createRedirectComponent('https://app.termly.io/document/terms-of-use-for-saas/e9b03552-2356-43d7-877b-442667a8ebc0');
const PrivacyPolicy = createRedirectComponent('https://app.termly.io/document/privacy-policy/cbd366c8-9824-452f-af91-229308fd53c4');
const CookiePolicy = createRedirectComponent('https://app.termly.io/document/cookie-policy/1f1a5092-a6f0-4beb-be20-c97d7dc5635f');

export {
  Blog,
  Terms,
  PrivacyPolicy,
  CookiePolicy
};