import React, { Component } from 'react';
import './Help.scss';
import cx from 'classnames';
import { NavbarTitleHeader } from '../components/Navbar';
import SvgIconExpand from '../images/IconExpand';
import SvgIconCollapse from '../images/IconCollapse';
import { Link } from 'react-router-dom';
import env from '../data/env';
import { withAuth, AUTH_STATE } from '../data/Auth';
import ReadyToTryDumpster from '../components/ReadyToTryDumpster';
import Script from 'react-load-script';
import { trackEvent } from '../data/Analytics';
import _ from 'lodash';

// https://stackoverflow.com/questions/5598743/finding-elements-position-relative-to-the-document
function _getOffset(elem) { // crossbrowser version
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top  = box.top +  scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

class NavbarHelpHeader extends Component {
  render() {
    return (
      <NavbarTitleHeader
        chevron
        to="/"
        title="Help &amp; FAQ"
      />
    );
  }
}

class HelpSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      highlighted: false
    };
    this._hash = `#${_.kebabCase(this.props.title)}`;
  }

  _handleToggle = (e) => {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  _highlight = () => {
    this.setState({
      expanded: true,
      highlighted: true
    });
    setTimeout(() => {
      this.setState({
        highlighted: false
      });
    }, 2000);
  }
  
  componentDidMount = () => {
    if (window.location.hash === this._hash) {
      this._highlight();
    }
  }

  render() {
    const className = cx(
      'HelpSection', 
      {'HelpSection--expanded': this.state.expanded},
      {'HelpSection--highlighted': this.state.highlighted}
    );
    return (
      <div data-hash={this._hash} className={className}>
        <Link replace to={this._hash} className="HelpSection__title" onClick={this._handleToggle}>
          {this.props.title}
          <div className="HelpSection__title__spacer"></div>
          {this.state.expanded ? (<SvgIconCollapse />) : (<SvgIconExpand />)}
        </Link>
        <div className="HelpSection__content">{this.props.children}</div>
      </div>
    );
  }
}

let _helpscoutBootstrapped = false;
class _Help extends Component {
  componentDidMount = () => {
    trackEvent('Help Viewed');
    this._bootstrapHelpscount();
    window.Beacon('init', '27d523c2-cbed-4e8e-8455-11e02b20721d');

    // If we're using a hash link we need to scroll to the correct help section
    if (!_.isEmpty(this.props.location.hash)) {
      const el = document.querySelector(`[data-hash="${this.props.location.hash}"]`);
      window.scrollTo(0, _getOffset(el).top - 100);
    }
  }

  componentWillUnmount = () => {
    window.Beacon('destroy');
  }

  _bootstrapHelpscount = () => {
    if (!_helpscoutBootstrapped) {
      (function(e, t, n) {
        e.Beacon = n = function(t, n, a) {
          e.Beacon.readyQueue.push({
            method: t,
            options: n,
            data: a
          });
        };
        n.readyQueue = [];
      })(window, document, window.Beacon || function() {});
      _helpscoutBootstrapped = true;
    }
  }

  render() {
    const showReadyToTryDumpster = 
      this.props.authState <= AUTH_STATE.UNAUTHENTICATED
      && window.location.pathname !== '/help/app';

    return (
      <div className="Help">
        <div className="Help__content">
          <h2>Help &amp; FAQ</h2>

          <h3>About Dumpster</h3>

          <HelpSection title="What is Dumpster?">
            <p>Dumpster is a light-weight bookmarking tool to save and share links. Using the Dumpster web app, browser extension or iOS app you're able to save any url you find on the Internet for later use.</p>
          </HelpSection>
          <HelpSection title="Who is behind Dumpster?">
            <p>Dumpster was started by Jan &amp; Matt. That’s Jan pronounced like Yawn and Matt pronounced like Matt. We are two passionate product makers based in the US, self-funding this project out of the personal desire for a simple tool like this.</p>
          </HelpSection>
          <HelpSection title="What is Dumpster’s business model?">
            <p>Dumpster is free for 14 days and then requires users to pay a small subscription fee. Requiring users to pay for Dumpster means we never have to sell ads or user data. It means our incentives are aligned – we build a product that our customers love and are willing to pay for.</p>
          </HelpSection>

          <h3>Installing Dumpster</h3>

          <HelpSection title="How do I install Dumpster?">
            <p>Dumpster is available as a web app, browser extensions for Chrome and Safari as well as for iOS (iPhone &amp; iPad).</p>
            <ul>
              <li><Link to="/auth/sign-in">Click here</Link> to sign in and use the web app</li>
              <li><a href={env.chromeExtensionInstallURL} target="_blank" rel="noopener noreferrer">Click here</a> to install the Chrome extension</li>
              <li><a href={env.macAppDownloadURL} target="_blank" rel="noopener noreferrer">Click here</a> to install the Safari extension</li>
              <li><a href={env.iOSAppDownloadURL}>Click here</a> to install the iOS app or search for 'Dumpster' on the iOS App Store</li>
            </ul>
          </HelpSection>
          <HelpSection title="What is the Dumpster browser extension?">
            <p>The Dumpster browser extensions are plugins for your web browser (Chrome or Safari), which allow you to save a link right when you're viewing it. <Link to="/install">Click here</Link> to install the browser extension for your browser. Once installed, click on the Dumpster icon next to the browser's search bar to save a link.</p>
          </HelpSection>
          <HelpSection title="Is Dumpster available for Android?">
            <p>Unfortunately not at the moment. We're currently focused on the Dumpster web &amp; iOS experience but please feel free to write us at android@dumpster.app if you want to stay in the loop about any future updates. </p>
          </HelpSection>

          <h3>How to use Dumpster</h3>

          <HelpSection title="How do I save links to Dumpster?">
            <p>You can save new links to Dumpster using one of a few methods:</p>
            <ul>
              <li>Once signed into the Dumpster web app you can click the + in the top right corner to save a link.</li>
              <li>Dumpster has extensions for your web browser (Chrome or Safari) that allow you to save a link as you're viewing it. <Link to="/install">Click here</Link> to install the browser extension for your browser. Once installed, click on the Dumpster icon in the browser toolbar to save your current page.</li>
              <li>After having installed the Dumpster iOS app you can use the iOS share extension to save new links. Try it out by e.g. visiting any link on Safari on iOS, swipe the app section to the end, select 'More' and add Dumpster to your share sheet.</li>
            </ul>
          </HelpSection>
          <HelpSection title="What are lists? ">
            <p>We all love saving links and our libraries can turn into... well, dumpsters. Lists make it easy for you to organize your links. You can add links to lists as you're saving them or by editing them later too.</p>
            <p>Lists on Dumpster are shareable. When viewing a list, either copy the url from your browser (e.g. https://dumpster.app/list/...) or click the share icon next to the title of the list. Note: your saved links and lists are private by default. A list can only be viewed by someone if you have explicitly given them the url to your list. </p>
          </HelpSection>
          <HelpSection title="How do I add a note?">
            <p>As you're saving a new link you have the option to add a note. We've found that notes can help you remember why you have saved a link in the first place. If you want to add or edit a note, then click or tap the edit icon on a saved link. </p>
          </HelpSection>
          <HelpSection title="How do I edit a saved link?">
            <p>You can edit the title, note or lists of a saved link. On the web, hover a saved link and then click the edit icon in the bottom right corner. On iOS, swipe a saved link to the left or right and then tap the edit icon.</p>
          </HelpSection>
          <HelpSection title="How do I change the layout when viewing links or lists?">
            <p>Dumpster offers a card and list layout when viewing links or lists, on both web and iOS. Simply click or tap the layout icons in the top right corner to switch between them.</p>
          </HelpSection>
          <HelpSection title="How do I sort lists or items?">
            <p>You can sort your lists or items (e.g. by title or recency). Click or tap the arrow icon on either the web or iOS app to apply a new sorting.</p>
          </HelpSection>
          <HelpSection title="How do I share a list?">
            <p>Lists on Dumpster are shareable. When viewing a list, either copy the url from your browser (e.g. https://dumpster.app/list/...) or click the share icon next to the title of the list. </p>
            <p>Note: your saved links and lists are private by default. A list can only be viewed by someone if you have explicitly given them the url to your list.</p>
          </HelpSection>
          <HelpSection title="Who can see my lists?">
            <p>Your saved links and lists are private by default. A list can only be viewed by someone if you have explicitly given them the url to your list.</p>
          </HelpSection>
          <HelpSection title="What is archving?">
            <p>Websites change and sometimes even disappear - it happens all the time. For that reason Dumpster offers an archiving feature. Every time you save a new link to Dumpster, Dumpster automatically creates a copy of that page by saving its text and images as well as the corresponding HTML/CSS. If you notice that a link in your Dumpster doesn't work anymore, click on the clock icon to view the archived version of that saved link instead.</p>
          </HelpSection>

          <h3>Payments</h3>
          <HelpSection title="Why do I need a subscription?">
            <p>Dumpster is free for 14 days and then requires users to pay a small subscription fee. Dumpster is a self-funded project. Requiring users to pay for Dumpster allows us to never having to sell ads or user data.</p>
          </HelpSection>
          <HelpSection title="How do I purchase a subscription?">
            <p>If you're on the web click this link to view our available plans. On iOS please make sure you have the Dumpster iOS app installed and are signed in. Then tap this link to see and choose a plan. </p>
          </HelpSection>
          <HelpSection title="How do I cancel my subscription?">
            <p>If you have purchased your subscription on the web, <Link to="/settings/plan">click here</Link> to see your your current plan and to cancel your membership.</p>
            <p>If you have purchased your subscription via Apple, please open this link which will bring you to the iTunes/App Store subscriptions page. You'll be able to cancel your subscription from there. </p>
          </HelpSection>

          <h3>Privacy</h3>
          <HelpSection title="What is your privacy policy?">
            <p>Respecting your privacy is one of our most important values. We carefully choose how we store and handle your data, always adhering to the best practices in security and privacy.</p>
            <p>We have chosen to never run ads and instead fund Dumpster through subscriptions only. This means our incentives are aligned – we build a product that our customers love and are willing to pay for. Dumpster may never grow as quickly as a free product, but it means we will never need to sell ads or user data to keep the service running.</p>
            <p>You can find our full privacy policy <a href="/privacy" target="_blank">here</a>. If you have additional questions please don't hesitate to write us a note at help@dumpster.app.</p>
          </HelpSection>
          <HelpSection title="What are your terms of service?">
            <p>Our terms of service are as straightforward as they can be: We offer a web and mobile experience and need to ask you for personal data such as your name and email to create an account for you. We don't ask for more than we need to. We collect anonimized usage data only to make decisions on how to improve our product. We don't tolerate hateful or racist content, or content that infringes copyrights, and we reserve the right to delete the offending content or account after a warning. </p>
            <p>You can find our full terms of service <a href="/terms" target="_blank">here</a>. If you have additional questions please don't hesitate to write us a note at help@dumpster.app.</p>
          </HelpSection>
          <HelpSection title="Do you share my information with other parties?">
            <p>Dumpster is built using cloud services, meaning we rely on services provided by other companies to store data and keep it running. We have agreements with each of these companies ensuring they adhere to our high standards of data security and privacy.</p>
          </HelpSection>
          <HelpSection title="Can other people see my Dumpster?">
            <p>No, your Dumpster is private and for your eyes only. The only exception is if you deliberately share a list with someone: anyone who you share the link to a list with can see the title and saved items and that you are the author of that list. </p>
          </HelpSection>
          <HelpSection title="Does Dumpster have ads?">
            <p>Nope, never. Dumpster is funded through subscriptions which allows us to say 'No!' to ads, ad targeting and retargeting.</p>
          </HelpSection>
          <HelpSection title="How do I delete my account?">
            <p>We're sorry to see you go but we get it... Dumpster isn't for everyone. To delete your account please <Link to="/settings">click here</Link>. At the bottom of the Settings page you'll see a link that allows your to delete your account. Please note that this action is irreversible.</p>
          </HelpSection>

        </div>
        {showReadyToTryDumpster && <ReadyToTryDumpster />}
        <Script url="https://beacon-v2.helpscout.net" />
      </div>
    );
  }
}
const Help = withAuth(_Help);

export {
  Help,
  NavbarHelpHeader
};