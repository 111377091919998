import React, { Component } from 'react';
import { toast, ToastDialog, ToastDialogBody, ToastDialogButtons } from '../components/Toast';
import { DataStore } from '../data/DataStore';
import { Link } from 'react-router-dom';
import moment from 'moment'; 
import _ from 'lodash';
import { PlanExpiryDescription } from '../components/Plan';
import st from 'safe-timers';

class ExpiryWarningHandler extends Component {
  constructor() {
    super();
    this.dataStore = new DataStore();
    this.timer = null;
    this.currentToast = null;
    this.state = {
      dismissedExpiryWarningForPeriodEndMillis: null,
      currentPeriodEndMillis: null
    };
  }
  
  componentDidMount = () => {
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentWillUnmount = () => {
    this._dismissToast();
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  _syncWithDataStore = () => {
    const { plan, settings } = this.dataStore.user;
    this.setState({
      subscribed: !_.isNull(plan.type),
      dismissedExpiryWarningForPeriodEndMillis: settings.dismissedExpiryWarningForPeriodEndMillis,
      currentPeriodEndMillis: _.isNull(plan.currentPeriodEndDate) ? null : plan.currentPeriodEndDate.toMillis()
    }, _ => {
      this._updateTimer();
    });
  }

  _updateTimer = () => {
    st.clearTimeout(this.timer);
    if (!this.state.subscribed
        && (_.isUndefined(this.state.dismissedExpiryWarningForPeriodEndMillis)
            || this.state.dismissedExpiryWarningForPeriodEndMillis !== this.state.currentPeriodEndMillis)) {
      const warningTime = moment(this.state.currentPeriodEndMillis).subtract(3, 'days').valueOf();
      const warningTimeout = Math.max(warningTime - Date.now(), 0);
      
      if (warningTimeout > 0) {
        this._dismissToast(false);
      }

      this.timer = st.setTimeout(_ => {
        this._showExpiryWarningToast();
      }, warningTimeout);
    }
    else {
      this._dismissToast(false);
    }
  }

  _showExpiryWarningToast = () => {
    if (!_.isNull(this.currentToast)) {
      return;
    }

    this.currentToast = toast(({ closeToast }) =>
      <ToastDialog>
        <ToastDialogBody>
          <PlanExpiryDescription />
          Enjoying Dumpster? Upgrade to an yearly Premium plan now and save 15%.
        </ToastDialogBody>
        <ToastDialogButtons>
          <Link to="/pricing" className="btn" onClick={(e) => this._dismissToast(true)}>Upgrade</Link>
          <div className="btn secondary" onClick={(e) => this._dismissToast(true)}>Not now</div>
        </ToastDialogButtons>
      </ToastDialog>
    , { autoClose: false });
  }

  _dismissToast = (persist) => {
    if (!_.isNull(this.currentToast)) {
      toast.dismiss(this.currentToast);
      this.currentToast = null;
      if (persist) {
        this.dataStore.dismissedExpiryWarning();
      }
    }
  }

  render = () => {
    return null;
  }
}

export default ExpiryWarningHandler;