import React, { Component } from 'react';
import './BrowserExtension.scss';
import { Redirect, Link } from 'react-router-dom';
import { withAuth, AUTH_STATE } from '../data/Auth';
import { updateDarkModeStatus } from '../data/darkmode';

class BrowserExtensionAuth extends Component {
  componentDidMount = () => {
    updateDarkModeStatus(true);
  }

  render() {
    if (this.props.authState <= AUTH_STATE.UNAUTHENTICATED) {
      return <Redirect to={{ pathname: '/auth/sign-in', state: { from: this.props.location } }} />;
    }

    return (
      <div className="BrowserExtension__message">
        <div className="BrowserExtension__message__header">
          You are signed into Dumpster!
        </div>
        <div className="BrowserExtension__message__body">
          You can start using the Dumpster Extension now.
        </div>
        <Link to="/" className="btn medium BrowserExtension__btn">View my Dumpster</Link>
      </div>
    );
  }
}

export default withAuth(BrowserExtensionAuth);