const env = {};

// Figure out environment based on where we're being served from
switch (window.location.host) {
case 'dumpster.app':
case 'thedumpster.co':
case 'dumpster-3f20e.web.app':
case 'dumpster-3f20e.firebaseapp.com':
  env.environment = 'production';
  break;
case 'dumpster-test.firebaseapp.com':
case 'dumpster-test.web.app':
case 'test.dumpster.app':
  env.environment = 'test';
  break;
default:
  env.environment = 'development';
  break;
}

// Firebase config
if (env.environment === 'test' || env.environment === 'development') {
  env.firebase = {
    projectId: 'dumpster-test',
    locationId: 'us-central',
    appId: '1:809804549703:web:9a894917eba7fc79',
    apiKey: 'AIzaSyAfBSI8OJL-KtSSVfA4BPDIzc7sF3uX5IA',
    authDomain: 'dumpster-test.firebaseapp.com',
    databaseURL: 'https://dumpster-test.firebaseio.com',
    storageBucket: 'dumpster-test.appspot.com',
    messagingSenderId: '809804549703'
  };
}
else if (env.environment === 'production') {
  env.firebase = {
    projectId: 'dumpster-3f20e',
    locationId: 'us-central',
    apiKey: 'AIzaSyD-XqVLjL-GB19GJSY6HQsWfeyfz9u0aG4',
    authDomain: window.location.host,
    databaseURL: 'https://dumpster-3f20e.firebaseio.com',
    storageBucket: 'dumpster-3f20e.appspot.com',
    messagingSenderId: '641375746283'
  };
}
else {
  throw new Error('Firebase configuration must be correctly set to use Dumpster!');
}

// Set Stripe config based on Firebase project
if (env.firebase.projectId === 'dumpster-test') {
  env.stripe = {
    apiKey: 'pk_test_yULXk94fOBsRkLrlh9hHdDoc00Ev8xwjJC'
  };
}
else if (env.firebase.projectId === 'dumpster-3f20e') {
  env.stripe = {
    apiKey: 'pk_live_0XSXgh6sncjQIFBazmtoG0DV00OaJeSsOW'
  };
}

// Extension links
env.chromeExtensionInstallURL = 'https://chrome.google.com/webstore/detail/dumpster/lcmgcfibdfmboahplofobcneiaggopcp';
env.firefoxExtensionInstallURL = 'https://addons.mozilla.org/addon/dumpster/';
env.macAppDownloadURL = 'https://apps.apple.com/us/app/dumpster-easy-link-saving/id1495928019?ls=1&mt=12';

// App store links
env.manageAppStoreSubscription = 'https://apps.apple.com/account/subscriptions';
if (env.environment === 'test') {
  env.iOSAppDownloadURL = 'https://testflight.apple.com';
}
else if (env.environment === 'production') {
  env.iOSAppDownloadURL = 'https://apps.apple.com/us/app/dumpster-easy-link-saving/id1493068954?ls=1';
}
else {
  env.iOSAppDownloadURL = 'https://testflight.apple.com';
}

// Amplitude API keys
if (env.environment === 'production') {
  env.amplitude = { apiKey: 'e5cf693c13872ed8698f8f3e4a753245' };
}
else if (env.environment === 'test') {
  env.amplitude = { apiKey: 'c605b8ab51eddaaa91a70ef84402ccd4' };
}
else {
  env.amplitude = { apiKey: '2d4fdc6d737669fd86c22167acf4c9e3' };
}

// Commit information for Sentry
if (process.env.REACT_APP_COMMIT) {
  env.commit = process.env.REACT_APP_COMMIT;
}

env.whitelist = false;
env.protectPublicPages = false;

export default env;