import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import { DataStore } from '../data/DataStore';
import { trackEvent } from '../data/Analytics';
import Loading from '../components/Loading';

class AccountDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.dataStore = new DataStore();
    this.state = { deleting: false };
  }

  _handleAfterOpen = () => {
    trackEvent('Delete Account Opened');
  }

  _handleDelete = (e) => {
    if (e) e.preventDefault();
    this.setState({ deleting: true });
    // We only request close on error - refresh will happen automatically on deletion
    this.dataStore.deleteUser().catch((err) => {
      this.props.onRequestClose();
    });
  }

  _handleCancel = (e) => {
    e.preventDefault();
    this.props.onRequestClose();
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={!this.state.deleting}
        shouldCloseOnOverlayClick={!this.state.deleting}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header Modal__header--warning">Delete your account?</div>
        <div className="Modal__row">
          <div className="Modal__text">
            Are you sure you want to delete your Dumpster account and all of your lists and items?
            This action cannot be undone.
          </div>
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this._handleCancel}>Cancel</button>
          <button className="Modal__button btn warning" onClick={this._handleDelete}>Delete</button>
        </div>
        {this.state.deleting && <Loading opaque />}
      </ReactModal>
    );
  }
}

export default AccountDeleteModal;