import React, { Component } from 'react';
import './TagGridCard.scss';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { DataStore } from '../data/DataStore';
import _ from 'lodash';
import { TagControls } from './TagControls';

class TagGridCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      deleting: false,
      sharing: false
    };
    this.dataStore = new DataStore();
  }
  
  _handleEditOpen = (e) => {
    e.preventDefault();
    this.setState({ editing: true });
  }

  _handleEditClose = () => {
    this.setState({ editing: false });
  }

  _handleDeleteOpen = (e) => {
    e.preventDefault();
    this.setState({ deleting: true });
  }

  _handleDeleteClose = (e) => {
    this.setState({ deleting: false });
  }

  _handleShareOpen = (e) => {
    e.preventDefault();
    this.setState({ sharing: true });
  }

  _handleShareClose = () => {
    this.setState({ sharing: false });
  }

  render() {
    const mostRecentItems = _
      .chain(this.props.tag.items)
      .filter(item => { return _.isArray(item.imageUrls) && item.imageUrls.length > 0; })
      .take(4)
      .value();
    const images = _.range(0,4).map((index) => {
      const item = mostRecentItems[index];
      const key = _.get(item,'id') || _.uniqueId();
      return (
        <div key={key} className="TagGridCard__image-carousel__image-container">
          { !_.isNil(item) &&
            <div className="TagGridCard__image-carousel__image">
              <img src={item.imageUrls[0]} alt="" />
            </div>
          }
        </div>
      );
    });

    return (
      <div className="TagGridCard">
        <Link to={`/list/${this.props.tag.id}`}>
          <div className="TagGridCard__header">
            <div className="TagGridCard__label">{this.props.tag.label}</div>
            <div className="TagGridCard__items-count">{this.props.tag.items.length}</div>
          </div>
          <div className="TagGridCard__image-carousel">
            {images}
          </div>
          <div className="TagGridCard__recent-item-timestamp">
            Updated <TimeAgo date={this.props.tag.mostRecentItem.savedDate.toDate()} />
          </div>
        </Link>
        
        <TagControls
          className="TagGridCard__TagControls"
          tag={this.props.tag}
          editable />
      </div>
    );
  }
}

export default TagGridCard;
