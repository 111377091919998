import React, { Component } from 'react';
import './Premium.scss';
import { NavbarTitleHeader } from '../components/Navbar';
import { DataStore } from '../data/DataStore';
import { PlanButtons, PlanUpgradeHint, PlanDescription } from '../components/Plan';
import { withAuth, AUTH_STATE } from '../data/Auth';
import Loading from '../components/Loading';
import { trackEvent } from '../data/Analytics';

class NavbarPremiumHeader extends Component {
  render() {
    return (
      <NavbarTitleHeader
        chevron
        to="/settings"
        title="Dumpster Premium"
      />
    );
  }
}

class _Premium extends Component {
  constructor() {
    super();
    this.dataStore = new DataStore();
    this.state = { initialized: false };
  }

  componentDidMount = () => {
    trackEvent('Premium Viewed');
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  componentDidUpdate = (prevProps) => {
    if (!this.state.initialized) {
      if (this.props.authState === AUTH_STATE.UNAUTHENTICATED) {
        this.setState({ initialized: true });
      }
      else if (this.props.authState > AUTH_STATE.UNAUTHENTICATED) {
        this.dataStore.listen(this._syncWithDataStore);
      }
    }
  }

  _syncWithDataStore = () => {
    this.setState({ initialized: this.dataStore.initialized });
  }

  render() {
    const { authState } = this.props;

    if (authState <= AUTH_STATE.UNINITIALIZED || !this.state.initialized) {
      return <Loading />;
    }

    return (
      <div className="Pricing">
        <div className="Pricing__content">
          <div className="Pricing__row">
            <div className="Pricing__left">
              <h2>Dumpster Premium</h2>
              <p>
                Dumpster was started by <a href="http://jansen.co/">Jan</a> &amp;&nbsp;
                <a href="https://twitter.com/mblackshaw">Matt</a>. That’s Jan pronounced like Yawn and Matt
                pronounced like Matt. We are two passionate product makers, self-funding this project out
                of the personal desire for a simple tool like this.
              </p>
              {authState <= AUTH_STATE.UNAUTHENTICATED ? ( 
                <p>
                  Try it out for free for 14 days. If you like what you see, unlock Dumpster for a small
                  price so we can keep the lights on and continue building new features for us and for you.
                </p>
              ) : (
                <PlanDescription showWarningIcon />
              )}
            </div>
            <div className="Pricing__spacer"></div>
            <div className="Pricing__right">
              <PlanButtons />
              <div className="Pricing__upsell">
                <PlanUpgradeHint />
              </div>
            </div>
          </div>
          <div className="Pricing__row Pricing__row--features">
            <div className="Pricing__feature">
              <div className="Pricing__feature__header">Unlimited</div>
              <div className="Pricing__feature__text">
                Save literally any and every link you want.
              </div>
            </div>
            <div className="Pricing__feature">
              <div className="Pricing__feature__header">Lists</div>
              <div className="Pricing__feature__text">
                Add your links to lists so you can easily find and share them.
              </div>
            </div>
            <div className="Pricing__feature">
              <div className="Pricing__feature__header">Privacy</div>
              <div className="Pricing__feature__text">
                We don’t need ads and we respect your privacy.
              </div>
            </div>
            <div className="Pricing__feature">
              <div className="Pricing__feature__header">Search</div>
              <div className="Pricing__feature__text">
                We’re pretty positive you know what to do here.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const Premium = withAuth(_Premium);

export { Premium, NavbarPremiumHeader };