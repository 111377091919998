import React, { Component } from 'react';
import './ArchivedPage.scss';
import { DataStore } from '../data/DataStore';
import Loading from '../components/Loading';
import _ from 'lodash';
import NotFound from './NotFound';
import Navbar, { NavbarBrandHeader, NavbarSpacer } from '../components/Navbar';
import { Link } from 'react-router-dom';
import SvgIconCancelLarge from '../images/IconCancelLarge';
import cx from 'classnames';
import moment from 'moment';
import { trackEvent } from '../data/Analytics';

class ArchivedPage extends Component {
  constructor(props) {
    super(props);
    this.dataStore = new DataStore();
    this.state = {
      item: undefined,
      bannerHidden: false
    };
  }

  componentDidMount = async () => {
    trackEvent('Archived Page Viewed');
    this.dataStore.getItem({ id: this.props.match.params.id }).then(({ item }) => {
      this.setState({ item });
    }).catch((err) => {
      this.setState({ item: null });
    });
  }

  _handleClose = (e) => {
    e.preventDefault();
    this.setState({ bannerHidden: true });
  }

  render() {
    const { item } = this.state;

    if (_.isUndefined(item)) {
      return <Loading />;
    }

    if (_.isNull(item)) {
      return <div className="ArchivedPage"><NotFound /></div>;
    }

    const className = cx('ArchivedPage', { 'ArchivedPage--banner-hidden': this.state.bannerHidden });
    const archiveDateString = moment(item.archiveDate.toDate()).format('ll');
    
    return (
      <div className={className}>
        {!this.state.bannerHidden &&
          <div className="ArchivedPage__Navbar">
            <Navbar banner>
              <NavbarBrandHeader />
              <NavbarSpacer />
              <div className="ArchivedPage__Navbar__timestamp">
                You’re viewing an archived version of this page. This archive was created on {archiveDateString}.<br/>
                It’s possible that not all images and features are displayed correctly. <Link to="/help#what-is-archving">Learn more</Link> about archiving.
              </div>
              <NavbarSpacer />
              <div className="ArchivedPage__Navbar__close-button" onClick={this._handleClose}>
                <SvgIconCancelLarge />
              </div>
            </Navbar>
          </div>
        }
        <div className="ArchivedPage__content">
          <iframe title="Archived Page" frameBorder="0" src={this.state.item.archiveUrl} />
        </div>
      </div>
    );
  }
}

export default ArchivedPage;