import React, { Component } from 'react';
import './ViewToggle.scss';
import cx from 'classnames';
import SvgIconGridView from '../images/IconGridView';
import SvgIconListView from '../images/IconListView';

class ViewToggle extends Component {
  _onToggleClick = (activeName) => {
    return () => {
      this.props.onViewToggle(activeName);
    };
  }

  render() {
    const gridClassName = cx({
      'ViewToggle__toggle': true,
      'ViewToggle__toggle--active': this.props.activeView === 'grid',
      'svg-icon': true
    });
    
    const listClassName = cx({
      'ViewToggle__toggle': true,
      'ViewToggle__toggle--active': this.props.activeView === 'list',
      'svg-icon': true
    });

    return (
      <div className="ViewToggle">
        <div className={gridClassName} onClick={this._onToggleClick('grid')}><SvgIconGridView /></div>
        <div className={listClassName} onClick={this._onToggleClick('list')}><SvgIconListView /></div>
      </div>
    );
  }
}

export default ViewToggle;