import { Component } from 'react';
import { withAuth, currentUserRecord, AUTH_STATE } from '../data/Auth';
import { DataStore } from '../data/DataStore';

class SafariExtensionHandler extends Component {
  _sendMessageToExtension = (name, data) => {
    document.dispatchEvent(new CustomEvent('dumpster-message-from-app', { detail: { name, data } }));
  }

  _handleMessageFromExtension = (e) => {
    if (e.detail.name === 'token') {
      const dataStore = new DataStore();
      dataStore.getAuthToken().then(({ data }) => {
        const { token } = data;
        this._sendMessageToExtension('token', { token: token });
      });
    }
  }

  componentDidMount = () => {
    if (this.props.authState > AUTH_STATE.UNAUTHENTICATED) {
      document.addEventListener('dumpster-message-from-extension', this._handleMessageFromExtension);
      this._sendMessageToExtension('currentUser', { uid: currentUserRecord().uid });
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('dumpster-message', this._handleMessageFromExtension);
  }
  
  render() {
    return null;
  }
}

export default withAuth(SafariExtensionHandler);