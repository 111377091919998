import React, { Component } from 'react';
import './Home.scss';
import { DataStore } from '../data/DataStore';
import _ from 'lodash';
import Loading from '../components/Loading';
import NavTabs from '../components/NavTabs';
import MasonryLayout from '../components/MasonryLayout';
import ItemGridCard from '../components/ItemGridCard';
import TagGridCard from '../components/TagGridCard';
import { trackEvent } from '../data/Analytics';
import { Install } from './Install';

class Home extends Component {
  constructor(props) {
    super(props);
    this._perPage = 50;
    this._pagesLoaded = 1;
    this._objects = [];
    this.state = {
      loadedObjects: null,
      infiniteScrollEnd: false
    };

    this.state = {
      objects: null
    };

    this.dataStore = new DataStore();
  }

  componentDidMount = () => {
    trackEvent('Home Viewed');
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  _syncWithDataStore = () => {
    const wrappedItems = this.dataStore.items.map(item => {
      return {
        kind: 'item',
        item: item,
        timestamp: item.savedDate
      };
    });
    const wrappedTags = this.dataStore.tags.map(tag => {
      if (tag.items.length === 0) return null;
      return {
        kind: 'tag',
        tag: tag,
        timestamp: tag.mostRecentItem.savedDate
      };
    });
    const objects = _.compact(_.concat(wrappedItems, wrappedTags));
    this._objects = _.orderBy(objects, ['timestamp','kind'], ['desc','asc']);
    this._updateLoadedObjects();
  }

  _loadMoreObjects = () => {
    if (this.state.loadedObjects.length < this._objects.length) {
      this._pagesLoaded += 1;
      this._updateLoadedObjects();
    }
    else {
      this.setState({ infiniteScrollEnd: true });
    }
  }

  _updateLoadedObjects = () => {
    this.setState({ loadedObjects: this._objects.slice(0, Math.min(this._pagesLoaded * this._perPage, this._objects.length)) });
  }

  render() {
    // If we are still loading
    if (_.isNil(this.state.loadedObjects)) {
      return <Loading />;
    }

    if (_.isEmpty(this.state.loadedObjects)) {
      return <Install onboarding />;
    }



    const cards = this.state.loadedObjects.map(object => {
      if (object.kind === 'item') {
        const item = object.item;
        return (<ItemGridCard
          key={item.id}
          item={item}
          onDelete={this._handleDelete}
          editable
        />);
      }
      else if (object.kind === 'tag') {
        const tag = object.tag;
        return (<TagGridCard
          key={tag.id}
          tag={tag}
        />);
      }
      else {
        return null;
      }
    });
    
    return (
      <div className="Home">
        <div className="Home__header">
          <NavTabs />
        </div>
        <MasonryLayout 
          objects={cards}
          infiniteScrollEnd={this.state.infiniteScrollEnd}
          infiniteScroll={this._loadMoreObjects}/>
      </div>
    );
  }
}

export default Home;