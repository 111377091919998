import React, { Component } from 'react';
import './App.scss';
import { Route, Switch, Link } from 'react-router-dom';
import { withAuth, AUTH_STATE } from '..//data/Auth';
import Navbar, { NavbarSpacer, NavbarBrandHeader } from '../components/Navbar';
import SearchBar from '../components/SearchBar';
import AddItemDropdown from '../components/AddItemDropdown';
import AccountDropdown from '../components/AccountDropdown';
import { ToastContainer } from '../components/Toast';
import OnlineToastHandler from '../components/OnlineToastHandler';
import PrivacyConsentHandler from '../components/PrivacyConsentHandler';
import ExpiryWarningHandler from '../components/ExpiryWarningHandler';
import WhatIsDumpsterPopover from '../components/WhatIsDumpsterPopover';
import SafariExtensionHandler from '../components/SafariExtensionHandler';
import { updateDarkModeStatus } from '../data/darkmode';

class App extends Component {
  componentDidMount = () => {
    updateDarkModeStatus(true);
  }

  render() {
    const { authState, routes } = this.props;
    const isPremium = authState >= AUTH_STATE.TRIALING;
    
    return (
      <div className="App">
        <div className="App__navbar">
          <Navbar app>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  exact
                  key={index}
                  path={route.path}
                  component={route.header || NavbarBrandHeader}
                />
              ))}
            </Switch>
            <NavbarSpacer />
            { isPremium ? (
              <>
                <SearchBar />
                <AccountDropdown />
                <AddItemDropdown />
              </>
            ) : (
              <>
                <Link to="/" className="btn secondary --hide-on-mobile">What is Dumpster?</Link>
              </>
            )}
          </Navbar>
        </div>
        <div className="App__content">
          <Switch>
            {routes.map((route, index) => (
              <Route
                exact
                key={index}
                path={route.path}
                component={route.content}
              />
            ))}
          </Switch>
        </div>
        {!isPremium && <WhatIsDumpsterPopover />}
        <ToastContainer />
        <OnlineToastHandler />
        <PrivacyConsentHandler />
        <SafariExtensionHandler />
        {isPremium && <ExpiryWarningHandler />}
      </div>
    );
  }
}

export default withAuth(App);