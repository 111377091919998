import React, { Component } from 'react';
import '../base.scss';
import cx from 'classnames';
import ItemShareModal from './ItemShareModal';
import ItemEditModal from './ItemEditModal';
import ItemDeleteModal from './ItemDeleteModal';
import SvgIconShare from '../images/IconShare';
import SvgIconArchive from '../images/IconArchive';
import SvgIconEdit from '../images/IconEdit';
import SvgIconDelete from '../images/IconDelete';

class ModalButton extends Component {
  state = { showModal: false }
  _handleModalShow = () => { this.setState({ showModal: true }); }
  _handleModalHide = () => { this.setState({ showModal: false }); }
}

class ItemShareButton extends ModalButton {
  render = () => {
    return (
      <div className="ItemShareButton">
        <div className="control-btn" onClick={this._handleModalShow}>
          <SvgIconShare />
        </div>
        {this.state.showModal &&
          <ItemShareModal
            item={this.props.item}
            isOpen
            onRequestClose={this._handleModalHide}
          />
        }
      </div>
    );
  }
}

class ItemArchiveButton extends Component {
  render = () => {
    const { id } = this.props.item;
    const archivePageUrl = `/archive/${id}`;
    return (
      <a className="ItemArchiveButton" href={archivePageUrl} target="_blank" rel="noopener noreferrer">
        <div className="control-btn">
          <SvgIconArchive />
        </div>
      </a>
    );
  }
}

class ItemEditButton extends ModalButton {
  render = () => {
    return (
      <div className="ItemEditButton">
        <div className="control-btn" onClick={this._handleModalShow}>
          <SvgIconEdit />
        </div>
        {this.state.showModal &&
          <ItemEditModal
            item={this.props.item}
            isOpen
            onRequestClose={this._handleModalHide}
          />
        }
      </div>
    );
  }
}

class ItemDeleteButton extends ModalButton {
  render = () => {
    return (
      <div className="ItemDeleteButton">
        <div className="control-btn" onClick={this._handleModalShow}>
          <SvgIconDelete />
        </div>
        {this.state.showModal &&
          <ItemDeleteModal 
            item={this.props.item}
            isOpen
            onRequestClose={this._handleModalHide}
          />
        }
      </div>
    );
  }
}

class ItemControls extends Component {
  render = () => {
    const { item } = this.props;
    return (
      <div className={cx('ItemControls', 'controls', this.props.className)}>
        {this.props.editable && <ItemDeleteButton item={item} />}
        {this.props.editable && <ItemEditButton item={item} />}
        {this.props.item.archiveStatusCode === 200 && <ItemArchiveButton item={item} />}
        <ItemShareButton item={item} />
      </div>
    );
  }
}

export {
  ItemShareButton,
  ItemEditButton,
  ItemDeleteButton,
  ItemControls
};