import * as React from 'react';

function SvgIconLogo(props) {
  return (
    <svg width={23} height={25} fill="none" {...props}>
      <path
        d="M3.143 24h16.571V6.571h3.143V4.286H16V0H6.857v4.286H0V6.57h3.143V24zm6-21.714h4.571v2H9.143v-2zM5.429 6.57H17.429v15.143h-12V6.571z"
        fill="currentColor"
      />
      <path
        d="M10.286 9.429H8v8.857h2.286V9.429zM14.857 9.429h-2.285v8.857h2.285V9.429z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconLogo;
