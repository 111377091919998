import React, { Component } from 'react';
import TagGridCard from './TagGridCard';
import _ from 'lodash';
import MasonryLayout from './MasonryLayout';

class TagGrid extends Component {
  render() {
    // If we don't have any data, we render nothing
    // Containing views are responsible for showing sensible state for these cases
    if (_.isNil(this.props.tags) || this.props.tags.length === 0) {
      return null;
    }

    var cards = [];
    this.props.tags.forEach(tag => {
      // Ignore tags with no items
      if (tag.items.length === 0) return;
      cards.push((
        <TagGridCard
          key={tag.id}
          tag={tag}
        />
      ));
    });

    return (
      <div className="TagGrid">
        <MasonryLayout objects={cards} />
      </div>
    );
  }
}

export default TagGrid;
