import * as React from 'react';

function SvgIconTokenRemove(props) {
  return (
    <svg width={7} height={8} fill="none" {...props}>
      <path
        d="M6.714 1.751L5.748.786 3.5 3.034 1.252.786l-.966.965L2.534 4 .286 6.249l.966.965L3.5 4.966l2.248 2.248.966-.965-2.248-2.25 2.248-2.248z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconTokenRemove;
