import * as React from 'react';

function SvgIconAdd(props) {
  return (
    <svg width={20} height={20} fill="none" {...props}>
      <path
        stroke="currentColor"
        d="M0 9.833h20M0 9.833h20M0 9.833h20M9.833 20V0M9.833 20V0M9.833 20V0"
      />
    </svg>
  );
}

export default SvgIconAdd;
