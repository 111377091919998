import * as React from 'react';

function SvgIconSearchCancel(props) {
  return (
    <svg width={12} height={12} fill="none" {...props}>
      <rect
        y={10.544}
        width={14.912}
        height={2.059}
        rx={1.029}
        transform="rotate(-45 0 10.544)"
        fill="currentColor"
      />
      <rect
        x={10.544}
        y={12}
        width={14.912}
        height={2.059}
        rx={1.029}
        transform="rotate(-135 10.544 12)"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconSearchCancel;
