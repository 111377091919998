import React, { Component } from 'react';
import './Items.scss';
import { DataStore } from '../data/DataStore';
import _ from 'lodash';
import NavTabs from '../components/NavTabs';
import ViewToggle from '../components/ViewToggle';
import ItemSortSelect from '../components/ItemSortSelect';
import Loading from '../components/Loading';
import ItemGrid from '../components/ItemGrid';
import ItemList from '../components/ItemList';
import EmptyState from '../components/EmptyState';
import { trackEvent } from '../data/Analytics';


class Items extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
      sortValue: null,
      activeView: null
    };

    this.dataStore = new DataStore();
  }

  componentDidMount = () => {
    trackEvent('Items Viewed');
    this.dataStore.listen(this._syncWithDataStore);
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
  }

  _syncWithDataStore = () => {
    const { value, field, order } = this.dataStore.getDefaultItemSortOrder();
    const fieldResolver = (['title', 'siteDomain'].includes(field)) ? item => item[field].toLowerCase() : field;
    this.setState({
      items: _.orderBy(this.dataStore.items, fieldResolver, order),
      sortValue: value,
      activeView: this.dataStore.getDefaultItemView()
    });
  }

  render() {
    // If we are still loading
    if (_.isNil(this.state.items)) {
      return <Loading />;
    }

    const hasItems = this.state.items.length > 0;

    return (
      <div className="Items">
        <div className="Items__header">
          <NavTabs />
          <div className="Items__spacer"></div>
          {hasItems &&
            <>
              <ViewToggle activeView={this.state.activeView} onViewToggle={this.dataStore.setDefaultItemView} />
              <ItemSortSelect value={this.state.sortValue} onChange={this.dataStore.setDefaultItemSortOrder} />
            </>
          }
        </div>
        {!hasItems && <EmptyState message="You haven't saved any items yet!" />}
        {hasItems && this.state.activeView === 'grid' && <ItemGrid editable items={this.state.items} />}
        {hasItems && this.state.activeView === 'list' && <ItemList editable items={this.state.items} />}
      </div>
    );
  }
}

export default Items;