import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import { trackEvent } from '../data/Analytics';
import env from '../data/env';

class ManageAppStorePlanModal extends Component {
  _handleAfterOpen = () => {
    trackEvent('Manage App Store Plan Opened');
  }

  _handleClose = (e) => {
    this.props.onRequestClose();
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        <div className="Modal__header">Manage on App Store</div>
        <div className="Modal__row">
          <div className="Modal__text">
            Your subscription has been purchased via Apple. Please visit Apple’s App Store to manage your subscription.
          </div>
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this._handleClose}>Cancel</button>
          <a href={env.manageAppStoreSubscription}
            target="_blank"
            rel="noopener noreferrer"
            className="Modal__button btn"
            onClick={this._handleClose}
          >Go to App Store</a>
        </div>
      </ReactModal>
    );
  }
}

export default ManageAppStorePlanModal;