import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';
import './StripePaymentModal.scss';
import { DataStore } from '../data/DataStore';
import Loading from '../components/Loading';
import { trackEvent } from '../data/Analytics';
import {
  CardElement,
  StripeProvider,
  Elements,
  injectStripe,
} from 'react-stripe-elements';
import { PLANS } from './Plan';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import env from '../data/env';
import { Link } from 'react-router-dom';
import Script from 'react-load-script';
import cx from 'classnames';

class _CardForm extends Component {
  _handleSubmit = (e) => {
    if (e) e.preventDefault();
    if (this.props.stripe) {
      this.props.onLoading(true);
      this.props.stripe
        .createToken()
        .then((payload) => {
          if (payload.error) {
            this.props.onLoading(false);
            alert(payload.error.message);
          }
          else {
            this.props.onCreateToken(payload.token.id);
          }
        });
    } else {
      throw new Error('StripePaymentModal attempted payment without Stripe library loaded');
    }
  }

  render() {
    const plan = this.props.plan;
    const currentPeriodEndMoment = _.isNull(plan.currentPeriodEndDate) ? moment() : moment(plan.currentPeriodEndDate.toDate());
    const datePeriodEnds = currentPeriodEndMoment.format('l');
    const darkMode = $('h2').css('color') === 'rgb(255, 255, 255)';

    return (
      <form onSubmit={this._handleSubmit}>
        <CardElement
          onReady={(el) => el.focus()}
          style={{
            base: {
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
              color: darkMode ? '#FFFFFF' : '#000000',
              fontSmoothing: 'antialiased',
              '::placeholder': {
                color: '#828282',
              },
            }
          }} />
        <div className="Modal__row">
          <div className="Modal__text">
            You will be charged <b>{PLANS[this.props.planType].PRICE_STRING}</b>
            &nbsp;per {PLANS[this.props.planType].PERIOD_STRING}&nbsp;
            {this.props.plan.status === 'trialing' ? 'after your trial ends' : 'starting'}
            &nbsp;on {datePeriodEnds}.
          </div>
        </div>
        <div className="Modal__row Modal__buttons">
          <div className="Modal__spacer"></div>
          <button className="Modal__button btn secondary" onClick={this.props.onCancel}>Cancel</button>
          <button className="Modal__button btn" onClick={this._handleSubmit}>Sign up</button>
        </div>
      </form>
    );
  }
}
const CardForm = injectStripe(_CardForm);

class StripePaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeLoaded: false,
      loading: false,
      success: false
    };
    this._dataStore = new DataStore();
  }

  componentDidMount = () => {
    this.setState({
      success: this.props.justPurchased
    });
  }

  _handleAfterOpen = () => {
    trackEvent('Stripe Payment Opened');
  }

  _handleLoading = (isLoading) => {
    this.setState({ loading: isLoading });
  }

  _handleCreateToken = (token) => {
    this._dataStore.createStripeSubscription({
      source: token,
      plan: this.props.planType
    }).then(() => {
      this.setState({ loading: false, success: true });
      sessionStorage.setItem('dumpster_just_purchased', 'true');
    }).catch((err) => {
      this.setState({ loading: false });
      if (_.has(err, 'details.paymentErrorMessage')) {
        alert(err.details.paymentErrorMessage);
      }
      else {
        alert('An unknown error has occured. Please try again.');
      }
    });
  }

  _handleCancel = (e) => {
    e.preventDefault();
    this.props.onRequestClose();
  }

  _handleStripeLoaded = () => {
    this.setState({ stripeLoaded: true });
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.isOpen}
        onAfterOpen={this._handleAfterOpen}
        shouldCloseOnEsc={!this.state.loading}
        shouldCloseOnOverlayClick={!this.state.loading}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        className={cx('Modal', 'StripePaymentModal', { 'StripePaymentModal--stripe-loading': !this.state.stripeLoaded })}
        overlayClassName="ModalOverlay"
      >
        <Script url="https://js.stripe.com/v3/" onLoad={this._handleStripeLoaded} />
        {!this.state.success ? (
          <>
            <div className="Modal__header">Sign up for our {this.props.planType} plan</div>
            {this.state.stripeLoaded &&
              <StripeProvider apiKey={env.stripe.apiKey}>
                <Elements fonts={[{cssSrc: 'https://rsms.me/inter/inter.css'}]}>
                  <CardForm
                    onLoading={this._handleLoading}
                    onCreateToken={this._handleCreateToken}
                    onCancel={this._handleCancel}
                    plan={this.props.plan}
                    planType={this.props.planType}
                  />
                </Elements>
              </StripeProvider>
            }
          </>
        ) : (
          <>
            <div className="Modal__header">Thank you!</div>
            <div className="Modal__row">
              <div className="Modal__text">
                We’re high-fiving you from the Dumpster HQ (keep your eyes on the elbow).
                Ok, now get out there and start saving and sharing all the things. 
              </div>
            </div>
            <div className="Modal__row Modal__buttons">
              <div className="Modal__spacer"></div>
              <button className="Modal__button btn secondary" onClick={this.props.onRequestClose}>Close</button>
              <Link to="/" className="Modal__button btn">Back to Dumpster</Link>
            </div>
          </>
        )}
        {(this.state.loading || !this.state.stripeLoaded) && <Loading opaque />}
      </ReactModal>
    );
  }
}

export default StripePaymentModal;