import React, { Component } from 'react';
import './NoResults.scss';

class NoResults extends Component {
  render() {
    const message = this.props.message || 'Sorry, there are no results.';
    return (
      <div className="NoResults">
        <div>{message}</div>
      </div>
    );
  }
}

export default NoResults;