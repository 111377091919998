import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './TagSelect.scss';
import { DataStore } from '../data/DataStore';
import { Tokens, TagToken } from './Tokens';
import TokenInput from './TokenInput';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { PulseLoader } from 'react-spinners';

class TagSelect extends Component {
  constructor(props) {
    super(props);

    this._recentTags = null;
    this._filteredSuggestions = null;
    this._selectableTokenRefs = null;
    this._selectableTokenLocations = null;
    this._selectableTokensByLocation = null;
    this._selectableTokenRowNumber = null;
    this._selectedToken = null;

    this.state = {
      selectableTags: null,
      selectedTagIndex: null,
      inputValue: ''
    };

    this.fuse = null;
    this.dataStore = new DataStore();
    this.inputRef = React.createRef();
    this.cancelSubmit = null;

    this.tagSuggestions = [
      'Read later',
      'Favorites',
      'Travel',
      'Archive',
      'Random stuff'
    ].map((i, index) => { return { id: _.uniqueId(), label: i, value: i.toLowerCase() }; });
  }

  componentDidMount = () => {
    this.dataStore.listen(this._syncWithDataStore);
    document.addEventListener('click', this._handleDocumentClick);
  }

  componentWillUnmount = () => {
    this.dataStore.stopListening(this._syncWithDataStore);
    this.stopListeningToDocumentKeyDown();
    document.removeEventListener('click', this._handleDocumentClick);
  }

  _syncWithDataStore = () => {
    const dataStore = new DataStore();

    this._recentTags = _
      .chain(dataStore.tags)
      .filter((t) => { return t.items.length > 0; })
      .orderBy('mostRecentItem.savedDate', 'desc')
      .value();

    const recentTagValues = this._recentTags.map(tag => { return tag.value; });
    this._filteredSuggestions = this.tagSuggestions.filter(tag => {
      return !recentTagValues.includes(tag.value);
    });

    this._allTags = _
      .chain(this._recentTags)
      .concat(this._filteredSuggestions)
      .value();

    this.fuse = new Fuse(this._allTags, {
      shouldSort: true,
      tokenize: false,
      threshold: 0.1,
      location: 0,
      distance: 1000,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [{ name: 'label', weight: 1 }]
    });

    this._updateSelectableTags();
  }

  _updateSelectableTokenLocations = () => {
    // Index tokens by coordinate
    this._selectableTokensByLocation = {};
    this._selectableTokenLocations = [];
    let row = -1, lastY = null;
    this._selectableTokenRefs.forEach((t, index) => {
      const domNode = ReactDOM.findDOMNode(t.current);
      const rect = domNode.getBoundingClientRect();
      const x = _.floor(rect.x);
      const y = _.floor(rect.y);
      if (_.isNull(lastY) || y > lastY) {
        row += 1;
        lastY = y;
      }
      _.set(this._selectableTokensByLocation, `[${row}].x-${x}`, index);
      this._selectableTokenLocations[index] = { x, y, row };
    });
    this._selectableTokenRowNumber = row+1;
  }

  _addSelectedTag = () => {
    if (this.state.selectedTagIndex !== null && this.state.selectableTags.length > 0) {
      this._addTag(this.state.selectableTags[this.state.selectedTagIndex]);
      this.resetInput();
      this.focus();
    }
    else if (_.isFunction(this.props.onSubmit)) {
      this.props.onSubmit();
    }
  }

  _handleInputOnKeyDown = (e) => {
    // On enter key presss
    if (e.keyCode === 13) {
      this._addSelectedTag();
    }
    // On tab key, or down key press
    else if ((e.keyCode === 9 && !e.shiftKey) || e.keyCode === 40) {
      e.stopPropagation();
      e.preventDefault();
      this.inputRef.current.blur();
      this.setState({ selectedTagIndex: 0 });
      document.addEventListener('keydown', this._handleDocumentOnKeyDown);
    }
  }

  _findClosestTagInRow = (location, row) => {
    const destinationRow = this._selectableTokensByLocation[row];
    let closestTagIndex = null;
    let closestDistance = null;
    for (const xKey in destinationRow) {
      const x = Number(xKey.slice(2));
      const distance = Math.abs(x - location.x);
      if (_.isNull(closestDistance) || distance < closestDistance) {
        closestTagIndex = destinationRow[xKey];
        closestDistance = distance;
      }
    }
    return closestTagIndex;
  }

  _handleDocumentClick = (e) => {
    // Used to detect unfocus and release key down listener
    this.stopListeningToDocumentKeyDown();
  }

  _handleDocumentOnKeyDown = (e) => {
    let location;
    switch (e.keyCode) {
    case 13: // enter
      this._addSelectedTag();
      break;
    case 38: // up
      e.preventDefault();  
      // Find the closest element in the previous row
      location = this._selectableTokenLocations[this.state.selectedTagIndex];
      if (location.row === 0) {
        this.focus();
      }
      else {
        this.setState({
          selectedTagIndex: this._findClosestTagInRow(location, location.row-1)
        });
      }
      break;
    case 40: // down
      e.preventDefault();
      // Find the closest element in the next row
      location = this._selectableTokenLocations[this.state.selectedTagIndex];
      if (location.row === this._selectableTokenRowNumber-1) {
        return;
      }
      else {
        this.setState({
          selectedTagIndex: this._findClosestTagInRow(location, location.row+1)
        });
      }
      break;
    case 37: // left
      e.preventDefault();
      this.setState({ selectedTagIndex: _.max([this.state.selectedTagIndex - 1, 0]) });
      break;
    case 39: // right
      e.preventDefault();
      this.setState({ selectedTagIndex: _.min([this.state.selectedTagIndex + 1, this.state.selectableTags.length-1]) });
      break;
    case 9: // tab
      e.preventDefault();
      if (e.shiftKey) {
        this._ignoreNextTokenMouseMove = true;
        const destinationTagIndex = this.state.selectedTagIndex - 1;
        if (destinationTagIndex < 0) {
          this.focus();
        }
        else {
          this.setState({ selectedTagIndex: _.max([this.state.selectedTagIndex - 1, 0]) });
        }
      }
      else {
        this.setState({ selectedTagIndex: _.min([this.state.selectedTagIndex + 1, this.state.selectableTags.length-1]) });
      }
      break;
    default:
    }
  }

  _handleInputValueChange = (e) => {
    this.setState({
      inputValue: e.target.value,
      selectedTagIndex: e.target.value === '' ? null : 0
    });
  }

  resetInput = () => {
    this.setState({
      inputValue: '',
      selectedTagIndex: null
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputValue !== this.state.inputValue) {
      this._updateSelectableTags();
    }
    if (!_.isEqual(prevProps.tags, this.props.tags)) {
      this._updateSelectableTags();
    }
    this._updateSelectableTokenLocations();
    if (_.isFunction(this.props.onUpdate)) { 
      this.props.onUpdate(); 
    }
  }

  _updateSelectableTags = () => {
    // If loading we ignore for now - state will be correctly updated when syncWithDataStore is called
    if (_.isNull(this._recentTags)) {
      return;
    }

    const isSearching = this.state.inputValue !== '';

    let tagsToRender = [];
    if (isSearching) {
      // Use Fuse to find matching tags
      tagsToRender = this.fuse.search(this.state.inputValue);
    }
    else {
      // Fallback to rendering default set
      tagsToRender = this._allTags;
    }

    // Remove tags that we've already selected
    const selectableTags = _.take(_.filter(tagsToRender, tag => {
      return _.find(this.props.tags, selectedTag => { return tag.value === selectedTag.value; }) === undefined;
    }), 10);

    // Need to add option to create a tag if we're searching and no exact
    // match exists and we haven't already selected it
    const inputTagValue = _.toLower(this.state.inputValue);
    const exactMatch = selectableTags.length === 1 && selectableTags[0].value === inputTagValue;
    const alreadyExists = _.find(this.props.tags, { value: inputTagValue }) !== undefined;
    if (isSearching && !exactMatch && !alreadyExists) {
      selectableTags.push({
        id: _.uniqueId(), // Need a dummy unique id that can be used as the key for React
        label: this.state.inputValue, 
        value: inputTagValue,
        _new: true
      });
    }

    this.setState({ selectableTags });
  }

  _handleRemoveToken = (token, index) => {
    if (_.isFunction(this.props.onTagsChange)) {
      const updatedTags = _.clone(this.props.tags);
      _.pullAt(updatedTags, index);
      this.props.onTagsChange(updatedTags);
    }
  }

  _addTag = (tag) => {
    // Add tag to selected by notifying consumer with updated array
    // consumer is responsible for updating this.props.tags
    if (_.isFunction(this.props.onTagsChange)) {
      // Remove new indicator and dummy id so it renders correctly
      delete tag._new;
      this.props.onTagsChange(_.concat(this.props.tags, tag));
    }
  }

  stopListeningToDocumentKeyDown = () => {
    document.removeEventListener('keydown', this._handleDocumentOnKeyDown);
  }

  focus = () => {
    this.inputRef.current.focus();
    this.setState({ selectedTagIndex: null });
    this.stopListeningToDocumentKeyDown();
  }

  render() {
    const selectedTokens = this.props.tags.map((tag, index) => {
      const tokenRemoveWrapper = () => {
        this._handleRemoveToken(null, index);
        this.inputRef.current.focus();
      };
      return <TagToken key={tag.id} tag={tag} onRemove={tokenRemoveWrapper} />;
    });
    
    const loading = _.isNull(this.state.selectableTags);
    var selectableTokens = [];
    this._selectableTokenRefs = [];
    if (!loading) {
      selectableTokens = this.state.selectableTags.map((tag, index) => {
        const tokenAddWrapper = (e) => {
          e.preventDefault();
          this._addTag(tag);
          this.resetInput();
          this.focus();
        };
        const tokenMouseMoveWrapper = (e) => {
          if (e.shiftKey) {
            this._ignoreNextTokenMouseMove = true;
            return;
          }
          if (this._ignoreNextTokenMouseMove) {
            this._ignoreNextTokenMouseMove = false;
            return;
          }
          this.setState({ selectedTagIndex: index });
        };
        const tokenMouseLeaveWrapper = (e) => {
          this.setState({ selectedTagIndex: null });
        };
        this._selectableTokenRefs[index] = React.createRef();
        return <TagToken 
          selectable={index !== this.state.selectedTagIndex}
          key={tag.id}
          tag={tag} 
          onClick={tokenAddWrapper}
          onMouseMove={tokenMouseMoveWrapper}
          onMouseLeave={tokenMouseLeaveWrapper}
          ref={this._selectableTokenRefs[index]} />;
      });
    }

    return (
      <div className="TagSelect">
        <TokenInput
          wrap
          autoFocus={this.props.autoFocus}
          placeholder={_.isEmpty(this.props.tags) ? this.props.placeholder : ''}
          tokens={selectedTokens}
          value={this.state.inputValue}
          onKeyDown={this._handleInputOnKeyDown}
          onChange={this._handleInputValueChange}
          onRemoveToken={this._handleRemoveToken}
          ref={this.inputRef}
        />
        {loading ? (
          <PulseLoader
            size={8}
            color="var(--text-light-light-gray)"
            css="margin-top: 15px; margin-left: 5px; margin-bottom: 31px;"/>
        ) : (
          <Tokens>
            {selectableTokens}
          </Tokens>
        )}
      </div>
    );
  }
}

export default TagSelect;