import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import env from '../data/env';

firebase.initializeApp(env.firebase);

firebase.db = firebase.firestore();
firebase.db.settings({});

if (env.environment === 'development') {
  // Connect to emulators
  firebase.db.useEmulator('localhost', 8080);
  firebase.auth().useEmulator('http://localhost:9099');
  firebase.functions().useEmulator('localhost', 5001);
}

export default firebase;
