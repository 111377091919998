import * as React from 'react';

function SvgIconWarning(props) {
  return (
    <svg width={30} height={28} fill="none" {...props}>
      <path
        d="M4.498 28h20.99c3.455 0 5.626-3.889 3.915-6.932L18.875 2.333a4.396 4.396 0 00-7.764 0L.583 21.068C-1.095 24.145 1.043 28 4.498 28zm10.528-4.16c-.921 0-1.678-.777-1.678-1.724s.757-1.725 1.678-1.725c.921 0 1.678.778 1.645 1.759.033.913-.757 1.69-1.645 1.69zm-.428-15.555a1.659 1.659 0 011.941.98c.1.271.165.542.165.846a67.355 67.355 0 01-.165 2.57c-.065 1.319-.164 2.672-.23 3.99-.033.44-.033.812-.033 1.252-.033.71-.559 1.25-1.25 1.25-.69 0-1.217-.506-1.25-1.216-.099-2.063-.23-4.126-.33-6.189a455.55 455.55 0 01-.098-1.657c-.033-.845.46-1.59 1.25-1.826z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconWarning;
