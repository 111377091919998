import React, { Component } from 'react';
import { DataStore } from '../data/DataStore';
import _ from 'lodash';
import moment from 'moment';
import { AUTH_STATE, AuthHandler } from '../data/Auth';
import StripePaymentModal from './StripePaymentModal';
import StripeCancelPlanModal from './StripeCancelSubscriptionModal';
import StripeSwitchPlanModal from './StripeSwitchPlanModal';
import ManageAppStorePlanModal from './ManageAppStorePlanModal';
import cx from 'classnames';
import SvgIconWarning from '../images/IconWarning';

const PLAN_STATE = {
  TRIAL: 'trial',
  TRIAL_SUBSCRIBED: 'trialSubscribed',
  TRIAL_EXPIRED: 'trialExpired',
  SUBSCRIBED: 'subscribed',
  PAYMENT_FAILURE_RECOVERING: 'paymentFailureRecovering',
  PAYMENT_FAILURE_NOT_RECOVERED: 'paymentFailureNotRecovered',
  USER_CANCEL_PENDING: 'userCancelPending',
  USER_CANCELED: 'userCanceled',
  APP_STORE_CANCELED: 'appStoreCanceled',
  EXPIRED: 'expired',
  FRIEND: 'friend'
};

const PLANS = {
  'null': {
    TYPE_STRING: '',
    PERIOD_STRING: '',
    PRICE_STRING: ''
  },
  'monthly': {
    TYPE_STRING: 'monthly',
    PERIOD_STRING: 'month',
    PRICE_STRING: '$1.99'
  },
  'yearly': {
    TYPE_STRING: 'yearly',
    PERIOD_STRING: 'year',
    PRICE_STRING: '$19.99'
  },
  'friend': {
    TYPE_STRING: 'Friend',
    PERIOD_STRING: 'forever',
    PRICE_STRING: 'free'
  }
};

class _PlanComponent extends Component {
  constructor() {
    super();
    this._authHandler = new AuthHandler();
    this._dataStore = new DataStore();
    this.state = {
      authState: this._authHandler.authState,
      plan: null,
      paymentMethods: null
    };
  }

  componentDidMount = () => {
    this._authHandler.listen(this._syncWithAuthHandler);
  }

  componentWillUnmount = () => {
    this._authHandler.stopListening(this._syncWithAuthHandler);
    this._dataStore.stopListening(this._syncWithDataStore);
  }

  _syncWithAuthHandler = () => {
    this.setState({ authState: this._authHandler.authState });
    if (this._authHandler.authState > AUTH_STATE.WAITLISTED) {
      this._dataStore.listen(this._syncWithDataStore);
    }
    else {
      this._dataStore.stopListening(this._syncWithDataStore);
    }
  }

  _syncWithDataStore = () => {
    this.setState(_.pick(this._dataStore.user, ['plan', 'paymentMethods']));
  }
}

class PlanSettingsTitle extends _PlanComponent {
  render () {
    if (this.state.authState < AUTH_STATE.UNAUTHENTICATED) return null;

    let title;

    switch (_.get(this.state, 'plan.status')) {
    case 'trialing':
      title = 'Trial Plan';
      break;
    case 'active':
      title = 'Premium Plan';
      break;
    case 'inactive':
      title = 'Plan Expired';
      break;
    default:
      title = null;
    }

    return (
      <div className="PlanTitle">{title}</div>
    );
  }
}

class PlanPremiumHeader extends _PlanComponent {
  render () {
    if (this.state.authState < AUTH_STATE.UNAUTHENTICATED) return null;

    let header = null;

    switch (_.get(this.state, 'plan.state')) {
    case PLAN_STATE.SUBSCRIBED:
    case PLAN_STATE.TRIAL_SUBSCRIBED:
    case PLAN_STATE.FRIEND:
      header = 'Thank you!';
      break;
    case PLAN_STATE.EXPIRED:
      header = 'Plan Expired';
      break;
    case PLAN_STATE.TRIAL_EXPIRED:
      header = 'Trial Expired';
      break;
    default:
      header = 'Dumpster Premium';
    }

    return (
      <div className="PlanPremiumHeader">{header}</div>
    );
  }
}

class PlanPremiumSubText extends _PlanComponent {
  render () {
    if (this.state.authState < AUTH_STATE.UNAUTHENTICATED) return null;

    let text = null;

    switch (_.get(this.state, 'plan.state')) {
    case PLAN_STATE.FRIEND:
      text = null;
      break;
    case PLAN_STATE.SUBSCRIBED:
    case PLAN_STATE.TRIAL_SUBSCRIBED:
      text = 'We’re a small team and your contribution means a lot to us.';
      break;
    case PLAN_STATE.TRIAL:
    case PLAN_STATE.PAYMENT_FAILURE_RECOVERING:
    case PLAN_STATE.PAYMENT_FAILURE_NOT_RECOVERED:
    case PLAN_STATE.USER_CANCEL_PENDING:
    case PLAN_STATE.USER_CANCELED:
    case PLAN_STATE.APP_STORE_CANCELED:
    case PLAN_STATE.TRIAL_EXPIRED:
    case PLAN_STATE.EXPIRED:
    default:
      text = `Dumpster is free to try for the first 7 days. After that you can unlock it for
        a small price which will help us to keep the lights on. Thank you for your support!`;
    }

    return <div className="PlanPremiumSubText">{text}</div>;
  }
}

class PlanPremiumTitle extends _PlanComponent {
  render () {
    if (this.state.authState < AUTH_STATE.UNAUTHENTICATED) return null;

    let el = null;

    switch (_.get(this.state, 'plan.state')) {
    case PLAN_STATE.FRIEND:
      el = null;
      break;
    case PLAN_STATE.SUBSCRIBED:
    case PLAN_STATE.TRIAL_SUBSCRIBED:
      el = <div className="PlanPremiumTitle">Your Plan</div>;
      break;
    case PLAN_STATE.TRIAL:
    case PLAN_STATE.PAYMENT_FAILURE_RECOVERING:
    case PLAN_STATE.PAYMENT_FAILURE_NOT_RECOVERED:
    case PLAN_STATE.USER_CANCEL_PENDING:
    case PLAN_STATE.USER_CANCELED:
    case PLAN_STATE.APP_STORE_CANCELED:
    case PLAN_STATE.TRIAL_EXPIRED:
    case PLAN_STATE.EXPIRED:
    default:
      el = <div className="PlanPremiumTitle">Premium Plans</div>;  
    }

    return el;
  }
}

class PlanDescription extends _PlanComponent {
  render() {
    if (this.state.authState < AUTH_STATE.INACTIVE) return null;
    if (_.isNull(this.state.plan)) return null;

    const plan = this.state.plan;

    const currentPeriodEndMoment = _.isNull(plan.currentPeriodEndDate) ? moment() : moment(plan.currentPeriodEndDate.toDate());
    const timeLeftInPeriodDuration = moment.duration(currentPeriodEndMoment.diff(moment()));
    const timeLeftInPeriod = currentPeriodEndMoment.calendar(null, {
      sameDay: '[today]',
      nextDay: '[tomorrow]',
      nextWeek: (now) => { return `[${timeLeftInPeriodDuration.humanize(true)}]`; },
      lastDay: '[today]',
      lastWeek: '[today]',
      sameElse: '[on] l'
    });
    const datePeriodEnds = currentPeriodEndMoment.format('l');
    const planType = PLANS[plan.type].TYPE_STRING;
    const dateCanceledAt = _.isUndefined(plan.canceledAtDate) ? null : moment(plan.canceledAtDate.toDate()).format('l');
    let paymentMethodDescription;
    let paymentFailureMessage;
    switch (plan.paymentMethod) {
    case 'stripe':
      paymentMethodDescription = `${this.state.paymentMethods.stripe.paymentCardBrand} 
        ending in ${this.state.paymentMethods.stripe.paymentCardLast4}`;
      paymentFailureMessage = _.lowerFirst(this.state.paymentMethods.stripe.paymentFailureMessage);
      break;
    case 'apple':
      paymentMethodDescription = 'App Store subscription';
      break;
    default:
      paymentMethodDescription = 'save payment method';
    }

    let description;
    let warning = false;

    switch (plan.state) {
    case PLAN_STATE.TRIAL:
      warning = true;
      description = (<>Your free trial will <span className="warning">expire {timeLeftInPeriod}</span>.</>);
      break;
    case PLAN_STATE.TRIAL_SUBSCRIBED:
      description = (<>Your free trial will expire {timeLeftInPeriod}.<br/>
        On {datePeriodEnds} you will automatically start on the {planType} plan
        using your {paymentMethodDescription}.</>);
      break;
    case PLAN_STATE.TRIAL_EXPIRED:
      warning = true;
      description = (<>Your free trial expired on {datePeriodEnds}.</>);
      break;
    case PLAN_STATE.SUBSCRIBED:
      description = (<>You're on the {planType} plan. Your plan automatically renews
      on {datePeriodEnds} using your {paymentMethodDescription}.</>);
      break;
    case PLAN_STATE.PAYMENT_FAILURE_RECOVERING:
    case PLAN_STATE.PAYMENT_FAILURE_NOT_RECOVERED:
      warning = true;
      description = (<>Your plan expired on {datePeriodEnds}. We were unable
        to charge your {paymentMethodDescription} because {paymentFailureMessage}.</>);
      break;
    case PLAN_STATE.USER_CANCEL_PENDING:
      warning = true;
      description = (<>Your plan will <span className="warning">expire {timeLeftInPeriod}</span> unless
        you choose a new plan. You canceled your previous plan on {dateCanceledAt}.</>);
      break;
    case PLAN_STATE.USER_CANCELED:
      warning = true;
      description = (<>Your plan expired on {datePeriodEnds}. You canceled your
        previous plan on {dateCanceledAt}.</>);
      break;
    case PLAN_STATE.APP_STORE_CANCELED:
      warning = true;
      description = (<>Your plan was canceled by the App Store on {dateCanceledAt}.</>);
      break;
    case PLAN_STATE.EXPIRED:
      warning = true;
      description = (<>Your plan expired on {datePeriodEnds}.</>);
      break;
    case PLAN_STATE.FRIEND:
      description = (<>Thanks for being a friend. You have free access to Dumpster Premium.</>);
      break;
    default:
      description = null;
    }

    const className = cx(
      'PlanDescription',
      {'PlanDescription--warning': warning}
    );

    return (
      <div className={className}>
        {warning && this.props.showWarningIcon && <SvgIconWarning />}
        <span>{description}</span>
      </div>
    );
  }
}

class PlanExpiryDescription extends _PlanComponent {
  render() {
    if (this.state.authState < AUTH_STATE.INACTIVE) return null;
    if (_.isNull(this.state.plan)) return null;

    const plan = this.state.plan;

    let description;
    const currentPeriodEndMoment = _.isNull(plan.currentPeriodEndDate) ? moment() : moment(plan.currentPeriodEndDate.toDate());
    const timeLeftInPeriodDuration = moment.duration(currentPeriodEndMoment.diff(moment()));
    const timeLeftInPeriod = currentPeriodEndMoment.calendar(null, {
      sameDay: '[today]',
      nextDay: '[tomorrow]',
      nextWeek: (now) => { return `[in ${Math.round(timeLeftInPeriodDuration.asDays())} days]`; },
      lastDay: '[today]',
      lastWeek: '[today]',
      sameElse: '[on] l'
    });
    const datePeriodEnds = currentPeriodEndMoment.format('l');

    switch (plan.state) {
    case PLAN_STATE.TRIAL:
      description = (<>Your free trial will <span className="warning">expire {timeLeftInPeriod}</span>.</>);
      break;
    case PLAN_STATE.TRIAL_EXPIRED:
      description = (<>Your free trial expired on {datePeriodEnds}.</>);
      break;
    case PLAN_STATE.USER_CANCEL_PENDING:
      description = (<>Your Dumpster Premium plan will <span className="warning">expire {timeLeftInPeriod}</span>.</>);
      break;
    case PLAN_STATE.USER_CANCELED:
    case PLAN_STATE.EXPIRED:
      description = (<>Your Dumpster Premium plan expired on {datePeriodEnds}.</>);
      break;
    default:
      description = null;
    }

    return (
      <div className="PlanExpiryDescription">{description}</div>
    );
  }
}

class PlanUpgradeHint extends _PlanComponent {
  render() {
    if (this.state.authState < AUTH_STATE.UNAUTHENTICATED) return null;

    switch (_.get(this.state, 'plan.type')) {
    case 'friend':
    case 'yearly':
      return null;
    case 'monthly':
      return (<div className="PlanUpgradeHint">
        Switch to a yearly plan to save cash money. More than 15% to be sort of exact.
      </div>);
    case null:
    default:
      return (<div className="PlanUpgradeHint">
        Sign up for a yearly plan to save cash money. More than 15% to be sort of exact.
      </div>);
    }
  }
}

class PlanButtons extends _PlanComponent {
  constructor() {
    super();
    const justPurchased = sessionStorage.getItem('dumpster_just_purchased') === 'true';
    sessionStorage.removeItem('dumpster_just_purchased');
    _.assign(this.state, { 
      paymentModalOpen: justPurchased,
      justPurchased: justPurchased,
      cancelPlanModalOpen: false,
      manageAppStorePlanModalOpen: false,
      planType: null
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (_.get(this.state, 'plan.type') !== _.get(prevState, 'plan.type')) {
      if (this.state.authState > AUTH_STATE.UNAUTHENTICATED && _.get(this.state, 'plan.type') === null) {
        if (window.location.search === '?createPlan=monthly') {
          this.setState({
            paymentModalOpen: true,
            planType: 'monthly'
          });
        }
        else if (window.location.search === '?createPlan=yearly') {
          this.setState({
            paymentModalOpen: true,
            planType: 'yearly'
          });
        }
      }
    }
  }

  _createNewPlanHandler = (planType) => {
    return (e) => {
      if (e) e.preventDefault();

      if (this.state.authState === AUTH_STATE.UNAUTHENTICATED) {
        window.location.href = `/settings/plans?createPlan=${planType}`;
      }
      else {
        this.setState({
          planType: planType,
          paymentModalOpen: true
        });
      }
    };
  }

  _handleCancelPlan = (e) => {
    if (e) e.preventDefault();
    if (this.state.plan.paymentMethod === 'apple') {
      this._manageAppStorePlan();
    }
    else {
      this.setState({
        cancelPlanModalOpen: true
      });
    }
  }

  _createSwitchPlanHandler = (planType) => {
    return (e) => {
      if (e) e.preventDefault();
      if (this.state.plan.paymentMethod === 'apple') {
        this._manageAppStorePlan();
      }
      else {
        this.setState({
          planType: planType,
          changePlanModalOpen: true
        });
      }
    };
  }

  _manageAppStorePlan = (e) => {
    if (e) e.preventDefault();
    this.setState({
      manageAppStorePlanModalOpen: true
    });
  }

  _handleModalClose = () => {
    this.setState({
      planType: null,
      paymentModalOpen: false,
      justPurchased: false,
      cancelPlanModalOpen: false,
      changePlanModalOpen: false,
      manageAppStorePlanModalOpen: false
    });
  }

  render() {
    if (this.state.authState < AUTH_STATE.UNAUTHENTICATED) return null;

    const { plan } = this.state;
    const hasPlan = !(_.isNull(plan) || _.isNull(plan.type));
    const hasMonthlyPlan = hasPlan && plan.type === 'monthly';
    const hasYearlyPlan = hasPlan && plan.type === 'yearly';

    const monthlyClassName = cx('PlanButtons__button', { 
      'PlanButtons__button--clickable': !hasPlan,
      'PlanButtons__button--active': hasMonthlyPlan 
    });
    const monthlyClickHandler = hasPlan ? undefined : this._createNewPlanHandler('monthly');

    const yearlyClassName = cx('PlanButtons__button', {
      'PlanButtons__button--clickable': !hasPlan,
      'PlanButtons__button--active': hasYearlyPlan
    });
    const yearlyClickHandler = hasPlan ? undefined : this._createNewPlanHandler('yearly');    

    return (
      <div className="PlanButtons">
        <div className={monthlyClassName} onClick={monthlyClickHandler}>
          <div className="PlanButtons__button__plan">Monthly Plan</div>
          <div className="PlanButtons__button__price">$1.99 / month</div>
          <div className="PlanButtons__button__features">
            Includes all features and apps, billed every month.
          </div>
          <div className="PlanButtons__button__spacer"></div>
          {hasMonthlyPlan && <div className="PlanButtons__button__action btn secondary warning" onClick={this._handleCancelPlan}>Cancel plan</div>}
          {hasYearlyPlan && <div className="PlanButtons__button__action btn secondary" onClick={this._createSwitchPlanHandler('monthly')}>Switch to monthly plan</div>}
        </div>
        <div className={yearlyClassName} onClick={yearlyClickHandler}>
          <div className="PlanButtons__button__plan">Yearly Plan</div>
          <div className="PlanButtons__button__price">$19.99 / year</div>
          <div className="PlanButtons__button__features">
            Includes all features and apps, billed every year.
          </div>
          <div className="PlanButtons__button__spacer"></div>
          {!hasPlan && <div className="PlanButtons__button__upsell">Save 15%</div>}
          {hasMonthlyPlan && <div className="PlanButtons__button__action btn secondary" onClick={this._createSwitchPlanHandler('yearly')}>Switch to yearly plan</div>}
          {hasYearlyPlan && <div className="PlanButtons__button__action btn secondary warning" onClick={this._handleCancelPlan}>Cancel plan</div>}
        </div>

        {this.state.paymentModalOpen &&
          <StripePaymentModal 
            isOpen
            plan={this.state.plan}
            planType={this.state.planType}
            justPurchased={this.state.justPurchased}
            onRequestClose={this._handleModalClose}
          />
        }
        {this.state.cancelPlanModalOpen &&
          <StripeCancelPlanModal
            isOpen
            onRequestClose={this._handleModalClose}
            plan={this.state.plan}
          />
        }
        {this.state.changePlanModalOpen &&
          <StripeSwitchPlanModal
            isOpen
            onRequestClose={this._handleModalClose}
            planType={this.state.planType}
            plan={this.state.plan}
          />
        }
        {this.state.manageAppStorePlanModalOpen && 
          <ManageAppStorePlanModal
            isOpen
            onRequestClose={this._handleModalClose}
          />  
        }
      </div>
    );
  }
}

export {
  PlanSettingsTitle,
  PlanPremiumTitle,
  PlanPremiumSubText,
  PlanPremiumHeader,
  PlanDescription,
  PlanExpiryDescription,
  PlanUpgradeHint,
  PlanButtons,
  PLANS
};