import React, { Component } from 'react';
import '../base.scss';
import cx from 'classnames';
import TagShareModal from './TagShareModal';
import TagEditModal from './TagEditModal';
import TagDeleteModal from './TagDeleteModal';
import SvgIconShare from '../images/IconShare';
import SvgIconEdit from '../images/IconEdit';
import SvgIconDelete from '../images/IconDelete';

class ModalButton extends Component {
  state = { showModal: false }
  _handleModalShow = () => { this.setState({ showModal: true }); }
  _handleModalHide = () => { this.setState({ showModal: false }); }
}

class TagShareButton extends ModalButton {
  render = () => {
    return (
      <div className="TagShareButton">
        <div className="control-btn" onClick={this._handleModalShow}>
          <SvgIconShare />
        </div>
        {this.state.showModal &&
          <TagShareModal
            tag={this.props.tag}
            isOpen
            onRequestClose={this._handleModalHide}
          />
        }
      </div>
    );
  }
}

class TagEditButton extends ModalButton {
  render = () => {
    return (
      <div className="TagEditButton">
        <div className="control-btn" onClick={this._handleModalShow}>
          <SvgIconEdit />
        </div>
        {this.state.showModal &&
          <TagEditModal
            tag={this.props.tag}
            isOpen
            onRequestClose={this._handleModalHide}
          />
        }
      </div>
    );
  }
}

class TagDeleteButton extends ModalButton {
  render = () => {
    return (
      <div className="TagDeleteButton">
        <div className="control-btn" onClick={this._handleModalShow}>
          <SvgIconDelete />
        </div>
        {this.state.showModal &&
          <TagDeleteModal 
            tag={this.props.tag}
            isOpen
            onRequestClose={this._handleModalHide}
            onDeleted={this.props.onDeleted}
          />
        }
      </div>
    );
  }
}

class TagControls extends Component {
  render = () => {
    const { tag } = this.props;
    return (
      <div className={cx('TagControls', 'controls', {'controls--reverse': this.props.reverse}, this.props.className)}>
        {this.props.editable && <TagDeleteButton tag={tag} onDeleted={this.props.onDeleted} />}
        {this.props.editable && <TagEditButton tag={tag} />}
        <TagShareButton tag={tag} />
      </div>
    );
  }
}

export {
  TagShareButton,
  TagEditButton,
  TagDeleteButton,
  TagControls
};