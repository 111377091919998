import * as React from 'react';

function SvgIconEdit(props) {
  return (
    <svg width={19} height={19} fill="none" {...props}>
      <path
        d="M3.69 16.215h10.89M12.635 2.68a1.596 1.596 0 012.256 2.256l-7.467 7.467-3.033.856.855-3.034 7.39-7.544v0zM11.313 4.08L13.49 6.26"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIconEdit;
