import * as React from 'react';

function SvgIconArchive(props) {
  return (
    <svg width={18} height={16} fill="none" {...props}>
      <path
        d="M8.275.003A.507.507 0 018.325 0c.447 0 .756.362.756.808 0 .44-.172.798-.843.807v.003c-3.19.043-5.98 2.768-5.98 6.113 0 3.371 2.768 6.154 6.14 6.154 3.217 0 5.889-2.428 6.124-5.618h-1.42l2.191-3.643 2.19 3.643H16.14c-.237 4.03-3.607 7.233-7.716 7.233-4.263 0-7.732-3.486-7.732-7.75C.692 3.538 4.081.083 8.275.003z"
        fill="currentColor"
      />
      <path
        d="M7.566 3.769c0-.38.305-.707.685-.707h.148c.38 0 .678.326.678.707v4.064l1.99 1.987a.766.766 0 01.001 1.063.746.746 0 01-1.06 0l-2.2-2.2c-.017-.018-.03-.037-.047-.054a.69.69 0 01-.195-.482V3.77z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconArchive;
