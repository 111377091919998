import React, { Component } from 'react';
import './Loading.scss';
import cx from 'classnames';

class Loading extends Component {
  render() {
    return (
      <div className={cx('Loading', { 'Loading--opaque': this.props.opaque }, this.props.className)}>
        <div className={cx('la-line-scale-pulse-out', { 'la-sm': this.props.small })}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default Loading;